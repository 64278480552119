import React, { Component } from 'react'
import Radium from 'radium'
import { Link } from 'react-router-dom'
//import ReactSVG from 'react-svg'

import images from './../../../config/images'
import $ from 'jquery'
// import styles from './styles'

class Footer extends Component {
	componentDidMount() {
		//Keeps footer date accurate
		$('span#year').html(new Date().getFullYear())
	}
	render() {
		return (
			<footer className='footer'>
				<div className='information'>
					<div className='company'>T-Squared Builders LLC</div>
					<div className='address'>N. Hoffman Estates, IL, 60192</div>
					<div className='copyright'>
						©<span id='year' /> ALL RIGHTS RESERVED
					</div>
				</div>
				<div className='contact'>
					<h5>Connect With Us</h5>
					<ul>
						<li>
							<a href='tel:847-485-8464' className='tell-link'>
								<img
									src={images.placeholders.office_phone}
									alt='office phone'
									title='Office Phone'
									className='contact-icon'
								/>
								<span>847-485-8464</span>
							</a>
						</li>
						<li>
							<a href='tel:224-578-1717' className='tell-link'>
								<img
									src={images.placeholders.cell_phone}
									alt='cell phone'
									title='Cell Phone'
									className='contact-icon'
								/>
								<span>224-578-1717</span>
							</a>
						</li>
						<li>
							<a href='mailto:tom@t-squaredbuilders.com' className='email-link'>
								<img
									src={images.placeholders.email}
									alt='email'
									title='Email'
									className='contact-icon'
								/>
								<span>tom@t-squaredbuilders.com</span>
							</a>
						</li>
						{/*
            <li>
              <a href="https://www.facebook.com/38thStreetStudios" class="social-link" target="_blank">
                <img src={images.social.facebook} alt="facebook" title="Facebook" class="contact-icon">
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/38thststudios" class="social-link" target="_blank">
                <img src={images.social.twitter} alt="twitter" title="Twitter" class="contact-icon">
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/38th-street-studios" class="social-link" target="_blank">
                <img src={images.social.linkedIn} alt="linkedIn"  title="LinkedIn" class="contact-icon">
                <span>linkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/38thstreetstudios/" class="social-link" target="_blank">
                <img src={images.social.instagram} alt="instagram" title="Instagram" class="contact-icon">
                <span>Instagram</span>
              </a>
            </li>
            */}
					</ul>
				</div>
				<div className='footer-nav'>
					<h5>Explore</h5>
					<ul>
						<li>
							<Link className='nav-link' to='/'>
								HOME
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/story'>
								STORY
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/guarantees'>
								GUARANTEES
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/gallery'>
								GALLERIES
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/testimonials'>
								TESTIMONIALS
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/news'>
								NEWS
							</Link>
						</li>
						<li>
							<Link className='nav-link' to='/contact'>
								CONTACT
							</Link>
						</li>
					</ul>
				</div>
				<div className='information mobile'>
					<div className='company'>T-Squared Builders LLC</div>
					<div className='address'>N. Hoffman Estates, IL, 60192</div>
					<div className='copyright'>
						©<span id='year' />
						ALL RIGHTS RESERVED
					</div>
				</div>
				<div className='powered-by'>
					Carefully Crafted by
					<br />
					<a href='https://www.38thstreetstudios.com/'>38th Street Studios</a>
				</div>
			</footer>
		)
	}
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Radium(Footer)
