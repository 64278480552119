//GLOBAL
export const SHOW_ALERT					= 'show_alert'
export const HIDE_ALERT					= 'hide_alert'
export const SHOW_CONFIRM				= 'show_confirm'
export const HIDE_CONFIRM				= 'hide_confirm'
export const SHOW_NETWORK_ACTIVITY		= 'show_network_activity'
export const HIDE_NETWORK_ACTIVITY		= 'hide_network_activity'

//
// Global Stores
//

//HOME
export const HomeKeys = {
	CONTACT_NAME_CHANGED 		: 'home__contact_name_changed',
	CONTACT_EMAIL_CHANGED 		: 'home__contact_email_changed',
	CONTACT_SUBJECT_CHANGED 	: 'home__contact_subject_changed',
	CONTACT_MESSAGE_CHANGED 	: 'home__contact_message_changed',

	SEND_CONTACT_EMAIl_SUCCESS 	: 'home__send_contact_email_success',
	SEND_CONTACT_EMAIl_FAIL 	: 'home__send_contact_email_fail',
}
