import {
	HomeKeys
} from './../actions/types'

const INITIAL_STATE = {
	contactNameData : {
		title		: 'Name',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	contactEmailData : {
		title		: 'Email Address',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	contactSubjectData : {
		title		: 'Subject',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	},
	contactMessageData : {
		title		: 'Message',
		value 		: '',
		valid 		: false,
		validLabel 	: 'Required',
	}
}

export default (state = INITIAL_STATE, action) => {
	const {
		CONTACT_NAME_CHANGED,
		CONTACT_EMAIL_CHANGED,
		CONTACT_SUBJECT_CHANGED,
		CONTACT_MESSAGE_CHANGED,

		SEND_CONTACT_EMAIl_SUCCESS,
		SEND_CONTACT_EMAIl_FAIL,
	} = HomeKeys

	switch (action.type) {
		case CONTACT_NAME_CHANGED:
			return { ...state, contactNameData: { ...state.contactNameData, ...action.payload } }
		case CONTACT_EMAIL_CHANGED:
			return { ...state, contactEmailData: { ...state.contactEmailData, ...action.payload } }
		case CONTACT_SUBJECT_CHANGED:
			return { ...state, contactSubjectData: { ...state.contactSubjectData, ...action.payload } }
		case CONTACT_MESSAGE_CHANGED:
			return { ...state, contactMessageData: { ...state.contactMessageData, ...action.payload } }

		case SEND_CONTACT_EMAIl_SUCCESS:
			return { ...state,
				contactNameData 	: INITIAL_STATE.contactNameData,
				contactEmailData 	: INITIAL_STATE.contactEmailData,
				contactSubjectData 	: INITIAL_STATE.contactSubjectData,
				contactMessageData 	: INITIAL_STATE.contactMessageData,
			}
		case SEND_CONTACT_EMAIl_FAIL:
			return state

		default:
			return state
	}
}

