export const bathroom3819 = {
  "1-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/1-thumb.jpg"),
  "1.jpg": require("./../img/gallery/Bathroom/bathroom3819/1.jpg"),
  "10-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/10-thumb.jpg"),
  "10.jpg": require("./../img/gallery/Bathroom/bathroom3819/10.jpg"),
  "11-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/11-thumb.jpg"),
  "11.jpg": require("./../img/gallery/Bathroom/bathroom3819/11.jpg"),
  "12-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/12-thumb.jpg"),
  "12.jpg": require("./../img/gallery/Bathroom/bathroom3819/12.jpg"),
  "13-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/13-thumb.jpg"),
  "13.jpg": require("./../img/gallery/Bathroom/bathroom3819/13.jpg"),
  "14-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/14-thumb.jpg"),
  "14.jpg": require("./../img/gallery/Bathroom/bathroom3819/14.jpg"),
  "15-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/15-thumb.jpg"),
  "15.jpg": require("./../img/gallery/Bathroom/bathroom3819/15.jpg"),
  "16-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/16-thumb.jpg"),
  "16.jpg": require("./../img/gallery/Bathroom/bathroom3819/16.jpg"),
  "17-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/17-thumb.jpg"),
  "17.jpg": require("./../img/gallery/Bathroom/bathroom3819/17.jpg"),
  "18-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/18-thumb.jpg"),
  "18.jpg": require("./../img/gallery/Bathroom/bathroom3819/18.jpg"),
  "19-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/19-thumb.jpg"),
  "19.jpg": require("./../img/gallery/Bathroom/bathroom3819/19.jpg"),
  "2-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/2-thumb.jpg"),
  "2.jpg": require("./../img/gallery/Bathroom/bathroom3819/2.jpg"),
  "20-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/20-thumb.jpg"),
  "20.jpg": require("./../img/gallery/Bathroom/bathroom3819/20.jpg"),
  "21-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/21-thumb.jpg"),
  "21.jpg": require("./../img/gallery/Bathroom/bathroom3819/21.jpg"),
  "22-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/22-thumb.jpg"),
  "22.jpg": require("./../img/gallery/Bathroom/bathroom3819/22.jpg"),
  "23-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/23-thumb.jpg"),
  "23.jpg": require("./../img/gallery/Bathroom/bathroom3819/23.jpg"),
  "24-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/24-thumb.jpg"),
  "24.jpg": require("./../img/gallery/Bathroom/bathroom3819/24.jpg"),
  "25-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/25-thumb.jpg"),
  "25.jpg": require("./../img/gallery/Bathroom/bathroom3819/25.jpg"),
  "26-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/26-thumb.jpg"),
  "26.jpg": require("./../img/gallery/Bathroom/bathroom3819/26.jpg"),
  "27-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/27-thumb.jpg"),
  "27.jpg": require("./../img/gallery/Bathroom/bathroom3819/27.jpg"),
  "28-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/28-thumb.jpg"),
  "28.jpg": require("./../img/gallery/Bathroom/bathroom3819/28.jpg"),
  "29-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/29-thumb.jpg"),
  "29.jpg": require("./../img/gallery/Bathroom/bathroom3819/29.jpg"),
  "3-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/3-thumb.jpg"),
  "3.jpg": require("./../img/gallery/Bathroom/bathroom3819/3.jpg"),
  "30-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/30-thumb.jpg"),
  "30.jpg": require("./../img/gallery/Bathroom/bathroom3819/30.jpg"),
  "31-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/31-thumb.jpg"),
  "31.jpg": require("./../img/gallery/Bathroom/bathroom3819/31.jpg"),
  "32-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/32-thumb.jpg"),
  "32.jpg": require("./../img/gallery/Bathroom/bathroom3819/32.jpg"),
  "33-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/33-thumb.jpg"),
  "33.jpg": require("./../img/gallery/Bathroom/bathroom3819/33.jpg"),
  "34-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/34-thumb.jpg"),
  "34.jpg": require("./../img/gallery/Bathroom/bathroom3819/34.jpg"),
  "35-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/35-thumb.jpg"),
  "35.jpg": require("./../img/gallery/Bathroom/bathroom3819/35.jpg"),
  "36-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/36-thumb.jpg"),
  "36.jpg": require("./../img/gallery/Bathroom/bathroom3819/36.jpg"),
  "37-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/37-thumb.jpg"),
  "37.jpg": require("./../img/gallery/Bathroom/bathroom3819/37.jpg"),
  "38-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/38-thumb.jpg"),
  "38.jpg": require("./../img/gallery/Bathroom/bathroom3819/38.jpg"),
  "39-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/39-thumb.jpg"),
  "39.jpg": require("./../img/gallery/Bathroom/bathroom3819/39.jpg"),
  "4-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/4-thumb.jpg"),
  "4.jpg": require("./../img/gallery/Bathroom/bathroom3819/4.jpg"),
  "40-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/40-thumb.jpg"),
  "40.jpg": require("./../img/gallery/Bathroom/bathroom3819/40.jpg"),
  "5-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/5-thumb.jpg"),
  "5.jpg": require("./../img/gallery/Bathroom/bathroom3819/5.jpg"),
  "6-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/6-thumb.jpg"),
  "6.jpg": require("./../img/gallery/Bathroom/bathroom3819/6.jpg"),
  "7-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/7-thumb.jpg"),
  "7.jpg": require("./../img/gallery/Bathroom/bathroom3819/7.jpg"),
  "8-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/8-thumb.jpg"),
  "8.jpg": require("./../img/gallery/Bathroom/bathroom3819/8.jpg"),
  "9-thumb.jpg": require("./../img/gallery/Bathroom/bathroom3819/9-thumb.jpg"),
  "9.jpg": require("./../img/gallery/Bathroom/bathroom3819/9.jpg"),
}
export const basement21419 = {
  "51407036_2272630366104989_2856969007863431168_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51407036_2272630366104989_2856969007863431168_o-thumb.jpg"),
  "51407036_2272630366104989_2856969007863431168_o.jpg": require("./../img/gallery/Basement/basement21419/51407036_2272630366104989_2856969007863431168_o.jpg"),
  "51460946_2272637622770930_5267827373373390848_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51460946_2272637622770930_5267827373373390848_o-thumb.jpg"),
  "51460946_2272637622770930_5267827373373390848_o.jpg": require("./../img/gallery/Basement/basement21419/51460946_2272637622770930_5267827373373390848_o.jpg"),
  "51500009_2272643316103694_1448951408224108544_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51500009_2272643316103694_1448951408224108544_o-thumb.jpg"),
  "51500009_2272643316103694_1448951408224108544_o.jpg": require("./../img/gallery/Basement/basement21419/51500009_2272643316103694_1448951408224108544_o.jpg"),
  "51502869_2272638872770805_878663683566206976_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51502869_2272638872770805_878663683566206976_o-thumb.jpg"),
  "51502869_2272638872770805_878663683566206976_o.jpg": require("./../img/gallery/Basement/basement21419/51502869_2272638872770805_878663683566206976_o.jpg"),
  "51559189_2272639819437377_4904157099258281984_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51559189_2272639819437377_4904157099258281984_o-thumb.jpg"),
  "51559189_2272639819437377_4904157099258281984_o.jpg": require("./../img/gallery/Basement/basement21419/51559189_2272639819437377_4904157099258281984_o.jpg"),
  "51584439_2272632246104801_8953581071996289024_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51584439_2272632246104801_8953581071996289024_o-thumb.jpg"),
  "51584439_2272632246104801_8953581071996289024_o.jpg": require("./../img/gallery/Basement/basement21419/51584439_2272632246104801_8953581071996289024_o.jpg"),
  "51593560_2272635869437772_7733119308284297216_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51593560_2272635869437772_7733119308284297216_o-thumb.jpg"),
  "51593560_2272635869437772_7733119308284297216_o.jpg": require("./../img/gallery/Basement/basement21419/51593560_2272635869437772_7733119308284297216_o.jpg"),
  "51611005_2272642219437137_6443788156297805824_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51611005_2272642219437137_6443788156297805824_o-thumb.jpg"),
  "51611005_2272642219437137_6443788156297805824_o.jpg": require("./../img/gallery/Basement/basement21419/51611005_2272642219437137_6443788156297805824_o.jpg"),
  "51615242_2272991659402193_6858399572926922752_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51615242_2272991659402193_6858399572926922752_o-thumb.jpg"),
  "51615242_2272991659402193_6858399572926922752_o.jpg": require("./../img/gallery/Basement/basement21419/51615242_2272991659402193_6858399572926922752_o.jpg"),
  "51620166_2272636209437738_8860891631889416192_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51620166_2272636209437738_8860891631889416192_o-thumb.jpg"),
  "51620166_2272636209437738_8860891631889416192_o.jpg": require("./../img/gallery/Basement/basement21419/51620166_2272636209437738_8860891631889416192_o.jpg"),
  "51628630_2272638626104163_5244003761013129216_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51628630_2272638626104163_5244003761013129216_o-thumb.jpg"),
  "51628630_2272638626104163_5244003761013129216_o.jpg": require("./../img/gallery/Basement/basement21419/51628630_2272638626104163_5244003761013129216_o.jpg"),
  "51638564_2272639156104110_2082938557353164800_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51638564_2272639156104110_2082938557353164800_o-thumb.jpg"),
  "51638564_2272639156104110_2082938557353164800_o.jpg": require("./../img/gallery/Basement/basement21419/51638564_2272639156104110_2082938557353164800_o.jpg"),
  "51646023_2272631366104889_1712472773097422848_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51646023_2272631366104889_1712472773097422848_o-thumb.jpg"),
  "51646023_2272631366104889_1712472773097422848_o.jpg": require("./../img/gallery/Basement/basement21419/51646023_2272631366104889_1712472773097422848_o.jpg"),
  "51691351_2272631999438159_1520789958929940480_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51691351_2272631999438159_1520789958929940480_o-thumb.jpg"),
  "51691351_2272631999438159_1520789958929940480_o.jpg": require("./../img/gallery/Basement/basement21419/51691351_2272631999438159_1520789958929940480_o.jpg"),
  "51718645_2272632446104781_3371402658521808896_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51718645_2272632446104781_3371402658521808896_o-thumb.jpg"),
  "51718645_2272632446104781_3371402658521808896_o.jpg": require("./../img/gallery/Basement/basement21419/51718645_2272632446104781_3371402658521808896_o.jpg"),
  "51720738_2272632789438080_6730697658207305728_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51720738_2272632789438080_6730697658207305728_o-thumb.jpg"),
  "51720738_2272632789438080_6730697658207305728_o.jpg": require("./../img/gallery/Basement/basement21419/51720738_2272632789438080_6730697658207305728_o.jpg"),
  "51734001_2272639376104088_2015905014194634752_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51734001_2272639376104088_2015905014194634752_o-thumb.jpg"),
  "51734001_2272639376104088_2015905014194634752_o.jpg": require("./../img/gallery/Basement/basement21419/51734001_2272639376104088_2015905014194634752_o.jpg"),
  "51741884_2272645029436856_167964982556753920_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51741884_2272645029436856_167964982556753920_o-thumb.jpg"),
  "51741884_2272645029436856_167964982556753920_o.jpg": require("./../img/gallery/Basement/basement21419/51741884_2272645029436856_167964982556753920_o.jpg"),
  "51741942_2272636802771012_1461033606494289920_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51741942_2272636802771012_1461033606494289920_o-thumb.jpg"),
  "51741942_2272636802771012_1461033606494289920_o.jpg": require("./../img/gallery/Basement/basement21419/51741942_2272636802771012_1461033606494289920_o.jpg"),
  "51769701_2272630646104961_8513252473530482688_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51769701_2272630646104961_8513252473530482688_o-thumb.jpg"),
  "51769701_2272630646104961_8513252473530482688_o.jpg": require("./../img/gallery/Basement/basement21419/51769701_2272630646104961_8513252473530482688_o.jpg"),
  "51775714_2272643986103627_3449412342792585216_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51775714_2272643986103627_3449412342792585216_o-thumb.jpg"),
  "51775714_2272643986103627_3449412342792585216_o.jpg": require("./../img/gallery/Basement/basement21419/51775714_2272643986103627_3449412342792585216_o.jpg"),
  "51783608_2272990772735615_8419929555812220928_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51783608_2272990772735615_8419929555812220928_o-thumb.jpg"),
  "51783608_2272990772735615_8419929555812220928_o.jpg": require("./../img/gallery/Basement/basement21419/51783608_2272990772735615_8419929555812220928_o.jpg"),
  "51794134_2272991136068912_1777665149675503616_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51794134_2272991136068912_1777665149675503616_o-thumb.jpg"),
  "51794134_2272991136068912_1777665149675503616_o.jpg": require("./../img/gallery/Basement/basement21419/51794134_2272991136068912_1777665149675503616_o.jpg"),
  "51810461_2272631059438253_4566736617284304896_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51810461_2272631059438253_4566736617284304896_o-thumb.jpg"),
  "51810461_2272631059438253_4566736617284304896_o.jpg": require("./../img/gallery/Basement/basement21419/51810461_2272631059438253_4566736617284304896_o.jpg"),
  "51836638_2272989132735779_6913186059790581760_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51836638_2272989132735779_6913186059790581760_o-thumb.jpg"),
  "51836638_2272989132735779_6913186059790581760_o.jpg": require("./../img/gallery/Basement/basement21419/51836638_2272989132735779_6913186059790581760_o.jpg"),
  "51861134_2272645556103470_8490199992483446784_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51861134_2272645556103470_8490199992483446784_o-thumb.jpg"),
  "51861134_2272645556103470_8490199992483446784_o.jpg": require("./../img/gallery/Basement/basement21419/51861134_2272645556103470_8490199992483446784_o.jpg"),
  "51863714_2272635462771146_5339883992453742592_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51863714_2272635462771146_5339883992453742592_o-thumb.jpg"),
  "51863714_2272635462771146_5339883992453742592_o.jpg": require("./../img/gallery/Basement/basement21419/51863714_2272635462771146_5339883992453742592_o.jpg"),
  "51895266_2272637092770983_7416204520599846912_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51895266_2272637092770983_7416204520599846912_o-thumb.jpg"),
  "51895266_2272637092770983_7416204520599846912_o.jpg": require("./../img/gallery/Basement/basement21419/51895266_2272637092770983_7416204520599846912_o.jpg"),
  "51910841_2272989526069073_1983295986100862976_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51910841_2272989526069073_1983295986100862976_o-thumb.jpg"),
  "51910841_2272989526069073_1983295986100862976_o.jpg": require("./../img/gallery/Basement/basement21419/51910841_2272989526069073_1983295986100862976_o.jpg"),
  "51914298_2272641722770520_553668683713478656_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/51914298_2272641722770520_553668683713478656_o-thumb.jpg"),
  "51914298_2272641722770520_553668683713478656_o.jpg": require("./../img/gallery/Basement/basement21419/51914298_2272641722770520_553668683713478656_o.jpg"),
  "52020720_2272630859438273_5252682768931880960_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52020720_2272630859438273_5252682768931880960_o-thumb.jpg"),
  "52020720_2272630859438273_5252682768931880960_o.jpg": require("./../img/gallery/Basement/basement21419/52020720_2272630859438273_5252682768931880960_o.jpg"),
  "52029381_2272631196104906_7833596907078287360_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52029381_2272631196104906_7833596907078287360_o-thumb.jpg"),
  "52029381_2272631196104906_7833596907078287360_o.jpg": require("./../img/gallery/Basement/basement21419/52029381_2272631196104906_7833596907078287360_o.jpg"),
  "52038475_2272635709437788_840763912893759488_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52038475_2272635709437788_840763912893759488_o-thumb.jpg"),
  "52038475_2272635709437788_840763912893759488_o.jpg": require("./../img/gallery/Basement/basement21419/52038475_2272635709437788_840763912893759488_o.jpg"),
  "52421005_2272631829438176_6925157542393806848_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52421005_2272631829438176_6925157542393806848_o-thumb.jpg"),
  "52421005_2272631829438176_6925157542393806848_o.jpg": require("./../img/gallery/Basement/basement21419/52421005_2272631829438176_6925157542393806848_o.jpg"),
  "52461586_2272641226103903_1909910787606970368_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52461586_2272641226103903_1909910787606970368_o-thumb.jpg"),
  "52461586_2272641226103903_1909910787606970368_o.jpg": require("./../img/gallery/Basement/basement21419/52461586_2272641226103903_1909910787606970368_o.jpg"),
  "52536793_2272632642771428_1252659595931811840_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52536793_2272632642771428_1252659595931811840_o-thumb.jpg"),
  "52536793_2272632642771428_1252659595931811840_o.jpg": require("./../img/gallery/Basement/basement21419/52536793_2272632642771428_1252659595931811840_o.jpg"),
  "52558962_2272639569437402_6806497689178472448_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52558962_2272639569437402_6806497689178472448_o-thumb.jpg"),
  "52558962_2272639569437402_6806497689178472448_o.jpg": require("./../img/gallery/Basement/basement21419/52558962_2272639569437402_6806497689178472448_o.jpg"),
  "52569271_2272638372770855_46352644268097536_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52569271_2272638372770855_46352644268097536_o-thumb.jpg"),
  "52569271_2272638372770855_46352644268097536_o.jpg": require("./../img/gallery/Basement/basement21419/52569271_2272638372770855_46352644268097536_o.jpg"),
  "52674289_2272640306103995_442878464714866688_o-thumb.jpg": require("./../img/gallery/Basement/basement21419/52674289_2272640306103995_442878464714866688_o-thumb.jpg"),
  "52674289_2272640306103995_442878464714866688_o.jpg": require("./../img/gallery/Basement/basement21419/52674289_2272640306103995_442878464714866688_o.jpg"),
}
const images = {
  logo: require("./../img/logo.svg"),
  texture2: require("./../img/texture2.png"),
  down_arrow: require("./../img/down-arrow.png"),
  navIcon: require("./../img/navIcon.svg"),
  rpfpc: require("./../img/rpfpc.png"),
  logo_text: require("./../img/logo-text.png"),
  sandwich_button: require("./../img/sandwich-button.png"),

  social: {
    facebook: require("./../img/social/facebook.svg"),
    linkedIn: require("./../img/social/linkedIn.svg"),
    twitter: require("./../img/social/twitter.svg"),
    medium: require("./../img/social/medium.svg"),
    youtube: require("./../img/social/youTube.svg"),
    website: require("./../img/social/website.svg"),

    facebookBlank: require("./../img/social/facebookBlank.svg"),
    linkedInBlank: require("./../img/social/linkedInBlank.svg"),
    twitterBlank: require("./../img/social/twitterBlank.svg"),
    instagramBlank: require("./../img/social/instagramBlank.svg"),
    mediumBlank: require("./../img/social/mediumBlank.svg"),
    youtubeBlank: require("./../img/social/youtubeBlank.svg"),
    behanceBlank: require("./../img/social/behanceBlank.svg"),
    pinterestBlank: require("./../img/social/pinterestBlank.svg"),
    etsyBlank: require("./../img/social/etsyBlank.svg"),
  },

  //----------------------FOOTER-------------------------------------------
  placeholders: {
    cell_phone: require("./../img/placeholders/cell-phone.png"),
    email: require("./../img/placeholders/email.png"),
    office_phone: require("./../img/placeholders/office-phone.png"),
  },

  //----------------------GUARANTEES-------------------------------------------
  promises_header: require("./../img/promises-header.jpg"),

  //----------------------CONTACT----------------------------------------------
  contact_header: require("./../img/contact-header.jpg"),

  //----------------------NEWS----------------------------------------------
  news_header: require("./../img/news-header.jpg"),
  news: {
    Best_of_2015_Award: require("./../img/news/Best-of-2015-Award.jpg"),
    pressConference: require("./../img/news/pressConference.jpg"),
    splashpad: require("./../img/news/splashpad.jpg"),
    Sun_Times_New1: require("./../img/news/Sun-Times-New1.pdf"),
    suntimes: require("./../img/news/suntimes.jpg"),
    TrendingCover: require("./../img/news/TrendingCover.jpg"),
  },

  //----------------------TESTIMONIALS-----------------------------------------
  testimonials_header: require("./../img/testimonials-header.jpg"),

  //----------------------STORY-----------------------------------------
  story_header: require("./../img/story-header.jpg"),
  tom_serpento: require("./../img/tom-serpento.jpg"),

  crew: {
    AdamRamirez: require("./../img/crew/AdamRamirez.jpg"),
    AndreiViscribov: require("./../img/crew/AndreiViscribov.jpg"),
    AndrewBiewer: require("./../img/crew/AndrewBiewer.jpg"),
    AndrewDeak: require("./../img/crew/AndrewDeak.jpg"),
    AndrewMeintzer: require("./../img/crew/AndrewMeintzer.jpg"),
    AndrzejAndySmiarowski: require("./../img/crew/AndrzejAndySmiarowski.jpg"),
    AndyHoffman: require("./../img/crew/AndyHoffman.jpg"),
    AntonioDelgadillo: require("./../img/crew/AntonioDelgadillo.jpg"),
    AudiHavel: require("./../img/crew/AudiHavel.jpg"),
    BillBiewer: require("./../img/crew/BillBiewer.jpg"),
    BillGalichio: require("./../img/crew/BillGalichio.jpg"),
    BobShultz: require("./../img/crew/BobShultz.jpg"),
    BogadonBogiDwojak: require("./../img/crew/BogadonBogiDwojak.jpg"),
    BretLamb: require("./../img/crew/BretLamb.jpg"),
    BrianAllen: require("./../img/crew/BrianAllen.jpg"),
    BrianCarlborg: require("./../img/crew/BrianCarlborg.jpg"),
    BrianCibrowski: require("./../img/crew/BrianCibrowski.jpg"),
    CarlosEscobedo: require("./../img/crew/CarlosEscobedo.jpg"),
    CesarRecendiz: require("./../img/crew/CesarRecendiz.jpg"),
    ClayLamb: require("./../img/crew/ClayLamb.jpg"),
    CoachAndTom: require("./../img/crew/CoachAndTom.jpg"),
    CoachAndTom_resized: require("./../img/crew/CoachAndTom_resized.jpg"),
    DanMooney: require("./../img/crew/DanMooney.jpg"),
    ErnieIbarra: require("./../img/crew/ErnieIbarra.jpg"),
    FernandoTheFleaTorres: require("./../img/crew/FernandoTheFleaTorres.jpg"),
    GeorgeBigler: require("./../img/crew/GeorgeBigler.jpg"),
    GeorgeJackson: require("./../img/crew/GeorgeJackson.jpg"),
    GregHacker: require("./../img/crew/GregHacker.jpg"),
    GregPitek: require("./../img/crew/GregPitek.jpg"),
    GrigoreAndyDobrican: require("./../img/crew/GrigoreAndyDobrican.jpg"),
    group: require("./../img/crew/group.jpg"),
    HoracioMuresan: require("./../img/crew/HoracioMuresan.jpg"),
    IoanPop: require("./../img/crew/IoanPop.jpg"),
    IosifHardar: require("./../img/crew/IosifHardar.jpg"),
    IsmaelJrSanchez: require("./../img/crew/IsmaelJrSanchez.jpg"),
    IvanCamarzo: require("./../img/crew/IvanCamarzo.jpg"),
    JacobRecendiz: require("./../img/crew/JacobRecendiz.jpg"),
    JamieHolt: require("./../img/crew/JamieHolt.jpg"),
    JasonKeeley: require("./../img/crew/JasonKeeley.jpg"),
    JasonRidgeway: require("./../img/crew/JasonRidgeway.jpg"),
    JavierAlvarez: require("./../img/crew/JavierAlvarez.jpg"),
    JeremyOlson: require("./../img/crew/JeremyOlson.jpg"),
    JoeKalous: require("./../img/crew/JoeKalous.jpg"),
    JorgeGonzalez: require("./../img/crew/JorgeGonzalez.jpg"),
    JoseLuisRodriguez: require("./../img/crew/JoseLuisRodriguez.jpg"),
    JoseRecendizJr: require("./../img/crew/JoseRecendizJr.jpg"),
    JoseRecendizSr: require("./../img/crew/JoseRecendizSr.jpg"),
    JoseSoto: require("./../img/crew/JoseSoto.jpg"),
    KavinHortado: require("./../img/crew/KavinHortado.jpg"),
    KenBogus: require("./../img/crew/KenBogus.jpg"),
    KrzysztofKrisLupinski: require("./../img/crew/KrzysztofKrisLupinski.jpg"),
    LeonardHouda: require("./../img/crew/LeonardHouda.jpg"),
    LuisAlvarez: require("./../img/crew/LuisAlvarez.jpg"),
    LuisGuzman: require("./../img/crew/LuisGuzman.jpg"),
    ManuelTheCowFernandez: require("./../img/crew/ManuelTheCowFernandez.jpg"),
    MemoSanchez: require("./../img/crew/MemoSanchez.jpg"),
    MichaelAbrignani: require("./../img/crew/MichaelAbrignani.jpg"),
    MiguelMedoro: require("./../img/crew/MiguelMedoro.jpg"),
    MikeGasen: require("./../img/crew/MikeGasen.jpg"),
    MikeSlabich: require("./../img/crew/MikeSlabich.jpg"),
    MykolaRomanyuk: require("./../img/crew/MykolaRomanyuk.jpg"),
    OlehVivcharyk: require("./../img/crew/OlehVivcharyk.jpg"),
    PaulMeintzer: require("./../img/crew/PaulMeintzer.jpg"),
    PaulSirbu: require("./../img/crew/PaulSirbu.jpg"),
    PeteHavel: require("./../img/crew/PeteHavel.jpg"),
    PeteLamb: require("./../img/crew/PeteLamb.jpg"),
    PhillOlsen: require("./../img/crew/PhillOlsen.jpg"),
    RaydelMendoza: require("./../img/crew/RaydelMendoza.jpg"),
    RichardAceDulceak: require("./../img/crew/RichardAceDulceak.jpg"),
    RichBarfield: require("./../img/crew/RichBarfield.jpg"),
    RichPavlik: require("./../img/crew/RichPavlik.jpg"),
    RobertVarlam: require("./../img/crew/RobertVarlam.jpg"),
    RyanVegaPerez: require("./../img/crew/RyanVegaPerez.jpg"),
    ShawnGunderson: require("./../img/crew/ShawnGunderson.jpg"),
    SonnySmith: require("./../img/crew/SonnySmith.jpg"),
    SteveBriddick: require("./../img/crew/SteveBriddick.jpg"),
    SteveCibrowski: require("./../img/crew/SteveCibrowski.jpg"),
    TeodorTeoSilas: require("./../img/crew/TeodorTeoSilas.jpg"),
    TimLeo: require("./../img/crew/TimLeo.jpg"),
    TomLeo: require("./../img/crew/TomLeo.jpg"),
    TomSerpento: require("./../img/crew/TomSerpento.jpg"),
    TomSerpento_blue: require("./../img/crew/TomSerpento_blue.jpg"),
    TomSerpento_camel: require("./../img/crew/TomSerpento_camel.jpg"),
    WesWhite: require("./../img/crew/WesWhite.jpg"),
    WilliamBiewerJr: require("./../img/crew/WilliamBiewerJr.jpg"),
    ZachLeo: require("./../img/crew/ZachLeo.jpg"),
    RandyGabel: require("./../img/crew/RandyGabel2020.jpg"),
    BarryNagle: require("./../img/crew/BarryNagle2020.jpg"),
    ScottSasman: require("./../img/crew/ScottSasman2020.jpg"),
  },
  //----------------------HOME-------------------------------------------------
  rotator: {
    flooring: require("./../img/rotator/flooring.jpg"),
    rotator_2: require("./../img/rotator/rotator_2.jpg"),
    rotator_5: require("./../img/rotator/rotator_5.jpg"),
    kitchens: require("./../img/rotator/kitchens.jpg"),
    bathrooms: require("./../img/rotator/bathrooms.jpg"),
  },

  services: {
    additions: require("./../img/services/additions.png"),
    electrical: require("./../img/services/electrical.png"),
    flooring: require("./../img/services/flooring.png"),
    historical: require("./../img/services/historical.png"),
    interior: require("./../img/services/interior.png"),
    additionsOrange: require("./../img/services/orange/additions.png"),
    electricalOrange: require("./../img/services/orange/electrical.png"),
    flooringOrange: require("./../img/services/orange/flooring.png"),
    historicalOrange: require("./../img/services/orange/historical.png"),
    interiorOrange: require("./../img/services/orange/interior.png"),
    remodelingOrange: require("./../img/services/orange/remodeling.png"),
    paint: require("./../img/services/paint.png"),
    remodeling: require("./../img/services/remodeling.png"),
    roof: require("./../img/services/roof.png"),
    window: require("./../img/services/window.png"),
  },

  vendor: {
    three8ss_logo_and_text: require("./../img/vendor/38ss_logo_and_text.png"),
    accsolutions_logo: require("./../img/vendor/accsolutions_logo.jpg"),
    advantage_logo: require("./../img/vendor/advantage_logo.jpg"),
    barringtonbt_ogo: require("./../img/vendor/barringtonbt_ogo.jpg"),
    bosch_logo: require("./../img/vendor/bosch-logo.png"),
    briddickts_logo: require("./../img/vendor/briddickts_logo.jpg"),
    centralglasschicago_logo: require("./../img/vendor/centralglasschicago_logo.jpg"),
    damicopaving_logo: require("./../img/vendor/damicopaving_logo.jpg"),
    dewalt_logo: require("./../img/vendor/dewalt_logo.jpg"),
    grange_logo: require("./../img/vendor/grange_logo.jpg"),
    grohe_logo: require("./../img/vendor/grohe_logo.png"),
    habitat_logo: require("./../img/vendor/habitat_logo.jpg"),
    homedepot_logo: require("./../img/vendor/homedepot_logo.jpg"),
    IMT_logo: require("./../img/vendor/IMT_logo.png"),
    jsp_logo: require("./../img/vendor/jsp_logo.png"),
    knutte_logo: require("./../img/vendor/knutte_logo.jpg"),
    kohler_logo: require("./../img/vendor/kohler_logo.jpg"),
    kraftmaid_logo: require("./../img/vendor/kraftmaid_logo.jpg"),
    kwikprint_logo: require("./../img/vendor/kwikprint_logo.jpg"),
    litpub_logo: require("./../img/vendor/litpub_logo.jpg"),
    luna_logo: require("./../img/vendor/luna_logo.jpg"),
    maher_logo: require("./../img/vendor/maher_logo.jpg"),
    ridgewayhvac_logo: require("./../img/vendor/ridgewayhvac_logo.jpg"),
    robern_logo: require("./../img/vendor/robern_logo.jpg"),
    rpfpc_logo: require("./../img/vendor/rpfpc_logo.gif"),
    sponsor_H4H: require("./../img/vendor/sponsor_H4H.png"),
    sponsor_rpfpc: require("./../img/vendor/sponsor_rpfpc.png"),
    sponsor_unitedway: require("./../img/vendor/sponsor_unitedway.png"),
    thomasvillecabinets_logo: require("./../img/vendor/thomasvillecabinets_logo.jpg"),
    tileshop_logo: require("./../img/vendor/tileshop_logo.jpg"),
    triggerone_logo: require("./../img/vendor/triggerone_logo.jpg"),
    unitedway_logo: require("./../img/vendor/unitedway_logo.gif"),
  },

  //----------------------WORK-------------------------------------------------
  gallery: {
    basement: {
      Basement_A: {
        one_thumb: require("./../img/gallery/Basement/Basement A/1-thumb.jpg"),
        one: require("./../img/gallery/Basement/Basement A/1.jpg"),
        one2_thumb: require("./../img/gallery/Basement/Basement A/12-thumb.jpg"),
        one2: require("./../img/gallery/Basement/Basement A/12.jpg"),
        one3_thumb: require("./../img/gallery/Basement/Basement A/13-thumb.jpg"),
        one3: require("./../img/gallery/Basement/Basement A/13.jpg"),
        one5_thumb: require("./../img/gallery/Basement/Basement A/15-thumb.jpg"),
        one5: require("./../img/gallery/Basement/Basement A/15.jpg"),
        one6_thumb: require("./../img/gallery/Basement/Basement A/16-thumb.jpg"),
        one6: require("./../img/gallery/Basement/Basement A/16.jpg"),
        one7_thumb: require("./../img/gallery/Basement/Basement A/17-thumb.jpg"),
        one7: require("./../img/gallery/Basement/Basement A/17.jpg"),
        one8_thumb_5: require("./../img/gallery/Basement/Basement A/18-thumb.5.jpg"),
        one8_5: require("./../img/gallery/Basement/Basement A/18.5.jpg"),
        one9_thumb_1: require("./../img/gallery/Basement/Basement A/19-thumb.1.jpg"),
        one9_thumb_2: require("./../img/gallery/Basement/Basement A/19-thumb.2.jpg"),
        one9_thumb: require("./../img/gallery/Basement/Basement A/19-thumb.jpg"),
        one9_1: require("./../img/gallery/Basement/Basement A/19.1.jpg"),
        one9_2: require("./../img/gallery/Basement/Basement A/19.2.jpg"),
        one9: require("./../img/gallery/Basement/Basement A/19.jpg"),
        two0_thumb: require("./../img/gallery/Basement/Basement A/20-thumb.jpg"),
        two0: require("./../img/gallery/Basement/Basement A/20.jpg"),
        two1_thumb: require("./../img/gallery/Basement/Basement A/21-thumb.jpg"),
        two1: require("./../img/gallery/Basement/Basement A/21.jpg"),
        two2_thumb_5: require("./../img/gallery/Basement/Basement A/22-thumb.5.jpg"),
        two2_5: require("./../img/gallery/Basement/Basement A/22.5.jpg"),
        two3_thumb_1: require("./../img/gallery/Basement/Basement A/23-thumb.1.jpg"),
        two3_thumb_2: require("./../img/gallery/Basement/Basement A/23-thumb.2.jpg"),
        two3_thumb: require("./../img/gallery/Basement/Basement A/23-thumb.jpg"),
        two3_1: require("./../img/gallery/Basement/Basement A/23.1.jpg"),
        two3_2: require("./../img/gallery/Basement/Basement A/23.2.jpg"),
        two3: require("./../img/gallery/Basement/Basement A/23.jpg"),
        two4_thumb: require("./../img/gallery/Basement/Basement A/24-thumb.jpg"),
        two4: require("./../img/gallery/Basement/Basement A/24.jpg"),
        two5_thumb: require("./../img/gallery/Basement/Basement A/25-thumb.jpg"),
        two5: require("./../img/gallery/Basement/Basement A/25.jpg"),
        two6_thumb: require("./../img/gallery/Basement/Basement A/26-thumb.jpg"),
        two6: require("./../img/gallery/Basement/Basement A/26.jpg"),
        two7_thumb_5: require("./../img/gallery/Basement/Basement A/27-thumb.5.jpg"),
        two7_thumb: require("./../img/gallery/Basement/Basement A/27-thumb.jpg"),
        two7_5: require("./../img/gallery/Basement/Basement A/27.5.jpg"),
        two7: require("./../img/gallery/Basement/Basement A/27.jpg"),
        two8_thumb: require("./../img/gallery/Basement/Basement A/28-thumb.jpg"),
        two8: require("./../img/gallery/Basement/Basement A/28.jpg"),
        two9_thumb: require("./../img/gallery/Basement/Basement A/29-thumb.jpg"),
        two9: require("./../img/gallery/Basement/Basement A/29.jpg"),
        three0_thumb: require("./../img/gallery/Basement/Basement A/30-thumb.jpg"),
        three0: require("./../img/gallery/Basement/Basement A/30.jpg"),
        three1_thumb: require("./../img/gallery/Basement/Basement A/31-thumb.jpg"),
        three1: require("./../img/gallery/Basement/Basement A/31.jpg"),
        five_thumb: require("./../img/gallery/Basement/Basement A/5-thumb.jpg"),
        five: require("./../img/gallery/Basement/Basement A/5.jpg"),
        seven_thumb: require("./../img/gallery/Basement/Basement A/7-thumb.jpg"),
        seven: require("./../img/gallery/Basement/Basement A/7.jpg"),
        eight_thumb: require("./../img/gallery/Basement/Basement A/8-thumb.jpg"),
        eight: require("./../img/gallery/Basement/Basement A/8.jpg"),
        Copy_of_7_thumb: require("./../img/gallery/Basement/Basement A/Copy-of-7-thumb.jpg"),
        Copy_of_7: require("./../img/gallery/Basement/Basement A/Copy-of-7.jpg"),
        PALATINE_BASEMENT_July_2013_032_thumb: require("./../img/gallery/Basement/Basement A/PALATINE-BASEMENT-July-2013-032-thumb.jpg"),
        PALATINE_BASEMENT_July_2013_032: require("./../img/gallery/Basement/Basement A/PALATINE-BASEMENT-July-2013-032.jpg"),
        PALATINE_BASEMENT_July_2013_072_thumb: require("./../img/gallery/Basement/Basement A/PALATINE-BASEMENT-July-2013-072-thumb.jpg"),
        PALATINE_BASEMENT_July_2013_072: require("./../img/gallery/Basement/Basement A/PALATINE-BASEMENT-July-2013-072.jpg"),
      },
      basement11418: {
        two1_thumb: require("./../img/gallery/Basement/basement11418/21-thumb.jpg"),
        two1: require("./../img/gallery/Basement/basement11418/21.jpg"),
        two2_thumb: require("./../img/gallery/Basement/basement11418/22-thumb.jpg"),
        two2: require("./../img/gallery/Basement/basement11418/22.jpg"),
        two3_thumb: require("./../img/gallery/Basement/basement11418/23-thumb.jpg"),
        two3: require("./../img/gallery/Basement/basement11418/23.jpg"),
        two4_thumb: require("./../img/gallery/Basement/basement11418/24-thumb.jpg"),
        two4: require("./../img/gallery/Basement/basement11418/24.jpg"),
        two5_thumb: require("./../img/gallery/Basement/basement11418/25-thumb.jpg"),
        two5: require("./../img/gallery/Basement/basement11418/25.jpg"),
        two6_thumb: require("./../img/gallery/Basement/basement11418/26-thumb.jpg"),
        two6: require("./../img/gallery/Basement/basement11418/26.jpg"),
        two7_thumb: require("./../img/gallery/Basement/basement11418/27-thumb.jpg"),
        two7: require("./../img/gallery/Basement/basement11418/27.jpg"),
        two8_thumb: require("./../img/gallery/Basement/basement11418/28-thumb.jpg"),
        two8: require("./../img/gallery/Basement/basement11418/28.jpg"),
        two9_thumb: require("./../img/gallery/Basement/basement11418/29-thumb.jpg"),
        two9: require("./../img/gallery/Basement/basement11418/29.jpg"),
        three0_thumb: require("./../img/gallery/Basement/basement11418/30-thumb.jpg"),
        three0: require("./../img/gallery/Basement/basement11418/30.jpg"),
        three1_thumb: require("./../img/gallery/Basement/basement11418/31-thumb.jpg"),
        three1: require("./../img/gallery/Basement/basement11418/31.jpg"),
        three2_thumb: require("./../img/gallery/Basement/basement11418/32-thumb.jpg"),
        three2: require("./../img/gallery/Basement/basement11418/32.jpg"),
        three3_thumb: require("./../img/gallery/Basement/basement11418/33-thumb.jpg"),
        three3: require("./../img/gallery/Basement/basement11418/33.jpg"),
        three4_thumb: require("./../img/gallery/Basement/basement11418/34-thumb.jpg"),
        three4: require("./../img/gallery/Basement/basement11418/34.jpg"),
        three5_thumb: require("./../img/gallery/Basement/basement11418/35-thumb.jpg"),
        three5: require("./../img/gallery/Basement/basement11418/35.jpg"),
        three6_thumb: require("./../img/gallery/Basement/basement11418/36-thumb.jpg"),
        three6: require("./../img/gallery/Basement/basement11418/36.jpg"),
        three7_thumb: require("./../img/gallery/Basement/basement11418/37-thumb.jpg"),
        three7: require("./../img/gallery/Basement/basement11418/37.jpg"),
        three8_thumb: require("./../img/gallery/Basement/basement11418/38-thumb.jpg"),
        three8: require("./../img/gallery/Basement/basement11418/38.jpg"),
        three9_thumb: require("./../img/gallery/Basement/basement11418/39-thumb.jpg"),
        three9: require("./../img/gallery/Basement/basement11418/39.jpg"),
        four0_thumb: require("./../img/gallery/Basement/basement11418/40-thumb.jpg"),
        four0: require("./../img/gallery/Basement/basement11418/40.jpg"),
        four1_thumb: require("./../img/gallery/Basement/basement11418/41-thumb.jpg"),
        four1: require("./../img/gallery/Basement/basement11418/41.jpg"),
        four2_thumb: require("./../img/gallery/Basement/basement11418/42-thumb.jpg"),
        four2: require("./../img/gallery/Basement/basement11418/42.jpg"),
        four3_thumb: require("./../img/gallery/Basement/basement11418/43-thumb.jpg"),
        four3: require("./../img/gallery/Basement/basement11418/43.jpg"),
        four4_thumb: require("./../img/gallery/Basement/basement11418/44-thumb.jpg"),
        four4: require("./../img/gallery/Basement/basement11418/44.jpg"),
        four5_thumb: require("./../img/gallery/Basement/basement11418/45-thumb.jpg"),
        four5: require("./../img/gallery/Basement/basement11418/45.jpg"),
        four6_thumb: require("./../img/gallery/Basement/basement11418/46-thumb.jpg"),
        four6: require("./../img/gallery/Basement/basement11418/46.jpg"),
        four7_thumb: require("./../img/gallery/Basement/basement11418/47-thumb.jpg"),
        four7: require("./../img/gallery/Basement/basement11418/47.jpg"),
        four8_thumb: require("./../img/gallery/Basement/basement11418/48-thumb.jpg"),
        four8: require("./../img/gallery/Basement/basement11418/48.jpg"),
        four9_thumb: require("./../img/gallery/Basement/basement11418/49-thumb.jpg"),
        four9: require("./../img/gallery/Basement/basement11418/49.jpg"),
        five0_thumb: require("./../img/gallery/Basement/basement11418/50-thumb.jpg"),
        five0: require("./../img/gallery/Basement/basement11418/50.jpg"),
        five1_thumb: require("./../img/gallery/Basement/basement11418/51-thumb.jpg"),
        five1: require("./../img/gallery/Basement/basement11418/51.jpg"),
        five2_thumb: require("./../img/gallery/Basement/basement11418/52-thumb.jpg"),
        five2: require("./../img/gallery/Basement/basement11418/52.jpg"),
        five3_thumb: require("./../img/gallery/Basement/basement11418/53-thumb.jpg"),
        five3: require("./../img/gallery/Basement/basement11418/53.jpg"),
      },

      Basement_B: {
        basementc1_thumb: require("./../img/gallery/Basement/Basement B/basementc1-thumb.jpg"),
        basementc1: require("./../img/gallery/Basement/Basement B/basementc1.jpg"),
        basementc10_thumb: require("./../img/gallery/Basement/Basement B/basementc10-thumb.jpg"),
        basementc10: require("./../img/gallery/Basement/Basement B/basementc10.jpg"),
        basementc11_thumb: require("./../img/gallery/Basement/Basement B/basementc11-thumb.jpg"),
        basementc11: require("./../img/gallery/Basement/Basement B/basementc11.jpg"),
        basementc12_thumb: require("./../img/gallery/Basement/Basement B/basementc12-thumb.jpg"),
        basementc12: require("./../img/gallery/Basement/Basement B/basementc12.jpg"),
        basementc13_thumb: require("./../img/gallery/Basement/Basement B/basementc13-thumb.jpg"),
        basementc13: require("./../img/gallery/Basement/Basement B/basementc13.jpg"),
        basementc14_thumb: require("./../img/gallery/Basement/Basement B/basementc14-thumb.jpg"),
        basementc14: require("./../img/gallery/Basement/Basement B/basementc14.jpg"),
        basementc15_thumb: require("./../img/gallery/Basement/Basement B/basementc15-thumb.jpg"),
        basementc15: require("./../img/gallery/Basement/Basement B/basementc15.jpg"),
        basementc16_thumb: require("./../img/gallery/Basement/Basement B/basementc16-thumb.jpg"),
        basementc16: require("./../img/gallery/Basement/Basement B/basementc16.jpg"),
        basementc17_thumb: require("./../img/gallery/Basement/Basement B/basementc17-thumb.jpg"),
        basementc17: require("./../img/gallery/Basement/Basement B/basementc17.jpg"),
        basementc18_thumb: require("./../img/gallery/Basement/Basement B/basementc18-thumb.jpg"),
        basementc18: require("./../img/gallery/Basement/Basement B/basementc18.jpg"),
        basementc19_thumb: require("./../img/gallery/Basement/Basement B/basementc19-thumb.jpg"),
        basementc19: require("./../img/gallery/Basement/Basement B/basementc19.jpg"),
        basementc2_thumb: require("./../img/gallery/Basement/Basement B/basementc2-thumb.jpg"),
        basementc2: require("./../img/gallery/Basement/Basement B/basementc2.jpg"),
        basementc20_thumb: require("./../img/gallery/Basement/Basement B/basementc20-thumb.jpg"),
        basementc20: require("./../img/gallery/Basement/Basement B/basementc20.jpg"),
        basementc21_thumb: require("./../img/gallery/Basement/Basement B/basementc21-thumb.jpg"),
        basementc21: require("./../img/gallery/Basement/Basement B/basementc21.jpg"),
        basementc22_thumb: require("./../img/gallery/Basement/Basement B/basementc22-thumb.jpg"),
        basementc22: require("./../img/gallery/Basement/Basement B/basementc22.jpg"),
        basementc3_thumb: require("./../img/gallery/Basement/Basement B/basementc3-thumb.jpg"),
        basementc3: require("./../img/gallery/Basement/Basement B/basementc3.jpg"),
        basementc4_thumb: require("./../img/gallery/Basement/Basement B/basementc4-thumb.jpg"),
        basementc4: require("./../img/gallery/Basement/Basement B/basementc4.jpg"),
        basementc5_thumb: require("./../img/gallery/Basement/Basement B/basementc5-thumb.jpg"),
        basementc5: require("./../img/gallery/Basement/Basement B/basementc5.jpg"),
        basementc6_thumb: require("./../img/gallery/Basement/Basement B/basementc6-thumb.jpg"),
        basementc6: require("./../img/gallery/Basement/Basement B/basementc6.jpg"),
        basementc7_thumb: require("./../img/gallery/Basement/Basement B/basementc7-thumb.jpg"),
        basementc7: require("./../img/gallery/Basement/Basement B/basementc7.jpg"),
        basementc8_thumb: require("./../img/gallery/Basement/Basement B/basementc8-thumb.jpg"),
        basementc8: require("./../img/gallery/Basement/Basement B/basementc8.jpg"),
        basementc9_thumb: require("./../img/gallery/Basement/Basement B/basementc9-thumb.jpg"),
        basementc9: require("./../img/gallery/Basement/Basement B/basementc9.jpg"),
      },
      Basement_C: {
        basementa1_thumb: require("./../img/gallery/Basement/Basement C/basementa1-thumb.jpg"),
        basementa1: require("./../img/gallery/Basement/Basement C/basementa1.jpg"),
        basementa2_thumb: require("./../img/gallery/Basement/Basement C/basementa2-thumb.jpg"),
        basementa2: require("./../img/gallery/Basement/Basement C/basementa2.jpg"),
        basementa3_thumb: require("./../img/gallery/Basement/Basement C/basementa3-thumb.jpg"),
        basementa3: require("./../img/gallery/Basement/Basement C/basementa3.jpg"),
        basementa4_thumb: require("./../img/gallery/Basement/Basement C/basementa4-thumb.jpg"),
        basementa4: require("./../img/gallery/Basement/Basement C/basementa4.jpg"),
        basementa5_thumb: require("./../img/gallery/Basement/Basement C/basementa5-thumb.jpg"),
        basementa5: require("./../img/gallery/Basement/Basement C/basementa5.jpg"),
        basementa6_thumb: require("./../img/gallery/Basement/Basement C/basementa6-thumb.jpg"),
        basementa6: require("./../img/gallery/Basement/Basement C/basementa6.jpg"),
        basementa7_thumb: require("./../img/gallery/Basement/Basement C/basementa7-thumb.jpg"),
        basementa7: require("./../img/gallery/Basement/Basement C/basementa7.jpg"),
        basementa8_thumb: require("./../img/gallery/Basement/Basement C/basementa8-thumb.jpg"),
        basementa8: require("./../img/gallery/Basement/Basement C/basementa8.jpg"),
      },
      Basement_D: {
        basementb1_thumb: require("./../img/gallery/Basement/Basement D/basementb1-thumb.jpg"),
        basementb1: require("./../img/gallery/Basement/Basement D/basementb1.jpg"),
        basementb2_thumb: require("./../img/gallery/Basement/Basement D/basementb2-thumb.jpg"),
        basementb2: require("./../img/gallery/Basement/Basement D/basementb2.jpg"),
        basementb3_thumb: require("./../img/gallery/Basement/Basement D/basementb3-thumb.jpg"),
        basementb3: require("./../img/gallery/Basement/Basement D/basementb3.jpg"),
        basementb4_thumb: require("./../img/gallery/Basement/Basement D/basementb4-thumb.jpg"),
        basementb4: require("./../img/gallery/Basement/Basement D/basementb4.jpg"),
        basementb5_thumb: require("./../img/gallery/Basement/Basement D/basementb5-thumb.jpg"),
        basementb5: require("./../img/gallery/Basement/Basement D/basementb5.jpg"),
        basementb6_thumb: require("./../img/gallery/Basement/Basement D/basementb6-thumb.jpg"),
        basementb6: require("./../img/gallery/Basement/Basement D/basementb6.jpg"),
        basementb7_thumb: require("./../img/gallery/Basement/Basement D/basementb7-thumb.jpg"),
        basementb7: require("./../img/gallery/Basement/Basement D/basementb7.jpg"),
        basementb8_thumb: require("./../img/gallery/Basement/Basement D/basementb8-thumb.jpg"),
        basementb8: require("./../img/gallery/Basement/Basement D/basementb8.jpg"),
        basementb9_thumb: require("./../img/gallery/Basement/Basement D/basementb9-thumb.jpg"),
        basementb9: require("./../img/gallery/Basement/Basement D/basementb9.jpg"),
      },
      Basement_E: {
        basementd1_thumb: require("./../img/gallery/Basement/Basement E/basementd1-thumb.jpg"),
        basementd1: require("./../img/gallery/Basement/Basement E/basementd1.jpg"),
        basementd10_thumb: require("./../img/gallery/Basement/Basement E/basementd10-thumb.jpg"),
        basementd10: require("./../img/gallery/Basement/Basement E/basementd10.jpg"),
        basementd11_thumb: require("./../img/gallery/Basement/Basement E/basementd11-thumb.jpg"),
        basementd11: require("./../img/gallery/Basement/Basement E/basementd11.jpg"),
        basementd12_thumb: require("./../img/gallery/Basement/Basement E/basementd12-thumb.jpg"),
        basementd12: require("./../img/gallery/Basement/Basement E/basementd12.jpg"),
        basementd13_thumb: require("./../img/gallery/Basement/Basement E/basementd13-thumb.jpg"),
        basementd13: require("./../img/gallery/Basement/Basement E/basementd13.jpg"),
        basementd14_thumb: require("./../img/gallery/Basement/Basement E/basementd14-thumb.jpg"),
        basementd14: require("./../img/gallery/Basement/Basement E/basementd14.jpg"),
        basementd15_thumb: require("./../img/gallery/Basement/Basement E/basementd15-thumb.jpg"),
        basementd15: require("./../img/gallery/Basement/Basement E/basementd15.jpg"),
        basementd16_thumb: require("./../img/gallery/Basement/Basement E/basementd16-thumb.jpg"),
        basementd16: require("./../img/gallery/Basement/Basement E/basementd16.jpg"),
        basementd17_thumb: require("./../img/gallery/Basement/Basement E/basementd17-thumb.jpg"),
        basementd17: require("./../img/gallery/Basement/Basement E/basementd17.jpg"),
        basementd18_thumb: require("./../img/gallery/Basement/Basement E/basementd18-thumb.jpg"),
        basementd18: require("./../img/gallery/Basement/Basement E/basementd18.jpg"),
        basementd2_thumb: require("./../img/gallery/Basement/Basement E/basementd2-thumb.jpg"),
        basementd2: require("./../img/gallery/Basement/Basement E/basementd2.jpg"),
        basementd3_thumb: require("./../img/gallery/Basement/Basement E/basementd3-thumb.jpg"),
        basementd3: require("./../img/gallery/Basement/Basement E/basementd3.jpg"),
        basementd4_thumb: require("./../img/gallery/Basement/Basement E/basementd4-thumb.jpg"),
        basementd4: require("./../img/gallery/Basement/Basement E/basementd4.jpg"),
        basementd5_thumb: require("./../img/gallery/Basement/Basement E/basementd5-thumb.jpg"),
        basementd5: require("./../img/gallery/Basement/Basement E/basementd5.jpg"),
        basementd6_thumb: require("./../img/gallery/Basement/Basement E/basementd6-thumb.jpg"),
        basementd6: require("./../img/gallery/Basement/Basement E/basementd6.jpg"),
        basementd7_thumb: require("./../img/gallery/Basement/Basement E/basementd7-thumb.jpg"),
        basementd7: require("./../img/gallery/Basement/Basement E/basementd7.jpg"),
        basementd8_thumb: require("./../img/gallery/Basement/Basement E/basementd8-thumb.jpg"),
        basementd8: require("./../img/gallery/Basement/Basement E/basementd8.jpg"),
        basementd9_thumb: require("./../img/gallery/Basement/Basement E/basementd9-thumb.jpg"),
        basementd9: require("./../img/gallery/Basement/Basement E/basementd9.jpg"),
      },
    }, //end basement
    Bathroom: {
      bathroom02052018_2: {
        one9264355_1754658771235487_4550009420302430430_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/19264355_1754658771235487_4550009420302430430_o-thumb.jpg"),
        one9264355_1754658771235487_4550009420302430430_o: require("./../img/gallery/Bathroom/bathroom02052018/19264355_1754658771235487_4550009420302430430_o.jpg"),
        two1457770_1754656507902380_7933157837628445841_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/21457770_1754656507902380_7933157837628445841_o-thumb.jpg"),
        two1457770_1754656507902380_7933157837628445841_o: require("./../img/gallery/Bathroom/bathroom02052018/21457770_1754656507902380_7933157837628445841_o.jpg"),
        two7164812_1754663101235054_495977173956721240_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27164812_1754663101235054_495977173956721240_o-thumb.jpg"),
        two7164812_1754663101235054_495977173956721240_o: require("./../img/gallery/Bathroom/bathroom02052018/27164812_1754663101235054_495977173956721240_o.jpg"),
        two7164940_1754656607902370_6767809898384628241_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27164940_1754656607902370_6767809898384628241_o-thumb.jpg"),
        two7164940_1754656607902370_6767809898384628241_o: require("./../img/gallery/Bathroom/bathroom02052018/27164940_1754656607902370_6767809898384628241_o.jpg"),
        two7173176_1754656114569086_4692281573878838672_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173176_1754656114569086_4692281573878838672_o-thumb.jpg"),
        two7173176_1754656114569086_4692281573878838672_o: require("./../img/gallery/Bathroom/bathroom02052018/27173176_1754656114569086_4692281573878838672_o.jpg"),
        two7173266_1754656737902357_4319919078534305931_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173266_1754656737902357_4319919078534305931_o-thumb.jpg"),
        two7173266_1754656737902357_4319919078534305931_o: require("./../img/gallery/Bathroom/bathroom02052018/27173266_1754656737902357_4319919078534305931_o.jpg"),
        two7173270_1754661551235209_2248266992883331817_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173270_1754661551235209_2248266992883331817_o-thumb.jpg"),
        two7173270_1754661551235209_2248266992883331817_o: require("./../img/gallery/Bathroom/bathroom02052018/27173270_1754661551235209_2248266992883331817_o.jpg"),
        two7173273_1754657861235578_3210568863745427135_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173273_1754657861235578_3210568863745427135_o-thumb.jpg"),
        two7173273_1754657861235578_3210568863745427135_o: require("./../img/gallery/Bathroom/bathroom02052018/27173273_1754657861235578_3210568863745427135_o.jpg"),
        two7173419_1754663467901684_8159449207333977109_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173419_1754663467901684_8159449207333977109_o-thumb.jpg"),
        two7173419_1754663467901684_8159449207333977109_o: require("./../img/gallery/Bathroom/bathroom02052018/27173419_1754663467901684_8159449207333977109_o.jpg"),
        two7173540_1754661137901917_7669921723155978052_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173540_1754661137901917_7669921723155978052_o-thumb.jpg"),
        two7173540_1754661137901917_7669921723155978052_o: require("./../img/gallery/Bathroom/bathroom02052018/27173540_1754661137901917_7669921723155978052_o.jpg"),
        two7173586_1754665894568108_4232462850707029663_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173586_1754665894568108_4232462850707029663_o-thumb.jpg"),
        two7173586_1754665894568108_4232462850707029663_o: require("./../img/gallery/Bathroom/bathroom02052018/27173586_1754665894568108_4232462850707029663_o.jpg"),
        two7173706_1754663997901631_6844508328086789167_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173706_1754663997901631_6844508328086789167_o-thumb.jpg"),
        two7173706_1754663997901631_6844508328086789167_o: require("./../img/gallery/Bathroom/bathroom02052018/27173706_1754663997901631_6844508328086789167_o.jpg"),
        two7355536_1754657381235626_17678530765965128_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355536_1754657381235626_17678530765965128_o-thumb.jpg"),
        two7355536_1754657381235626_17678530765965128_o: require("./../img/gallery/Bathroom/bathroom02052018/27355536_1754657381235626_17678530765965128_o.jpg"),
        two7355538_1754658354568862_446191542480735416_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355538_1754658354568862_446191542480735416_o-thumb.jpg"),
        two7355538_1754658354568862_446191542480735416_o: require("./../img/gallery/Bathroom/bathroom02052018/27355538_1754658354568862_446191542480735416_o.jpg"),
        two7355607_1754658064568891_6936248619637201205_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754658064568891_6936248619637201205_o-thumb.jpg"),
        two7355607_1754658064568891_6936248619637201205_o: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754658064568891_6936248619637201205_o.jpg"),
        two7355607_1754663681234996_6443937458775262068_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754663681234996_6443937458775262068_o-thumb.jpg"),
        two7355607_1754663681234996_6443937458775262068_o: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754663681234996_6443937458775262068_o.jpg"),
        two7356317_1754664201234944_3906847881084252244_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356317_1754664201234944_3906847881084252244_o-thumb.jpg"),
        two7356317_1754664201234944_3906847881084252244_o: require("./../img/gallery/Bathroom/bathroom02052018/27356317_1754664201234944_3906847881084252244_o.jpg"),
        two7356355_1754659324568765_2240424415391257849_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356355_1754659324568765_2240424415391257849_o-thumb.jpg"),
        two7356355_1754659324568765_2240424415391257849_o: require("./../img/gallery/Bathroom/bathroom02052018/27356355_1754659324568765_2240424415391257849_o.jpg"),
        two7356420_1754659017902129_7247951066095167570_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356420_1754659017902129_7247951066095167570_o-thumb.jpg"),
        two7356420_1754659017902129_7247951066095167570_o: require("./../img/gallery/Bathroom/bathroom02052018/27356420_1754659017902129_7247951066095167570_o.jpg"),
        two7368436_1754665261234838_7353116156775303123_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368436_1754665261234838_7353116156775303123_o-thumb.jpg"),
        two7368436_1754665261234838_7353116156775303123_o: require("./../img/gallery/Bathroom/bathroom02052018/27368436_1754665261234838_7353116156775303123_o.jpg"),
        two7368721_1754660217902009_1590331930725399329_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368721_1754660217902009_1590331930725399329_o-thumb.jpg"),
        two7368721_1754660217902009_1590331930725399329_o: require("./../img/gallery/Bathroom/bathroom02052018/27368721_1754660217902009_1590331930725399329_o.jpg"),
        two7368756_1754657237902307_3613872706721921400_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368756_1754657237902307_3613872706721921400_o-thumb.jpg"),
        two7368756_1754657237902307_3613872706721921400_o: require("./../img/gallery/Bathroom/bathroom02052018/27368756_1754657237902307_3613872706721921400_o.jpg"),
        two7368761_1754665791234785_3325316719660652772_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368761_1754665791234785_3325316719660652772_o-thumb.jpg"),
        two7368761_1754665791234785_3325316719660652772_o: require("./../img/gallery/Bathroom/bathroom02052018/27368761_1754665791234785_3325316719660652772_o.jpg"),
        two7368858_1754660367901994_5957740495480162577_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368858_1754660367901994_5957740495480162577_o-thumb.jpg"),
        two7368858_1754660367901994_5957740495480162577_o: require("./../img/gallery/Bathroom/bathroom02052018/27368858_1754660367901994_5957740495480162577_o.jpg"),
        two7500422_1754662677901763_698827374946890248_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27500422_1754662677901763_698827374946890248_o-thumb.jpg"),
        two7500422_1754662677901763_698827374946890248_o: require("./../img/gallery/Bathroom/bathroom02052018/27500422_1754662677901763_698827374946890248_o.jpg"),
        two7500441_1754662011235163_521374104750133113_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27500441_1754662011235163_521374104750133113_o-thumb.jpg"),
        two7500441_1754662011235163_521374104750133113_o: require("./../img/gallery/Bathroom/bathroom02052018/27500441_1754662011235163_521374104750133113_o.jpg"),
        two7501028_1754656267902404_3022032184525868431_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27501028_1754656267902404_3022032184525868431_o-thumb.jpg"),
        two7501028_1754656267902404_3022032184525868431_o: require("./../img/gallery/Bathroom/bathroom02052018/27501028_1754656267902404_3022032184525868431_o.jpg"),
        two7501069_1754660611235303_3966466747641891871_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27501069_1754660611235303_3966466747641891871_o-thumb.jpg"),
        two7501069_1754660611235303_3966466747641891871_o: require("./../img/gallery/Bathroom/bathroom02052018/27501069_1754660611235303_3966466747641891871_o.jpg"),
        two7503808_1754665094568188_5573599284312084530_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27503808_1754665094568188_5573599284312084530_o-thumb.jpg"),
        two7503808_1754665094568188_5573599284312084530_o: require("./../img/gallery/Bathroom/bathroom02052018/27503808_1754665094568188_5573599284312084530_o.jpg"),
        two7504058_1754662517901779_5335742940718835767_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27504058_1754662517901779_5335742940718835767_o-thumb.jpg"),
        two7504058_1754662517901779_5335742940718835767_o: require("./../img/gallery/Bathroom/bathroom02052018/27504058_1754662517901779_5335742940718835767_o.jpg"),
        two7624886_1754662347901796_6019368570289152143_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27624886_1754662347901796_6019368570289152143_o-thumb.jpg"),
        two7624886_1754662347901796_6019368570289152143_o: require("./../img/gallery/Bathroom/bathroom02052018/27624886_1754662347901796_6019368570289152143_o.jpg"),
        two7625167_1754655721235792_308331465274540034_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625167_1754655721235792_308331465274540034_o-thumb.jpg"),
        two7625167_1754655721235792_308331465274540034_o: require("./../img/gallery/Bathroom/bathroom02052018/27625167_1754655721235792_308331465274540034_o.jpg"),
        two7625519_1754660777901953_6163126241123043869_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625519_1754660777901953_6163126241123043869_o-thumb.jpg"),
        two7625519_1754660777901953_6163126241123043869_o: require("./../img/gallery/Bathroom/bathroom02052018/27625519_1754660777901953_6163126241123043869_o.jpg"),
        two7625535_1754665474568150_551136948523261531_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625535_1754665474568150_551136948523261531_o-thumb.jpg"),
        two7625535_1754665474568150_551136948523261531_o: require("./../img/gallery/Bathroom/bathroom02052018/27625535_1754665474568150_551136948523261531_o.jpg"),
        two7629042_1754657701235594_39980766908952804_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27629042_1754657701235594_39980766908952804_o-thumb.jpg"),
        two7629042_1754657701235594_39980766908952804_o: require("./../img/gallery/Bathroom/bathroom02052018/27629042_1754657701235594_39980766908952804_o.jpg"),
        two6231917_1753391401362224_6076065338787609832_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/26231917_1753391401362224_6076065338787609832_o-thumb.jpg"),
        two6231917_1753391401362224_6076065338787609832_o: require("./../img/gallery/Bathroom/bathroom02052018-2/26231917_1753391401362224_6076065338787609832_o.jpg"),
        two7164329_1753392474695450_3343633431891565210_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27164329_1753392474695450_3343633431891565210_o-thumb.jpg"),
        two7164329_1753392474695450_3343633431891565210_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27164329_1753392474695450_3343633431891565210_o.jpg"),
        two7164360_1753393238028707_4581768317765714974_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27164360_1753393238028707_4581768317765714974_o-thumb.jpg"),
        two7164360_1753393238028707_4581768317765714974_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27164360_1753393238028707_4581768317765714974_o.jpg"),
        two7164663_1753393471362017_774049014094611551_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27164663_1753393471362017_774049014094611551_o-thumb.jpg"),
        two7164663_1753393471362017_774049014094611551_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27164663_1753393471362017_774049014094611551_o.jpg"),
        two7164789_1753379578030073_5543615101300809640_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27164789_1753379578030073_5543615101300809640_o-thumb.jpg"),
        two7164789_1753379578030073_5543615101300809640_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27164789_1753379578030073_5543615101300809640_o.jpg"),
        two7173171_1753386038029427_1474392571427620084_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173171_1753386038029427_1474392571427620084_o-thumb.jpg"),
        two7173171_1753386038029427_1474392571427620084_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173171_1753386038029427_1474392571427620084_o.jpg"),
        two7173436_1753380454696652_2268294358301683210_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173436_1753380454696652_2268294358301683210_o-thumb.jpg"),
        two7173436_1753380454696652_2268294358301683210_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173436_1753380454696652_2268294358301683210_o.jpg"),
        two7173461_1753385874696110_4514737323847673671_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173461_1753385874696110_4514737323847673671_o-thumb.jpg"),
        two7173461_1753385874696110_4514737323847673671_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173461_1753385874696110_4514737323847673671_o.jpg"),
        two7173499_1753392798028751_6645976795975432550_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173499_1753392798028751_6645976795975432550_o-thumb.jpg"),
        two7173499_1753392798028751_6645976795975432550_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173499_1753392798028751_6645976795975432550_o.jpg"),
        two7173507_1753393781361986_4626873612671058023_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173507_1753393781361986_4626873612671058023_o-thumb.jpg"),
        two7173507_1753393781361986_4626873612671058023_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173507_1753393781361986_4626873612671058023_o.jpg"),
        two7173554_1753380988029932_3599533489285138365_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173554_1753380988029932_3599533489285138365_o-thumb.jpg"),
        two7173554_1753380988029932_3599533489285138365_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173554_1753380988029932_3599533489285138365_o.jpg"),
        two7173574_1753386581362706_2357929751423403372_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27173574_1753386581362706_2357929751423403372_o-thumb.jpg"),
        two7173574_1753386581362706_2357929751423403372_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27173574_1753386581362706_2357929751423403372_o.jpg"),
        two7174047_1753391871362177_4314125268137752014_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27174047_1753391871362177_4314125268137752014_o-thumb.jpg"),
        two7174047_1753391871362177_4314125268137752014_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27174047_1753391871362177_4314125268137752014_o.jpg"),
        two7356251_1753392628028768_8686660137995076668_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27356251_1753392628028768_8686660137995076668_o-thumb.jpg"),
        two7356251_1753392628028768_8686660137995076668_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27356251_1753392628028768_8686660137995076668_o.jpg"),
        two7368240_1753381161363248_3344752193978213764_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27368240_1753381161363248_3344752193978213764_o-thumb.jpg"),
        two7368240_1753381161363248_3344752193978213764_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27368240_1753381161363248_3344752193978213764_o.jpg"),
        two7368359_1753382991363065_6053454845642202207_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27368359_1753382991363065_6053454845642202207_o-thumb.jpg"),
        two7368359_1753382991363065_6053454845642202207_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27368359_1753382991363065_6053454845642202207_o.jpg"),
        two7368467_1753382491363115_4228099802933527875_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27368467_1753382491363115_4228099802933527875_o-thumb.jpg"),
        two7368467_1753382491363115_4228099802933527875_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27368467_1753382491363115_4228099802933527875_o.jpg"),
        two7500302_1753392101362154_1879691126127638186_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27500302_1753392101362154_1879691126127638186_o-thumb.jpg"),
        two7500302_1753392101362154_1879691126127638186_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27500302_1753392101362154_1879691126127638186_o.jpg"),
        two7500489_1753386214696076_559182482792094209_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27500489_1753386214696076_559182482792094209_o-thumb.jpg"),
        two7500489_1753386214696076_559182482792094209_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27500489_1753386214696076_559182482792094209_o.jpg"),
        two7500683_1753393908028640_6246584967856502424_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27500683_1753393908028640_6246584967856502424_o-thumb.jpg"),
        two7500683_1753393908028640_6246584967856502424_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27500683_1753393908028640_6246584967856502424_o.jpg"),
        two7500686_1753392311362133_8052081710967044649_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27500686_1753392311362133_8052081710967044649_o-thumb.jpg"),
        two7500686_1753392311362133_8052081710967044649_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27500686_1753392311362133_8052081710967044649_o.jpg"),
        two7503323_1753381841363180_3892215032490599243_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27503323_1753381841363180_3892215032490599243_o-thumb.jpg"),
        two7503323_1753381841363180_3892215032490599243_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27503323_1753381841363180_3892215032490599243_o.jpg"),
        two7503384_1753380731363291_1605705214796951760_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27503384_1753380731363291_1605705214796951760_o-thumb.jpg"),
        two7503384_1753380731363291_1605705214796951760_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27503384_1753380731363291_1605705214796951760_o.jpg"),
        two7503475_1753379381363426_7267394460758364255_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27503475_1753379381363426_7267394460758364255_o-thumb.jpg"),
        two7503475_1753379381363426_7267394460758364255_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27503475_1753379381363426_7267394460758364255_o.jpg"),
        two7503671_1753391684695529_8145982241089853654_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27503671_1753391684695529_8145982241089853654_o-thumb.jpg"),
        two7503671_1753391684695529_8145982241089853654_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27503671_1753391684695529_8145982241089853654_o.jpg"),
        two7503845_1753391234695574_8835126338471871689_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27503845_1753391234695574_8835126338471871689_o-thumb.jpg"),
        two7503845_1753391234695574_8835126338471871689_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27503845_1753391234695574_8835126338471871689_o.jpg"),
        two7504101_1753382044696493_4008483035593525174_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27504101_1753382044696493_4008483035593525174_o-thumb.jpg"),
        two7504101_1753382044696493_4008483035593525174_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27504101_1753382044696493_4008483035593525174_o.jpg"),
        two7624813_1753392951362069_8670791027055060181_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27624813_1753392951362069_8670791027055060181_o-thumb.jpg"),
        two7624813_1753392951362069_8670791027055060181_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27624813_1753392951362069_8670791027055060181_o.jpg"),
        two7625259_1753382221363142_2322432244561353980_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27625259_1753382221363142_2322432244561353980_o-thumb.jpg"),
        two7625259_1753382221363142_2322432244561353980_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27625259_1753382221363142_2322432244561353980_o.jpg"),
        two7629022_1753382724696425_7873294011783457145_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27629022_1753382724696425_7873294011783457145_o-thumb.jpg"),
        two7629022_1753382724696425_7873294011783457145_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27629022_1753382724696425_7873294011783457145_o.jpg"),
        two7709694_1753394074695290_3335447658628649075_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018-2/27709694_1753394074695290_3335447658628649075_o-thumb.jpg"),
        two7709694_1753394074695290_3335447658628649075_o: require("./../img/gallery/Bathroom/bathroom02052018-2/27709694_1753394074695290_3335447658628649075_o.jpg"),
      },
      bathroom02052018: {
        one9264355_1754658771235487_4550009420302430430_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/19264355_1754658771235487_4550009420302430430_o-thumb.jpg"),
        one9264355_1754658771235487_4550009420302430430_o: require("./../img/gallery/Bathroom/bathroom02052018/19264355_1754658771235487_4550009420302430430_o.jpg"),
        two1457770_1754656507902380_7933157837628445841_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/21457770_1754656507902380_7933157837628445841_o-thumb.jpg"),
        two1457770_1754656507902380_7933157837628445841_o: require("./../img/gallery/Bathroom/bathroom02052018/21457770_1754656507902380_7933157837628445841_o.jpg"),
        two7164812_1754663101235054_495977173956721240_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27164812_1754663101235054_495977173956721240_o-thumb.jpg"),
        two7164812_1754663101235054_495977173956721240_o: require("./../img/gallery/Bathroom/bathroom02052018/27164812_1754663101235054_495977173956721240_o.jpg"),
        two7164940_1754656607902370_6767809898384628241_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27164940_1754656607902370_6767809898384628241_o-thumb.jpg"),
        two7164940_1754656607902370_6767809898384628241_o: require("./../img/gallery/Bathroom/bathroom02052018/27164940_1754656607902370_6767809898384628241_o.jpg"),
        two7173176_1754656114569086_4692281573878838672_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173176_1754656114569086_4692281573878838672_o-thumb.jpg"),
        two7173176_1754656114569086_4692281573878838672_o: require("./../img/gallery/Bathroom/bathroom02052018/27173176_1754656114569086_4692281573878838672_o.jpg"),
        two7173266_1754656737902357_4319919078534305931_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173266_1754656737902357_4319919078534305931_o-thumb.jpg"),
        two7173266_1754656737902357_4319919078534305931_o: require("./../img/gallery/Bathroom/bathroom02052018/27173266_1754656737902357_4319919078534305931_o.jpg"),
        two7173270_1754661551235209_2248266992883331817_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173270_1754661551235209_2248266992883331817_o-thumb.jpg"),
        two7173270_1754661551235209_2248266992883331817_o: require("./../img/gallery/Bathroom/bathroom02052018/27173270_1754661551235209_2248266992883331817_o.jpg"),
        two7173273_1754657861235578_3210568863745427135_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173273_1754657861235578_3210568863745427135_o-thumb.jpg"),
        two7173273_1754657861235578_3210568863745427135_o: require("./../img/gallery/Bathroom/bathroom02052018/27173273_1754657861235578_3210568863745427135_o.jpg"),
        two7173419_1754663467901684_8159449207333977109_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173419_1754663467901684_8159449207333977109_o-thumb.jpg"),
        two7173419_1754663467901684_8159449207333977109_o: require("./../img/gallery/Bathroom/bathroom02052018/27173419_1754663467901684_8159449207333977109_o.jpg"),
        two7173540_1754661137901917_7669921723155978052_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173540_1754661137901917_7669921723155978052_o-thumb.jpg"),
        two7173540_1754661137901917_7669921723155978052_o: require("./../img/gallery/Bathroom/bathroom02052018/27173540_1754661137901917_7669921723155978052_o.jpg"),
        two7173586_1754665894568108_4232462850707029663_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173586_1754665894568108_4232462850707029663_o-thumb.jpg"),
        two7173586_1754665894568108_4232462850707029663_o: require("./../img/gallery/Bathroom/bathroom02052018/27173586_1754665894568108_4232462850707029663_o.jpg"),
        two7173706_1754663997901631_6844508328086789167_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27173706_1754663997901631_6844508328086789167_o-thumb.jpg"),
        two7173706_1754663997901631_6844508328086789167_o: require("./../img/gallery/Bathroom/bathroom02052018/27173706_1754663997901631_6844508328086789167_o.jpg"),
        two7355536_1754657381235626_17678530765965128_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355536_1754657381235626_17678530765965128_o-thumb.jpg"),
        two7355536_1754657381235626_17678530765965128_o: require("./../img/gallery/Bathroom/bathroom02052018/27355536_1754657381235626_17678530765965128_o.jpg"),
        two7355538_1754658354568862_446191542480735416_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355538_1754658354568862_446191542480735416_o-thumb.jpg"),
        two7355538_1754658354568862_446191542480735416_o: require("./../img/gallery/Bathroom/bathroom02052018/27355538_1754658354568862_446191542480735416_o.jpg"),
        two7355607_1754658064568891_6936248619637201205_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754658064568891_6936248619637201205_o-thumb.jpg"),
        two7355607_1754658064568891_6936248619637201205_o: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754658064568891_6936248619637201205_o.jpg"),
        two7355607_1754663681234996_6443937458775262068_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754663681234996_6443937458775262068_o-thumb.jpg"),
        two7355607_1754663681234996_6443937458775262068_o: require("./../img/gallery/Bathroom/bathroom02052018/27355607_1754663681234996_6443937458775262068_o.jpg"),
        two7356317_1754664201234944_3906847881084252244_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356317_1754664201234944_3906847881084252244_o-thumb.jpg"),
        two7356317_1754664201234944_3906847881084252244_o: require("./../img/gallery/Bathroom/bathroom02052018/27356317_1754664201234944_3906847881084252244_o.jpg"),
        two7356355_1754659324568765_2240424415391257849_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356355_1754659324568765_2240424415391257849_o-thumb.jpg"),
        two7356355_1754659324568765_2240424415391257849_o: require("./../img/gallery/Bathroom/bathroom02052018/27356355_1754659324568765_2240424415391257849_o.jpg"),
        two7356420_1754659017902129_7247951066095167570_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27356420_1754659017902129_7247951066095167570_o-thumb.jpg"),
        two7356420_1754659017902129_7247951066095167570_o: require("./../img/gallery/Bathroom/bathroom02052018/27356420_1754659017902129_7247951066095167570_o.jpg"),
        two7368436_1754665261234838_7353116156775303123_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368436_1754665261234838_7353116156775303123_o-thumb.jpg"),
        two7368436_1754665261234838_7353116156775303123_o: require("./../img/gallery/Bathroom/bathroom02052018/27368436_1754665261234838_7353116156775303123_o.jpg"),
        two7368721_1754660217902009_1590331930725399329_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368721_1754660217902009_1590331930725399329_o-thumb.jpg"),
        two7368721_1754660217902009_1590331930725399329_o: require("./../img/gallery/Bathroom/bathroom02052018/27368721_1754660217902009_1590331930725399329_o.jpg"),
        two7368756_1754657237902307_3613872706721921400_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368756_1754657237902307_3613872706721921400_o-thumb.jpg"),
        two7368756_1754657237902307_3613872706721921400_o: require("./../img/gallery/Bathroom/bathroom02052018/27368756_1754657237902307_3613872706721921400_o.jpg"),
        two7368761_1754665791234785_3325316719660652772_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368761_1754665791234785_3325316719660652772_o-thumb.jpg"),
        two7368761_1754665791234785_3325316719660652772_o: require("./../img/gallery/Bathroom/bathroom02052018/27368761_1754665791234785_3325316719660652772_o.jpg"),
        two7368858_1754660367901994_5957740495480162577_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27368858_1754660367901994_5957740495480162577_o-thumb.jpg"),
        two7368858_1754660367901994_5957740495480162577_o: require("./../img/gallery/Bathroom/bathroom02052018/27368858_1754660367901994_5957740495480162577_o.jpg"),
        two7500422_1754662677901763_698827374946890248_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27500422_1754662677901763_698827374946890248_o-thumb.jpg"),
        two7500422_1754662677901763_698827374946890248_o: require("./../img/gallery/Bathroom/bathroom02052018/27500422_1754662677901763_698827374946890248_o.jpg"),
        two7500441_1754662011235163_521374104750133113_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27500441_1754662011235163_521374104750133113_o-thumb.jpg"),
        two7500441_1754662011235163_521374104750133113_o: require("./../img/gallery/Bathroom/bathroom02052018/27500441_1754662011235163_521374104750133113_o.jpg"),
        two7501028_1754656267902404_3022032184525868431_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27501028_1754656267902404_3022032184525868431_o-thumb.jpg"),
        two7501028_1754656267902404_3022032184525868431_o: require("./../img/gallery/Bathroom/bathroom02052018/27501028_1754656267902404_3022032184525868431_o.jpg"),
        two7501069_1754660611235303_3966466747641891871_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27501069_1754660611235303_3966466747641891871_o-thumb.jpg"),
        two7501069_1754660611235303_3966466747641891871_o: require("./../img/gallery/Bathroom/bathroom02052018/27501069_1754660611235303_3966466747641891871_o.jpg"),
        two7503808_1754665094568188_5573599284312084530_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27503808_1754665094568188_5573599284312084530_o-thumb.jpg"),
        two7503808_1754665094568188_5573599284312084530_o: require("./../img/gallery/Bathroom/bathroom02052018/27503808_1754665094568188_5573599284312084530_o.jpg"),
        two7504058_1754662517901779_5335742940718835767_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27504058_1754662517901779_5335742940718835767_o-thumb.jpg"),
        two7504058_1754662517901779_5335742940718835767_o: require("./../img/gallery/Bathroom/bathroom02052018/27504058_1754662517901779_5335742940718835767_o.jpg"),
        two7624886_1754662347901796_6019368570289152143_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27624886_1754662347901796_6019368570289152143_o-thumb.jpg"),
        two7624886_1754662347901796_6019368570289152143_o: require("./../img/gallery/Bathroom/bathroom02052018/27624886_1754662347901796_6019368570289152143_o.jpg"),
        two7625167_1754655721235792_308331465274540034_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625167_1754655721235792_308331465274540034_o-thumb.jpg"),
        two7625167_1754655721235792_308331465274540034_o: require("./../img/gallery/Bathroom/bathroom02052018/27625167_1754655721235792_308331465274540034_o.jpg"),
        two7625519_1754660777901953_6163126241123043869_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625519_1754660777901953_6163126241123043869_o-thumb.jpg"),
        two7625519_1754660777901953_6163126241123043869_o: require("./../img/gallery/Bathroom/bathroom02052018/27625519_1754660777901953_6163126241123043869_o.jpg"),
        two7625535_1754665474568150_551136948523261531_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27625535_1754665474568150_551136948523261531_o-thumb.jpg"),
        two7625535_1754665474568150_551136948523261531_o: require("./../img/gallery/Bathroom/bathroom02052018/27625535_1754665474568150_551136948523261531_o.jpg"),
        two7629042_1754657701235594_39980766908952804_o_thumb: require("./../img/gallery/Bathroom/bathroom02052018/27629042_1754657701235594_39980766908952804_o-thumb.jpg"),
        two7629042_1754657701235594_39980766908952804_o: require("./../img/gallery/Bathroom/bathroom02052018/27629042_1754657701235594_39980766908952804_o.jpg"),
      },
      Bathroom_A: {
        image_10_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_10_revised-thumb.jpg"),
        image_10_revised: require("./../img/gallery/Bathroom/Bathroom A/image_10_revised.jpg"),
        image_11_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_11_revised-thumb.jpg"),
        image_11_revised: require("./../img/gallery/Bathroom/Bathroom A/image_11_revised.jpg"),
        image_12_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_12_revised-thumb.jpg"),
        image_12_revised: require("./../img/gallery/Bathroom/Bathroom A/image_12_revised.jpg"),
        image_13_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_13_revised-thumb.jpg"),
        image_13_revised: require("./../img/gallery/Bathroom/Bathroom A/image_13_revised.jpg"),
        image_14_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_14_revised-thumb.jpg"),
        image_14_revised: require("./../img/gallery/Bathroom/Bathroom A/image_14_revised.jpg"),
        image_15_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_15_revised-thumb.jpg"),
        image_15_revised: require("./../img/gallery/Bathroom/Bathroom A/image_15_revised.jpg"),
        image_16_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_16_revised-thumb.jpg"),
        image_16_revised: require("./../img/gallery/Bathroom/Bathroom A/image_16_revised.jpg"),
        image_17_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_17_revised-thumb.jpg"),
        image_17_revised: require("./../img/gallery/Bathroom/Bathroom A/image_17_revised.jpg"),
        image_18_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_18_revised-thumb.jpg"),
        image_18_revised: require("./../img/gallery/Bathroom/Bathroom A/image_18_revised.jpg"),
        image_19_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_19_revised-thumb.jpg"),
        image_19_revised: require("./../img/gallery/Bathroom/Bathroom A/image_19_revised.jpg"),
        image_1_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_1_revised-thumb.jpg"),
        image_1_revised: require("./../img/gallery/Bathroom/Bathroom A/image_1_revised.jpg"),
        image_20_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_20_revised-thumb.jpg"),
        image_20_revised: require("./../img/gallery/Bathroom/Bathroom A/image_20_revised.jpg"),
        image_21_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_21_revised-thumb.jpg"),
        image_21_revised: require("./../img/gallery/Bathroom/Bathroom A/image_21_revised.jpg"),
        image_22_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_22_revised-thumb.jpg"),
        image_22_revised: require("./../img/gallery/Bathroom/Bathroom A/image_22_revised.jpg"),
        image_2_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_2_revised-thumb.jpg"),
        image_2_revised: require("./../img/gallery/Bathroom/Bathroom A/image_2_revised.jpg"),
        image_3_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_3_revised-thumb.jpg"),
        image_3_revised: require("./../img/gallery/Bathroom/Bathroom A/image_3_revised.jpg"),
        image_4_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_4_revised-thumb.jpg"),
        image_4_revised: require("./../img/gallery/Bathroom/Bathroom A/image_4_revised.jpg"),
        image_5_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_5_revised-thumb.jpg"),
        image_5_revised: require("./../img/gallery/Bathroom/Bathroom A/image_5_revised.jpg"),
        image_6_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_6_revised-thumb.jpg"),
        image_6_revised: require("./../img/gallery/Bathroom/Bathroom A/image_6_revised.jpg"),
        image_7_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_7_revised-thumb.jpg"),
        image_7_revised: require("./../img/gallery/Bathroom/Bathroom A/image_7_revised.jpg"),
        image_8_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_8_revised-thumb.jpg"),
        image_8_revised: require("./../img/gallery/Bathroom/Bathroom A/image_8_revised.jpg"),
        image_9_revised_thumb: require("./../img/gallery/Bathroom/Bathroom A/image_9_revised-thumb.jpg"),
        image_9_revised: require("./../img/gallery/Bathroom/Bathroom A/image_9_revised.jpg"),
      },
      Bathroom_B: {
        masterbatha1_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha1-thumb.jpg"),
        masterbatha1: require("./../img/gallery/Bathroom/Bathroom B/masterbatha1.jpg"),
        masterbatha2_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha2-thumb.jpg"),
        masterbatha2: require("./../img/gallery/Bathroom/Bathroom B/masterbatha2.jpg"),
        masterbatha3_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha3-thumb.jpg"),
        masterbatha3: require("./../img/gallery/Bathroom/Bathroom B/masterbatha3.jpg"),
        masterbatha4_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha4-thumb.jpg"),
        masterbatha4: require("./../img/gallery/Bathroom/Bathroom B/masterbatha4.jpg"),
        masterbatha5_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha5-thumb.jpg"),
        masterbatha5: require("./../img/gallery/Bathroom/Bathroom B/masterbatha5.jpg"),
        masterbatha6_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha6-thumb.jpg"),
        masterbatha6: require("./../img/gallery/Bathroom/Bathroom B/masterbatha6.jpg"),
        masterbatha7_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha7-thumb.jpg"),
        masterbatha7: require("./../img/gallery/Bathroom/Bathroom B/masterbatha7.jpg"),
        masterbatha8_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha8-thumb.jpg"),
        masterbatha8: require("./../img/gallery/Bathroom/Bathroom B/masterbatha8.jpg"),
        masterbatha9_thumb: require("./../img/gallery/Bathroom/Bathroom B/masterbatha9-thumb.jpg"),
        masterbatha9: require("./../img/gallery/Bathroom/Bathroom B/masterbatha9.jpg"),
      },
      Bathroom_C: {
        masterbathb1_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb1-thumb.jpg"),
        masterbathb1: require("./../img/gallery/Bathroom/Bathroom C/masterbathb1.jpg"),
        masterbathb10_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb10-thumb.jpg"),
        masterbathb10: require("./../img/gallery/Bathroom/Bathroom C/masterbathb10.jpg"),
        masterbathb11_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb11-thumb.jpg"),
        masterbathb11: require("./../img/gallery/Bathroom/Bathroom C/masterbathb11.jpg"),
        masterbathb12_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb12-thumb.jpg"),
        masterbathb12: require("./../img/gallery/Bathroom/Bathroom C/masterbathb12.jpg"),
        masterbathb13_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb13-thumb.jpg"),
        masterbathb13: require("./../img/gallery/Bathroom/Bathroom C/masterbathb13.jpg"),
        masterbathb2_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb2-thumb.jpg"),
        masterbathb2: require("./../img/gallery/Bathroom/Bathroom C/masterbathb2.jpg"),
        masterbathb3_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb3-thumb.jpg"),
        masterbathb3: require("./../img/gallery/Bathroom/Bathroom C/masterbathb3.jpg"),
        masterbathb4_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb4-thumb.jpg"),
        masterbathb4: require("./../img/gallery/Bathroom/Bathroom C/masterbathb4.jpg"),
        masterbathb4a_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb4a-thumb.jpg"),
        masterbathb4a: require("./../img/gallery/Bathroom/Bathroom C/masterbathb4a.jpg"),
        masterbathb5_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb5-thumb.jpg"),
        masterbathb5: require("./../img/gallery/Bathroom/Bathroom C/masterbathb5.jpg"),
        masterbathb6_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb6-thumb.jpg"),
        masterbathb6: require("./../img/gallery/Bathroom/Bathroom C/masterbathb6.jpg"),
        masterbathb7_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb7-thumb.jpg"),
        masterbathb7: require("./../img/gallery/Bathroom/Bathroom C/masterbathb7.jpg"),
        masterbathb8_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb8-thumb.jpg"),
        masterbathb8: require("./../img/gallery/Bathroom/Bathroom C/masterbathb8.jpg"),
        masterbathb9_thumb: require("./../img/gallery/Bathroom/Bathroom C/masterbathb9-thumb.jpg"),
        masterbathb9: require("./../img/gallery/Bathroom/Bathroom C/masterbathb9.jpg"),
      },
      Bathroom_D: {
        masterbathc1_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc1-thumb.jpg"),
        masterbathc1: require("./../img/gallery/Bathroom/Bathroom D/masterbathc1.jpg"),
        masterbathc10_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc10-thumb.jpg"),
        masterbathc10: require("./../img/gallery/Bathroom/Bathroom D/masterbathc10.jpg"),
        masterbathc2_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc2-thumb.jpg"),
        masterbathc2: require("./../img/gallery/Bathroom/Bathroom D/masterbathc2.jpg"),
        masterbathc3_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc3-thumb.jpg"),
        masterbathc3: require("./../img/gallery/Bathroom/Bathroom D/masterbathc3.jpg"),
        masterbathc4_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc4-thumb.jpg"),
        masterbathc4: require("./../img/gallery/Bathroom/Bathroom D/masterbathc4.jpg"),
        masterbathc5_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc5-thumb.jpg"),
        masterbathc5: require("./../img/gallery/Bathroom/Bathroom D/masterbathc5.jpg"),
        masterbathc6_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc6-thumb.jpg"),
        masterbathc6: require("./../img/gallery/Bathroom/Bathroom D/masterbathc6.jpg"),
        masterbathc7_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc7-thumb.jpg"),
        masterbathc7: require("./../img/gallery/Bathroom/Bathroom D/masterbathc7.jpg"),
        masterbathc8_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc8-thumb.jpg"),
        masterbathc8: require("./../img/gallery/Bathroom/Bathroom D/masterbathc8.jpg"),
        masterbathc9_thumb: require("./../img/gallery/Bathroom/Bathroom D/masterbathc9-thumb.jpg"),
        masterbathc9: require("./../img/gallery/Bathroom/Bathroom D/masterbathc9.jpg"),
      },
      Bathroom_E: {
        masterbathd1_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd1-thumb.jpg"),
        masterbathd1: require("./../img/gallery/Bathroom/Bathroom E/masterbathd1.jpg"),
        masterbathd2_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd2-thumb.jpg"),
        masterbathd2: require("./../img/gallery/Bathroom/Bathroom E/masterbathd2.jpg"),
        masterbathd3_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd3-thumb.jpg"),
        masterbathd3: require("./../img/gallery/Bathroom/Bathroom E/masterbathd3.jpg"),
        masterbathd4_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd4-thumb.jpg"),
        masterbathd4: require("./../img/gallery/Bathroom/Bathroom E/masterbathd4.jpg"),
        masterbathd5_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd5-thumb.jpg"),
        masterbathd5: require("./../img/gallery/Bathroom/Bathroom E/masterbathd5.jpg"),
        masterbathd6_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd6-thumb.jpg"),
        masterbathd6: require("./../img/gallery/Bathroom/Bathroom E/masterbathd6.jpg"),
        masterbathd7_thumb: require("./../img/gallery/Bathroom/Bathroom E/masterbathd7-thumb.jpg"),
        masterbathd7: require("./../img/gallery/Bathroom/Bathroom E/masterbathd7.jpg"),
      },
      Bathroom_F: {
        masterbathe1_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe1-thumb.jpg"),
        masterbathe1: require("./../img/gallery/Bathroom/Bathroom F/masterbathe1.jpg"),
        masterbathe10_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe10-thumb.jpg"),
        masterbathe10: require("./../img/gallery/Bathroom/Bathroom F/masterbathe10.jpg"),
        masterbathe11_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe11-thumb.jpg"),
        masterbathe11: require("./../img/gallery/Bathroom/Bathroom F/masterbathe11.jpg"),
        masterbathe12_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe12-thumb.jpg"),
        masterbathe12: require("./../img/gallery/Bathroom/Bathroom F/masterbathe12.jpg"),
        masterbathe2_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe2-thumb.jpg"),
        masterbathe2: require("./../img/gallery/Bathroom/Bathroom F/masterbathe2.jpg"),
        masterbathe3_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe3-thumb.jpg"),
        masterbathe3: require("./../img/gallery/Bathroom/Bathroom F/masterbathe3.jpg"),
        masterbathe4_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe4-thumb.jpg"),
        masterbathe4: require("./../img/gallery/Bathroom/Bathroom F/masterbathe4.jpg"),
        masterbathe5_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe5-thumb.jpg"),
        masterbathe5: require("./../img/gallery/Bathroom/Bathroom F/masterbathe5.jpg"),
        masterbathe6_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe6-thumb.jpg"),
        masterbathe6: require("./../img/gallery/Bathroom/Bathroom F/masterbathe6.jpg"),
        masterbathe7_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe7-thumb.jpg"),
        masterbathe7: require("./../img/gallery/Bathroom/Bathroom F/masterbathe7.jpg"),
        masterbathe9_thumb: require("./../img/gallery/Bathroom/Bathroom F/masterbathe9-thumb.jpg"),
        masterbathe9: require("./../img/gallery/Bathroom/Bathroom F/masterbathe9.jpg"),
      },
      Bathroom_G: {
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_017_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-017-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_017: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-017.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_019_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-019-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_019: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-019.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_020_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-020-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_020: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-020.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_021_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-021-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_021: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-021.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_024_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-024-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_024: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-024.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_026_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-026-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_026: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-026.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_029_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-029-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_029: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-029.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_031_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-031-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_031: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-031.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_033_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-033-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_033: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-033.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_034_thumb: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-034-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_034: require("./../img/gallery/Bathroom/Bathroom G/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-034.jpg"),
      },
      Bathroom_H: {
        Cruz_Hall_Bathroom___June_2013_001_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-001-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_001: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-001.jpg"),
        Cruz_Hall_Bathroom___June_2013_006_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-006-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_006: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-006.jpg"),
        Cruz_Hall_Bathroom___June_2013_008_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-008-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_008: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-008.jpg"),
        Cruz_Hall_Bathroom___June_2013_010_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-010-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_010: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-010.jpg"),
        Cruz_Hall_Bathroom___June_2013_011_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-011-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_011: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-011.jpg"),
        Cruz_Hall_Bathroom___June_2013_013_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-013-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_013: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-013.jpg"),
        Cruz_Hall_Bathroom___June_2013_018_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-018-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_018: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-018.jpg"),
        Cruz_Hall_Bathroom___June_2013_021_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-021-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_021: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-021.jpg"),
        Cruz_Hall_Bathroom___June_2013_027_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-027-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_027: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-027.jpg"),
        Cruz_Hall_Bathroom___June_2013_028_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-028-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_028: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-028.jpg"),
        Cruz_Hall_Bathroom___June_2013_030_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-030-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_030: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-030.jpg"),
        Cruz_Hall_Bathroom___June_2013_032_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-032-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_032: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-032.jpg"),
        Cruz_Hall_Bathroom___June_2013_041_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-041-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_041: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-041.jpg"),
        Cruz_Hall_Bathroom___June_2013_042_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-042-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_042: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-042.jpg"),
        Cruz_Hall_Bathroom___June_2013_045_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-045-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_045: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-045.jpg"),
        Cruz_Hall_Bathroom___June_2013_049_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-049-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_049: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-049.jpg"),
        Cruz_Hall_Bathroom___June_2013_050_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-050-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_050: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-050.jpg"),
        Cruz_Hall_Bathroom___June_2013_052_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-052-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_052: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-052.jpg"),
        Cruz_Hall_Bathroom___June_2013_053_thumb: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-053-thumb.jpg"),
        Cruz_Hall_Bathroom___June_2013_053: require("./../img/gallery/Bathroom/Bathroom H/Cruz-Hall-Bathroom---June-2013-053.jpg"),
      },
    }, // end bathroom
    Commercial: {
      Commercial_Asphalt: {
        commercialasphalt1_thumb: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt1-thumb.jpg"),
        commercialasphalt1: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt1.jpg"),
        commercialasphalt2_thumb: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt2-thumb.jpg"),
        commercialasphalt2: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt2.jpg"),
        commercialasphalt3_thumb: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt3-thumb.jpg"),
        commercialasphalt3: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt3.jpg"),
        commercialasphalt4_thumb: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt4-thumb.jpg"),
        commercialasphalt4: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt4.jpg"),
        commercialasphalt5_thumb: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt5-thumb.jpg"),
        commercialasphalt5: require("./../img/gallery/Commercial/Commercial Asphalt/commercialasphalt5.jpg"),
      },
      Siding_Gutter_Replacement: {
        commercial1_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial1-thumb.jpg"),
        commercial1: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial1.jpg"),
        commercial191_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial19(1)-thumb.jpg"),
        commercial191: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial19(1).jpg"),
        commercial19_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial19-thumb.jpg"),
        commercial19: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial19.jpg"),
        // commercial21_thumb: require('./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial2(1)-thumb.jpg'),
        // commercial21: require('./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial2(1).jpg'),
        commercial2_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial2-thumb.jpg"),
        commercial2: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial2.jpg"),
        // commercial211_thumb: require('./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial21(1)-thumb.jpg'),
        // commercial211: require('./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial21(1).jpg'),
        commercial21_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial21-thumb.jpg"),
        commercial21: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial21.jpg"),
        commercial221_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial22(1)-thumb.jpg"),
        commercial221: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial22(1).jpg"),
        commercial22_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial22-thumb.jpg"),
        commercial22: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial22.jpg"),
        commercial231_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial23(1)-thumb.jpg"),
        commercial231: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial23(1).jpg"),
        commercial23_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial23-thumb.jpg"),
        commercial23: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial23.jpg"),
        commercial241_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial24(1)-thumb.jpg"),
        commercial241: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial24(1).jpg"),
        commercial24_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial24-thumb.jpg"),
        commercial24: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial24.jpg"),
        commercial31_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial3(1)-thumb.jpg"),
        commercial31: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial3(1).jpg"),
        commercial3_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial3-thumb.jpg"),
        commercial3: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial3.jpg"),
        commercial41_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial4(1)-thumb.jpg"),
        commercial41: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial4(1).jpg"),
        commercial4_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial4-thumb.jpg"),
        commercial4: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial4.jpg"),
        commercial51_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial5(1)-thumb.jpg"),
        commercial51: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial5(1).jpg"),
        commercial5_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial5-thumb.jpg"),
        commercial5: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial5.jpg"),
        commercial61_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial6(1)-thumb.jpg"),
        commercial61: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial6(1).jpg"),
        commercial6_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial6-thumb.jpg"),
        commercial6: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial6.jpg"),
        commercial71_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial7(1)-thumb.jpg"),
        commercial71: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial7(1).jpg"),
        commercial7_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial7-thumb.jpg"),
        commercial7: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial7.jpg"),
        commercial81_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial8(1)-thumb.jpg"),
        commercial81: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial8(1).jpg"),
        commercial8_thumb: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial8-thumb.jpg"),
        commercial8: require("./../img/gallery/Commercial/Siding _ Gutter Replacement/commercial8.jpg"),
      },
      Structual_Refurbishing_Roofing: {
        commercial10_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial10-thumb.jpg"),
        commercial10: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial10.jpg"),
        commercial11_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial11-thumb.jpg"),
        commercial11: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial11.jpg"),
        commercial12_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial12-thumb.jpg"),
        commercial12: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial12.jpg"),
        commercial13_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial13-thumb.jpg"),
        commercial13: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial13.jpg"),
        commercial14_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial14-thumb.jpg"),
        commercial14: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial14.jpg"),
        commercial16_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial16-thumb.jpg"),
        commercial16: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial16.jpg"),
        commercial17_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial17-thumb.jpg"),
        commercial17: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial17.jpg"),
        commercial18_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial18-thumb.jpg"),
        commercial18: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial18.jpg"),
        commercial9_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial9-thumb.jpg"),
        commercial9: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commercial9.jpg"),
        commstruct9_thumb: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commstruct9-thumb.jpg"),
        commstruct9: require("./../img/gallery/Commercial/Structual Refurbishing _ Roofing/commstruct9.jpg"),
      },
    }, //end commercial
    Exteriors: {
      Decks: {
        deck1_thumb: require("./../img/gallery/Exteriors/Decks/deck1-thumb.jpg"),
        deck1: require("./../img/gallery/Exteriors/Decks/deck1.jpg"),
        deck10_thumb: require("./../img/gallery/Exteriors/Decks/deck10-thumb.jpg"),
        deck10: require("./../img/gallery/Exteriors/Decks/deck10.jpg"),
        deck11_thumb: require("./../img/gallery/Exteriors/Decks/deck11-thumb.jpg"),
        deck11: require("./../img/gallery/Exteriors/Decks/deck11.jpg"),
        deck12_thumb: require("./../img/gallery/Exteriors/Decks/deck12-thumb.jpg"),
        deck12: require("./../img/gallery/Exteriors/Decks/deck12.jpg"),
        deck13_thumb: require("./../img/gallery/Exteriors/Decks/deck13-thumb.jpg"),
        deck13: require("./../img/gallery/Exteriors/Decks/deck13.jpg"),
        deck14_thumb: require("./../img/gallery/Exteriors/Decks/deck14-thumb.jpg"),
        deck14: require("./../img/gallery/Exteriors/Decks/deck14.jpg"),
        deck15_thumb: require("./../img/gallery/Exteriors/Decks/deck15-thumb.jpg"),
        deck15: require("./../img/gallery/Exteriors/Decks/deck15.jpg"),
        deck16_thumb: require("./../img/gallery/Exteriors/Decks/deck16-thumb.jpg"),
        deck16: require("./../img/gallery/Exteriors/Decks/deck16.jpg"),
        deck2_thumb: require("./../img/gallery/Exteriors/Decks/deck2-thumb.jpg"),
        deck2: require("./../img/gallery/Exteriors/Decks/deck2.jpg"),
        deck3_thumb: require("./../img/gallery/Exteriors/Decks/deck3-thumb.jpg"),
        deck3: require("./../img/gallery/Exteriors/Decks/deck3.jpg"),
        deck4_thumb: require("./../img/gallery/Exteriors/Decks/deck4-thumb.jpg"),
        deck4: require("./../img/gallery/Exteriors/Decks/deck4.jpg"),
        deck5_thumb: require("./../img/gallery/Exteriors/Decks/deck5-thumb.jpg"),
        deck5: require("./../img/gallery/Exteriors/Decks/deck5.jpg"),
        deck6_thumb: require("./../img/gallery/Exteriors/Decks/deck6-thumb.jpg"),
        deck6: require("./../img/gallery/Exteriors/Decks/deck6.jpg"),
        deck7_thumb: require("./../img/gallery/Exteriors/Decks/deck7-thumb.jpg"),
        deck7: require("./../img/gallery/Exteriors/Decks/deck7.jpg"),
        deck8_thumb: require("./../img/gallery/Exteriors/Decks/deck8-thumb.jpg"),
        deck8: require("./../img/gallery/Exteriors/Decks/deck8.jpg"),
        deck9_thumb: require("./../img/gallery/Exteriors/Decks/deck9-thumb.jpg"),
        deck9: require("./../img/gallery/Exteriors/Decks/deck9.jpg"),
      },
      Exterior_Face_Renovation: {
        Exterior1_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior1-thumb.jpg"),
        Exterior1: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior1.jpg"),
        Exterior2_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior2-thumb.jpg"),
        Exterior2: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior2.jpg"),
        Exterior3_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior3-thumb.jpg"),
        Exterior3: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior3.jpg"),
        Exterior4_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior4-thumb.jpg"),
        Exterior4: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior4.jpg"),
        Exterior5_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior5-thumb.jpg"),
        Exterior5: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior5.jpg"),
        Exterior6_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior6-thumb.jpg"),
        Exterior6: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior6.jpg"),
        Exterior7_thumb: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior7-thumb.jpg"),
        Exterior7: require("./../img/gallery/Exteriors/Exterior Face Renovation/Exterior7.jpg"),
      },
      Roofing: {
        roofing1_thumb: require("./../img/gallery/Exteriors/Roofing/roofing1-thumb.jpg"),
        roofing1: require("./../img/gallery/Exteriors/Roofing/roofing1.jpg"),
        roofing2_thumb: require("./../img/gallery/Exteriors/Roofing/roofing2-thumb.jpg"),
        roofing2: require("./../img/gallery/Exteriors/Roofing/roofing2.jpg"),
        roofing3_thumb: require("./../img/gallery/Exteriors/Roofing/roofing3-thumb.jpg"),
        roofing3: require("./../img/gallery/Exteriors/Roofing/roofing3.jpg"),
        roofing4_thumb: require("./../img/gallery/Exteriors/Roofing/roofing4-thumb.jpg"),
        roofing4: require("./../img/gallery/Exteriors/Roofing/roofing4.jpg"),
        roofing5_thumb: require("./../img/gallery/Exteriors/Roofing/roofing5-thumb.jpg"),
        roofing5: require("./../img/gallery/Exteriors/Roofing/roofing5.jpg"),
        roofing6_thumb: require("./../img/gallery/Exteriors/Roofing/roofing6-thumb.jpg"),
        roofing6: require("./../img/gallery/Exteriors/Roofing/roofing6.jpg"),
      },
      Siding_Window_Replacement: {
        ressid1_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid1-thumb.jpg"),
        ressid1: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid1.jpg"),
        ressid10_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid10-thumb.jpg"),
        ressid10: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid10.jpg"),
        ressid11_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid11-thumb.jpg"),
        ressid11: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid11.jpg"),
        ressid2_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid2-thumb.jpg"),
        ressid2: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid2.jpg"),
        ressid3_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid3-thumb.jpg"),
        ressid3: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid3.jpg"),
        ressid5_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid5-thumb.jpg"),
        ressid5: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid5.jpg"),
        ressid6_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid6-thumb.jpg"),
        ressid6: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid6.jpg"),
        ressid7_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid7-thumb.jpg"),
        ressid7: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid7.jpg"),
        ressid8_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid8-thumb.jpg"),
        ressid8: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid8.jpg"),
        ressid9_thumb: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid9-thumb.jpg"),
        ressid9: require("./../img/gallery/Exteriors/Siding _ Window Replacement/ressid9.jpg"),
      },
      Stucco: {
        stuccoa1_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa1-thumb.jpg"),
        stuccoa1: require("./../img/gallery/Exteriors/Stucco/stuccoa1.jpg"),
        stuccoa10_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa10-thumb.jpg"),
        stuccoa10: require("./../img/gallery/Exteriors/Stucco/stuccoa10.jpg"),
        stuccoa11_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa11-thumb.jpg"),
        stuccoa11: require("./../img/gallery/Exteriors/Stucco/stuccoa11.jpg"),
        stuccoa12_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa12-thumb.jpg"),
        stuccoa12: require("./../img/gallery/Exteriors/Stucco/stuccoa12.jpg"),
        stuccoa13_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa13-thumb.jpg"),
        stuccoa13: require("./../img/gallery/Exteriors/Stucco/stuccoa13.jpg"),
        stuccoa14_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa14-thumb.jpg"),
        stuccoa14: require("./../img/gallery/Exteriors/Stucco/stuccoa14.jpg"),
        stuccoa15_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa15-thumb.jpg"),
        stuccoa15: require("./../img/gallery/Exteriors/Stucco/stuccoa15.jpg"),
        stuccoa16_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa16-thumb.jpg"),
        stuccoa16: require("./../img/gallery/Exteriors/Stucco/stuccoa16.jpg"),
        stuccoa17_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa17-thumb.jpg"),
        stuccoa17: require("./../img/gallery/Exteriors/Stucco/stuccoa17.jpg"),
        stuccoa18_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa18-thumb.jpg"),
        stuccoa18: require("./../img/gallery/Exteriors/Stucco/stuccoa18.jpg"),
        stuccoa19_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa19-thumb.jpg"),
        stuccoa19: require("./../img/gallery/Exteriors/Stucco/stuccoa19.jpg"),
        stuccoa2_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa2-thumb.jpg"),
        stuccoa2: require("./../img/gallery/Exteriors/Stucco/stuccoa2.jpg"),
        stuccoa20_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa20-thumb.jpg"),
        stuccoa20: require("./../img/gallery/Exteriors/Stucco/stuccoa20.jpg"),
        stuccoa21_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa21-thumb.jpg"),
        stuccoa21: require("./../img/gallery/Exteriors/Stucco/stuccoa21.jpg"),
        stuccoa22_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa22-thumb.jpg"),
        stuccoa22: require("./../img/gallery/Exteriors/Stucco/stuccoa22.jpg"),
        stuccoa23_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa23-thumb.jpg"),
        stuccoa23: require("./../img/gallery/Exteriors/Stucco/stuccoa23.jpg"),
        stuccoa3_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa3-thumb.jpg"),
        stuccoa3: require("./../img/gallery/Exteriors/Stucco/stuccoa3.jpg"),
        stuccoa4_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa4-thumb.jpg"),
        stuccoa4: require("./../img/gallery/Exteriors/Stucco/stuccoa4.jpg"),
        stuccoa5_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa5-thumb.jpg"),
        stuccoa5: require("./../img/gallery/Exteriors/Stucco/stuccoa5.jpg"),
        stuccoa6_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa6-thumb.jpg"),
        stuccoa6: require("./../img/gallery/Exteriors/Stucco/stuccoa6.jpg"),
        stuccoa7_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa7-thumb.jpg"),
        stuccoa7: require("./../img/gallery/Exteriors/Stucco/stuccoa7.jpg"),
        stuccoa8_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa8-thumb.jpg"),
        stuccoa8: require("./../img/gallery/Exteriors/Stucco/stuccoa8.jpg"),
        stuccoa9_thumb: require("./../img/gallery/Exteriors/Stucco/stuccoa9-thumb.jpg"),
        stuccoa9: require("./../img/gallery/Exteriors/Stucco/stuccoa9.jpg"),
      },
    }, //end exteriors
    Hardwood_Flooring: {
      Floor_A: {
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_008_thumb: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-008-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_008: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-008.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_013_thumb: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-013-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_013: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-013.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_016_thumb: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-016-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_016: require("./../img/gallery/Hardwood Flooring/Floor A/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-016.jpg"),
      },
      Floor_B: {
        hardwoodb1_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb1-thumb.jpg"),
        hardwoodb1: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb1.jpg"),
        hardwoodb10_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb10-thumb.jpg"),
        hardwoodb10: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb10.jpg"),
        hardwoodb11_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb11-thumb.jpg"),
        hardwoodb11: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb11.jpg"),
        hardwoodb12_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb12-thumb.jpg"),
        hardwoodb12: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb12.jpg"),
        hardwoodb13_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb13-thumb.jpg"),
        hardwoodb13: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb13.jpg"),
        hardwoodb14_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb14-thumb.jpg"),
        hardwoodb14: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb14.jpg"),
        hardwoodb15_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb15-thumb.jpg"),
        hardwoodb15: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb15.jpg"),
        hardwoodb16_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb16-thumb.jpg"),
        hardwoodb16: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb16.jpg"),
        hardwoodb17_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb17-thumb.jpg"),
        hardwoodb17: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb17.jpg"),
        hardwoodb18_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb18-thumb.jpg"),
        hardwoodb18: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb18.jpg"),
        hardwoodb19_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb19-thumb.jpg"),
        hardwoodb19: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb19.jpg"),
        hardwoodb2_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb2-thumb.jpg"),
        hardwoodb2: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb2.jpg"),
        hardwoodb20_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb20-thumb.jpg"),
        hardwoodb20: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb20.jpg"),
        hardwoodb21_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb21-thumb.jpg"),
        hardwoodb21: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb21.jpg"),
        hardwoodb22_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb22-thumb.jpg"),
        hardwoodb22: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb22.jpg"),
        hardwoodb23_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb23-thumb.jpg"),
        hardwoodb23: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb23.jpg"),
        hardwoodb24_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb24-thumb.jpg"),
        hardwoodb24: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb24.jpg"),
        hardwoodb25_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb25-thumb.jpg"),
        hardwoodb25: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb25.jpg"),
        hardwoodb26_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb26-thumb.jpg"),
        hardwoodb26: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb26.jpg"),
        hardwoodb27_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb27-thumb.jpg"),
        hardwoodb27: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb27.jpg"),
        hardwoodb28_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb28-thumb.jpg"),
        hardwoodb28: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb28.jpg"),
        hardwoodb29_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb29-thumb.jpg"),
        hardwoodb29: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb29.jpg"),
        hardwoodb3_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb3-thumb.jpg"),
        hardwoodb3: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb3.jpg"),
        hardwoodb30_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb30-thumb.jpg"),
        hardwoodb30: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb30.jpg"),
        hardwoodb31_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb31-thumb.jpg"),
        hardwoodb31: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb31.jpg"),
        hardwoodb32_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb32-thumb.jpg"),
        hardwoodb32: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb32.jpg"),
        hardwoodb4_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb4-thumb.jpg"),
        hardwoodb4: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb4.jpg"),
        hardwoodb5_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb5-thumb.jpg"),
        hardwoodb5: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb5.jpg"),
        hardwoodb6_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb6-thumb.jpg"),
        hardwoodb6: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb6.jpg"),
        hardwoodb7_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb7-thumb.jpg"),
        hardwoodb7: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb7.jpg"),
        hardwoodb8_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb8-thumb.jpg"),
        hardwoodb8: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb8.jpg"),
        hardwoodb9_thumb: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb9-thumb.jpg"),
        hardwoodb9: require("./../img/gallery/Hardwood Flooring/Floor B/hardwoodb9.jpg"),
      },
      hardwood12118: {
        one_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/1-thumb.jpg"),
        one: require("./../img/gallery/Hardwood Flooring/hardwood12118/1.jpg"),
        one0_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/10-thumb.jpg"),
        one0: require("./../img/gallery/Hardwood Flooring/hardwood12118/10.jpg"),
        one1_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/11-thumb.jpg"),
        one1: require("./../img/gallery/Hardwood Flooring/hardwood12118/11.jpg"),
        one2_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/12-thumb.jpg"),
        one2: require("./../img/gallery/Hardwood Flooring/hardwood12118/12.jpg"),
        one3_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/13-thumb.jpg"),
        one3: require("./../img/gallery/Hardwood Flooring/hardwood12118/13.jpg"),
        one4_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/14-thumb.jpg"),
        one4: require("./../img/gallery/Hardwood Flooring/hardwood12118/14.jpg"),
        one5_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/15-thumb.jpg"),
        one5: require("./../img/gallery/Hardwood Flooring/hardwood12118/15.jpg"),
        one6_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/16-thumb.jpg"),
        one6: require("./../img/gallery/Hardwood Flooring/hardwood12118/16.jpg"),
        one7_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/17-thumb.jpg"),
        one7: require("./../img/gallery/Hardwood Flooring/hardwood12118/17.jpg"),
        one8_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/18-thumb.jpg"),
        one8: require("./../img/gallery/Hardwood Flooring/hardwood12118/18.jpg"),
        one9_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/19-thumb.jpg"),
        one9: require("./../img/gallery/Hardwood Flooring/hardwood12118/19.jpg"),
        two_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/2-thumb.jpg"),
        two: require("./../img/gallery/Hardwood Flooring/hardwood12118/2.jpg"),
        two0_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/20-thumb.jpg"),
        two0: require("./../img/gallery/Hardwood Flooring/hardwood12118/20.jpg"),
        three_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/3-thumb.jpg"),
        three: require("./../img/gallery/Hardwood Flooring/hardwood12118/3.jpg"),
        four_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/4-thumb.jpg"),
        four: require("./../img/gallery/Hardwood Flooring/hardwood12118/4.jpg"),
        five_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/5-thumb.jpg"),
        five: require("./../img/gallery/Hardwood Flooring/hardwood12118/5.jpg"),
        six_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/6-thumb.jpg"),
        six: require("./../img/gallery/Hardwood Flooring/hardwood12118/6.jpg"),
        seven_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/7-thumb.jpg"),
        seven: require("./../img/gallery/Hardwood Flooring/hardwood12118/7.jpg"),
        eight_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/8-thumb.jpg"),
        eight: require("./../img/gallery/Hardwood Flooring/hardwood12118/8.jpg"),
        nine_thumb: require("./../img/gallery/Hardwood Flooring/hardwood12118/9-thumb.jpg"),
        nine: require("./../img/gallery/Hardwood Flooring/hardwood12118/9.jpg"),
      },
      Miscellaneous_Flooring: {
        hardwood1_thumb: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood1-thumb.jpg"),
        hardwood1: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood1.jpg"),
        hardwood2_thumb: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood2-thumb.jpg"),
        hardwood2: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood2.jpg"),
        hardwood3_thumb: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood3-thumb.jpg"),
        hardwood3: require("./../img/gallery/Hardwood Flooring/Miscellaneous Flooring/hardwood3.jpg"),
      },
    }, //end Hardwood_Flooring
    Kitchen: {
      Kitchen11418: {
        one00_thumb: require("./../img/gallery/Kitchen/Kitchen11418/100-thumb.jpg"),
        one00: require("./../img/gallery/Kitchen/Kitchen11418/100.jpg"),
        one01_thumb: require("./../img/gallery/Kitchen/Kitchen11418/101-thumb.jpg"),
        one01: require("./../img/gallery/Kitchen/Kitchen11418/101.jpg"),
        one02_thumb: require("./../img/gallery/Kitchen/Kitchen11418/102-thumb.jpg"),
        one02: require("./../img/gallery/Kitchen/Kitchen11418/102.jpg"),
        one03_thumb: require("./../img/gallery/Kitchen/Kitchen11418/103-thumb.jpg"),
        one03: require("./../img/gallery/Kitchen/Kitchen11418/103.jpg"),
        one04_thumb: require("./../img/gallery/Kitchen/Kitchen11418/104-thumb.jpg"),
        one04: require("./../img/gallery/Kitchen/Kitchen11418/104.jpg"),
        one05_thumb: require("./../img/gallery/Kitchen/Kitchen11418/105-thumb.jpg"),
        one05: require("./../img/gallery/Kitchen/Kitchen11418/105.jpg"),
        one06_thumb: require("./../img/gallery/Kitchen/Kitchen11418/106-thumb.jpg"),
        one06: require("./../img/gallery/Kitchen/Kitchen11418/106.jpg"),
        one07_thumb: require("./../img/gallery/Kitchen/Kitchen11418/107-thumb.jpg"),
        one07: require("./../img/gallery/Kitchen/Kitchen11418/107.jpg"),
        one08_thumb: require("./../img/gallery/Kitchen/Kitchen11418/108-thumb.jpg"),
        one08: require("./../img/gallery/Kitchen/Kitchen11418/108.jpg"),
        one09_thumb: require("./../img/gallery/Kitchen/Kitchen11418/109-thumb.jpg"),
        one09: require("./../img/gallery/Kitchen/Kitchen11418/109.jpg"),
        one10_thumb: require("./../img/gallery/Kitchen/Kitchen11418/110-thumb.jpg"),
        one10: require("./../img/gallery/Kitchen/Kitchen11418/110.jpg"),
        one11_thumb: require("./../img/gallery/Kitchen/Kitchen11418/111-thumb.jpg"),
        one11: require("./../img/gallery/Kitchen/Kitchen11418/111.jpg"),
        one12_thumb: require("./../img/gallery/Kitchen/Kitchen11418/112-thumb.jpg"),
        one12: require("./../img/gallery/Kitchen/Kitchen11418/112.jpg"),
        eight4_thumb: require("./../img/gallery/Kitchen/Kitchen11418/84-thumb.jpg"),
        eight4: require("./../img/gallery/Kitchen/Kitchen11418/84.jpg"),
        eight5_thumb: require("./../img/gallery/Kitchen/Kitchen11418/85-thumb.jpg"),
        eight5: require("./../img/gallery/Kitchen/Kitchen11418/85.jpg"),
        eight6_thumb: require("./../img/gallery/Kitchen/Kitchen11418/86-thumb.jpg"),
        eight6: require("./../img/gallery/Kitchen/Kitchen11418/86.jpg"),
        eight7_thumb: require("./../img/gallery/Kitchen/Kitchen11418/87-thumb.jpg"),
        eight7: require("./../img/gallery/Kitchen/Kitchen11418/87.jpg"),
        eight8_thumb: require("./../img/gallery/Kitchen/Kitchen11418/88-thumb.jpg"),
        eight8: require("./../img/gallery/Kitchen/Kitchen11418/88.jpg"),
        eight9_thumb: require("./../img/gallery/Kitchen/Kitchen11418/89-thumb.jpg"),
        eight9: require("./../img/gallery/Kitchen/Kitchen11418/89.jpg"),
        nine0_thumb: require("./../img/gallery/Kitchen/Kitchen11418/90-thumb.jpg"),
        nine0: require("./../img/gallery/Kitchen/Kitchen11418/90.jpg"),
        nine1_thumb: require("./../img/gallery/Kitchen/Kitchen11418/91-thumb.jpg"),
        nine1: require("./../img/gallery/Kitchen/Kitchen11418/91.jpg"),
        nine2_thumb: require("./../img/gallery/Kitchen/Kitchen11418/92-thumb.jpg"),
        nine2: require("./../img/gallery/Kitchen/Kitchen11418/92.jpg"),
        nine3_thumb: require("./../img/gallery/Kitchen/Kitchen11418/93-thumb.jpg"),
        nine3: require("./../img/gallery/Kitchen/Kitchen11418/93.jpg"),
        nine4_thumb: require("./../img/gallery/Kitchen/Kitchen11418/94-thumb.jpg"),
        nine4: require("./../img/gallery/Kitchen/Kitchen11418/94.jpg"),
        nine5_thumb: require("./../img/gallery/Kitchen/Kitchen11418/95-thumb.jpg"),
        nine5: require("./../img/gallery/Kitchen/Kitchen11418/95.jpg"),
        nine6_thumb: require("./../img/gallery/Kitchen/Kitchen11418/96-thumb.jpg"),
        nine6: require("./../img/gallery/Kitchen/Kitchen11418/96.jpg"),
        nine7_thumb: require("./../img/gallery/Kitchen/Kitchen11418/97-thumb.jpg"),
        nine7: require("./../img/gallery/Kitchen/Kitchen11418/97.jpg"),
        nine8_thumb: require("./../img/gallery/Kitchen/Kitchen11418/98-thumb.jpg"),
        nine8: require("./../img/gallery/Kitchen/Kitchen11418/98.jpg"),
        nine9_thumb: require("./../img/gallery/Kitchen/Kitchen11418/99-thumb.jpg"),
        nine9: require("./../img/gallery/Kitchen/Kitchen11418/99.jpg"),
      },
      Kitchen_A: {
        coco_10_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_10_revised-thumb.jpg"),
        coco_10_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_10_revised.jpg"),
        coco_14_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_14_revised-thumb.jpg"),
        coco_14_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_14_revised.jpg"),
        coco_15_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_15_revised-thumb.jpg"),
        coco_15_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_15_revised.jpg"),
        coco_16_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_16_revised-thumb.jpg"),
        coco_16_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_16_revised.jpg"),
        coco_19_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_19_revised-thumb.jpg"),
        coco_19_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_19_revised.jpg"),
        coco_20_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_20_revised-thumb.jpg"),
        coco_20_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_20_revised.jpg"),
        coco_23_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_23_revised-thumb.jpg"),
        coco_23_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_23_revised.jpg"),
        coco_24_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_24_revised-thumb.jpg"),
        coco_24_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_24_revised.jpg"),
        coco_26_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_26_revised-thumb.jpg"),
        coco_26_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_26_revised.jpg"),
        coco_27_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_27_revised-thumb.jpg"),
        coco_27_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_27_revised.jpg"),
        coco_28_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_28_revised-thumb.jpg"),
        coco_28_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_28_revised.jpg"),
        coco_2_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_2_revised-thumb.jpg"),
        coco_2_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_2_revised.jpg"),
        coco_30_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_30_revised-thumb.jpg"),
        coco_30_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_30_revised.jpg"),
        coco_33_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_33_revised-thumb.jpg"),
        coco_33_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_33_revised.jpg"),
        coco_34_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_34_revised-thumb.jpg"),
        coco_34_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_34_revised.jpg"),
        coco_35_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_35_revised-thumb.jpg"),
        coco_35_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_35_revised.jpg"),
        coco_38_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_38_revised-thumb.jpg"),
        coco_38_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_38_revised.jpg"),
        coco_39_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_39_revised-thumb.jpg"),
        coco_39_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_39_revised.jpg"),
        coco_3_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_3_revised-thumb.jpg"),
        coco_3_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_3_revised.jpg"),
        coco_40_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_40_revised-thumb.jpg"),
        coco_40_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_40_revised.jpg"),
        coco_43_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_43_revised-thumb.jpg"),
        coco_43_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_43_revised.jpg"),
        coco_44_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_44_revised-thumb.jpg"),
        coco_44_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_44_revised.jpg"),
        coco_45_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_45_revised-thumb.jpg"),
        coco_45_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_45_revised.jpg"),
        coco_46_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_46_revised-thumb.jpg"),
        coco_46_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_46_revised.jpg"),
        coco_47_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_47_revised-thumb.jpg"),
        coco_47_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_47_revised.jpg"),
        coco_49_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_49_revised-thumb.jpg"),
        coco_49_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_49_revised.jpg"),
        coco_4_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_4_revised-thumb.jpg"),
        coco_4_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_4_revised.jpg"),
        coco_5_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_5_revised-thumb.jpg"),
        coco_5_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_5_revised.jpg"),
        coco_6_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_6_revised-thumb.jpg"),
        coco_6_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_6_revised.jpg"),
        coco_7_revised_thumb: require("./../img/gallery/Kitchen/Kitchen A/coco_7_revised-thumb.jpg"),
        coco_7_revised: require("./../img/gallery/Kitchen/Kitchen A/coco_7_revised.jpg"),
      },
      Kitchen_B: {
        kitchenb5_thumb: require("./../img/gallery/Kitchen/Kitchen B/kitchenb5-thumb.jpg"),
        kitchenb5: require("./../img/gallery/Kitchen/Kitchen B/kitchenb5.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_001_thumb: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-001-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_001: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-001.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_002_thumb: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-002-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_002: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-002.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_003_thumb: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-003-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_003: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-003.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_004_thumb: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-004-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_004: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-004.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_011_thumb: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-011-thumb.jpg"),
        Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_011: require("./../img/gallery/Kitchen/Kitchen B/Lake-Barrington-Showcase---KITCHEN---MASTER-BATH---HARDWOOD-011.jpg"),
      },
      Kitchens_Misc: {
        kitchen1_thumb: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen1-thumb.jpg"),
        kitchen1: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen1.jpg"),
        kitchen2_thumb: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen2-thumb.jpg"),
        kitchen2: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen2.jpg"),
        kitchen3_thumb: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen3-thumb.jpg"),
        kitchen3: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen3.jpg"),
        kitchen4_thumb: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen4-thumb.jpg"),
        kitchen4: require("./../img/gallery/Kitchen/Kitchens (Misc. Others)/kitchen4.jpg"),
      },
    }, //end Kitchen
    Living_Room: {
      livingRoom11418: {
        five4_thumb: require("./../img/gallery/Living Room/livingRoom11418/54-thumb.jpg"),
        five4: require("./../img/gallery/Living Room/livingRoom11418/54.jpg"),
        five5_thumb: require("./../img/gallery/Living Room/livingRoom11418/55-thumb.jpg"),
        five5: require("./../img/gallery/Living Room/livingRoom11418/55.jpg"),
        five6_thumb: require("./../img/gallery/Living Room/livingRoom11418/56-thumb.jpg"),
        five6: require("./../img/gallery/Living Room/livingRoom11418/56.jpg"),
        five7_thumb: require("./../img/gallery/Living Room/livingRoom11418/57-thumb.jpg"),
        five7: require("./../img/gallery/Living Room/livingRoom11418/57.jpg"),
        five8_thumb: require("./../img/gallery/Living Room/livingRoom11418/58-thumb.jpg"),
        five8: require("./../img/gallery/Living Room/livingRoom11418/58.jpg"),
        five9_thumb: require("./../img/gallery/Living Room/livingRoom11418/59-thumb.jpg"),
        five9: require("./../img/gallery/Living Room/livingRoom11418/59.jpg"),
        six0_thumb: require("./../img/gallery/Living Room/livingRoom11418/60-thumb.jpg"),
        six0: require("./../img/gallery/Living Room/livingRoom11418/60.jpg"),
        six1_thumb: require("./../img/gallery/Living Room/livingRoom11418/61-thumb.jpg"),
        six1: require("./../img/gallery/Living Room/livingRoom11418/61.jpg"),
        six2_thumb: require("./../img/gallery/Living Room/livingRoom11418/62-thumb.jpg"),
        six2: require("./../img/gallery/Living Room/livingRoom11418/62.jpg"),
        six3_thumb: require("./../img/gallery/Living Room/livingRoom11418/63-thumb.jpg"),
        six3: require("./../img/gallery/Living Room/livingRoom11418/63.jpg"),
        six4_thumb: require("./../img/gallery/Living Room/livingRoom11418/64-thumb.jpg"),
        six4: require("./../img/gallery/Living Room/livingRoom11418/64.jpg"),
        six5_thumb: require("./../img/gallery/Living Room/livingRoom11418/65-thumb.jpg"),
        six5: require("./../img/gallery/Living Room/livingRoom11418/65.jpg"),
        six6_thumb: require("./../img/gallery/Living Room/livingRoom11418/66-thumb.jpg"),
        six6: require("./../img/gallery/Living Room/livingRoom11418/66.jpg"),
        six7_thumb: require("./../img/gallery/Living Room/livingRoom11418/67-thumb.jpg"),
        six7: require("./../img/gallery/Living Room/livingRoom11418/67.jpg"),
        six8_thumb: require("./../img/gallery/Living Room/livingRoom11418/68-thumb.jpg"),
        six8: require("./../img/gallery/Living Room/livingRoom11418/68.jpg"),
        six9_thumb: require("./../img/gallery/Living Room/livingRoom11418/69-thumb.jpg"),
        six9: require("./../img/gallery/Living Room/livingRoom11418/69.jpg"),
        seven0_thumb: require("./../img/gallery/Living Room/livingRoom11418/70-thumb.jpg"),
        seven0: require("./../img/gallery/Living Room/livingRoom11418/70.jpg"),
        seven1_thumb: require("./../img/gallery/Living Room/livingRoom11418/71-thumb.jpg"),
        seven1: require("./../img/gallery/Living Room/livingRoom11418/71.jpg"),
        seven2_thumb: require("./../img/gallery/Living Room/livingRoom11418/72-thumb.jpg"),
        seven2: require("./../img/gallery/Living Room/livingRoom11418/72.jpg"),
        seven3_thumb: require("./../img/gallery/Living Room/livingRoom11418/73-thumb.jpg"),
        seven3: require("./../img/gallery/Living Room/livingRoom11418/73.jpg"),
        seven4_thumb: require("./../img/gallery/Living Room/livingRoom11418/74-thumb.jpg"),
        seven4: require("./../img/gallery/Living Room/livingRoom11418/74.jpg"),
        seven5_thumb: require("./../img/gallery/Living Room/livingRoom11418/75-thumb.jpg"),
        seven5: require("./../img/gallery/Living Room/livingRoom11418/75.jpg"),
        seven6_thumb: require("./../img/gallery/Living Room/livingRoom11418/76-thumb.jpg"),
        seven6: require("./../img/gallery/Living Room/livingRoom11418/76.jpg"),
        seven7_thumb: require("./../img/gallery/Living Room/livingRoom11418/77-thumb.jpg"),
        seven7: require("./../img/gallery/Living Room/livingRoom11418/77.jpg"),
        seven8_thumb: require("./../img/gallery/Living Room/livingRoom11418/78-thumb.jpg"),
        seven8: require("./../img/gallery/Living Room/livingRoom11418/78.jpg"),
        seven9_thumb: require("./../img/gallery/Living Room/livingRoom11418/79-thumb.jpg"),
        seven9: require("./../img/gallery/Living Room/livingRoom11418/79.jpg"),
        eight0_thumb: require("./../img/gallery/Living Room/livingRoom11418/80-thumb.jpg"),
        eight0: require("./../img/gallery/Living Room/livingRoom11418/80.jpg"),
        eight1_thumb: require("./../img/gallery/Living Room/livingRoom11418/81-thumb.jpg"),
        eight1: require("./../img/gallery/Living Room/livingRoom11418/81.jpg"),
        eight2_thumb: require("./../img/gallery/Living Room/livingRoom11418/82-thumb.jpg"),
        eight2: require("./../img/gallery/Living Room/livingRoom11418/82.jpg"),
        eight3_thumb: require("./../img/gallery/Living Room/livingRoom11418/83-thumb.jpg"),
        eight3: require("./../img/gallery/Living Room/livingRoom11418/83.jpg"),
      },
      Living_Room_A: {
        livinga10_thumb: require("./../img/gallery/Living Room/Living Room A/livinga10-thumb.jpg"),
        livinga10: require("./../img/gallery/Living Room/Living Room A/livinga10.jpg"),
        livinga13_thumb: require("./../img/gallery/Living Room/Living Room A/livinga13-thumb.jpg"),
        livinga13: require("./../img/gallery/Living Room/Living Room A/livinga13.jpg"),
        livinga14_thumb: require("./../img/gallery/Living Room/Living Room A/livinga14-thumb.jpg"),
        livinga14: require("./../img/gallery/Living Room/Living Room A/livinga14.jpg"),
        livinga15_thumb: require("./../img/gallery/Living Room/Living Room A/livinga15-thumb.jpg"),
        livinga15: require("./../img/gallery/Living Room/Living Room A/livinga15.jpg"),
        livinga16_thumb: require("./../img/gallery/Living Room/Living Room A/livinga16-thumb.jpg"),
        livinga16: require("./../img/gallery/Living Room/Living Room A/livinga16.jpg"),
        livinga17_thumb: require("./../img/gallery/Living Room/Living Room A/livinga17-thumb.jpg"),
        livinga17: require("./../img/gallery/Living Room/Living Room A/livinga17.jpg"),
        livinga2_thumb: require("./../img/gallery/Living Room/Living Room A/livinga2-thumb.jpg"),
        livinga2: require("./../img/gallery/Living Room/Living Room A/livinga2.jpg"),
        livinga3_thumb: require("./../img/gallery/Living Room/Living Room A/livinga3-thumb.jpg"),
        livinga3: require("./../img/gallery/Living Room/Living Room A/livinga3.jpg"),
        livinga6_thumb: require("./../img/gallery/Living Room/Living Room A/livinga6-thumb.jpg"),
        livinga6: require("./../img/gallery/Living Room/Living Room A/livinga6.jpg"),
        livinga7_thumb: require("./../img/gallery/Living Room/Living Room A/livinga7-thumb.jpg"),
        livinga7: require("./../img/gallery/Living Room/Living Room A/livinga7.jpg"),
        livinga8_thumb: require("./../img/gallery/Living Room/Living Room A/livinga8-thumb.jpg"),
        livinga8: require("./../img/gallery/Living Room/Living Room A/livinga8.jpg"),
        livinga9_thumb: require("./../img/gallery/Living Room/Living Room A/livinga9-thumb.jpg"),
        livinga9: require("./../img/gallery/Living Room/Living Room A/livinga9.jpg"),
      },
    }, //end Living_Room
    Miscellaneous: {
      Stonework: {
        stone1_thumb: require("./../img/gallery/Miscellaneous/Stonework/stone1-thumb.jpg"),
        stone1: require("./../img/gallery/Miscellaneous/Stonework/stone1.jpg"),
        stone2_thumb: require("./../img/gallery/Miscellaneous/Stonework/stone2-thumb.jpg"),
        stone2: require("./../img/gallery/Miscellaneous/Stonework/stone2.jpg"),
        stone3_thumb: require("./../img/gallery/Miscellaneous/Stonework/stone3-thumb.jpg"),
        stone3: require("./../img/gallery/Miscellaneous/Stonework/stone3.jpg"),
        stone4_thumb: require("./../img/gallery/Miscellaneous/Stonework/stone4-thumb.jpg"),
        stone4: require("./../img/gallery/Miscellaneous/Stonework/stone4.jpg"),
      },
    }, //end Miscellaneous
  },
}

export default images
