import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import Slider from 'react-slick'

import TEHelmet from './../../../component/Header/TEHelmet'

import images from './../../../config/images'
// import styles from './styles'
import $ from 'jquery'
import '../../../stylesheets/home.css'

class Home extends Component {
	state = {
		activeSlide: 0,
		callToActionPopup: false,
	}

	componentDidMount() {
		scrollUpdate()
		// setTimeout(() => {
		//   console.log('Show Popup')
		//   this.setState({ callToActionPopup: true })
		// }, 10000)

		//
		// Scrolling Code
		//
		var firstTimeCountUp = true

		$(window).scroll(function () {
			scrollUpdate()
		})

		function scrollUpdate() {
			if ($('#HOME_PAGE').length) {
				//Window Variables
				var windowTop = $(window).scrollTop()
				var windowHeight = $(window).height()
				var windowBottom = windowTop + windowHeight

				//Count Numbers
				var numbersContainer = $('#numbers')
				var numbersContainerMid = numbersContainer.offset().top
				// numbersContainer.outerHeight() / 2
				if (windowBottom > numbersContainerMid) {
					if (firstTimeCountUp) {
						$('.numbers-container span.number').each(function () {
							$(this)
								.prop('Counter', 0)
								.animate(
									{
										Counter: $(this).text(),
									},
									{
										duration: 3000,
										easing: 'swing',
										step: function (now) {
											$(this).text(
												Math.ceil(now).toLocaleString('en-US', {
													minimumFractionDigits: 0,
												}),
											)
										},
									},
								)
						})
						firstTimeCountUp = false
					}
				}
			}
		}
	}

	render() {
		const { activeSlide } = this.state
		const meta = {
			title: 'T-Squared Builders LLC',
			description: '',
		}
		const settings = {
			accessibility: true,
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: true,
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		}

		return (
			<div id='HOME_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<div className='slider' id='header'>
						<Slider
							{...settings}
							afterChange={(activeSlide) => this.setState({ activeSlide })}
						>
							<div
								className={activeSlide === 0 ? 'item active' : 'item'}
								id='flooring'
							>
								<img
									src={images.rotator.flooring}
									alt='T-Squared flooring'
									className='background'
								/>
								<div className='content'>
									<div className='title-container flooring'>
										<h2>Hardwood</h2>
										<br />
										<h2>Flooring</h2>
									</div>
								</div>
							</div>
							<div
								className={activeSlide === 1 ? 'item active' : 'item'}
								id='basements'
							>
								<img
									src={images.rotator.rotator_2}
									alt='T-Squared basements'
									className='background'
								/>
								<div className='content'>
									<div className='title-container basements'>
										<h2>Finished</h2>
										<br />
										<h2>Basements</h2>
									</div>
								</div>
							</div>
							<div
								className={activeSlide === 2 ? 'item active' : 'item'}
								id='kitchens'
							>
								<img
									src={images.rotator.kitchens}
									alt='T-Squared kitchens'
									className='background'
								/>
								<div className='content'>
									<div className='title-container kitchens'>
										<h2>Kitchens</h2>
									</div>
								</div>
							</div>
							<div
								className={activeSlide === 3 ? 'item active' : 'item'}
								id='exterior'
							>
								<img
									src={images.rotator.rotator_5}
									alt='T-Squared exterior'
									className='background'
								/>
								<div className='content'>
									<div className='title-container exterior'>
										<h2>Exteriors</h2>
									</div>
								</div>
							</div>
							<div
								className={activeSlide === 4 ? 'item active' : 'item'}
								id='bathrooms'
							>
								<img
									src={images.rotator.bathrooms}
									alt='T-Squared bathrooms'
									className='background'
								/>
								<div className='content'>
									<div className='title-container bathrooms'>
										<h2>Bathrooms</h2>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</header>
				<div className='section' id='quote'>
					<div className='column about'>
						T-Squared Builders LLC. is a general contracting and construction company
						serving the residential and commercial business markets within the
						Chicagoland suburban areas since 2002.
					</div>
					<div className='column quote'>
						<span className='quote-text'>
							If we relentlessly pursue perfection, knowing that it's unattainable,
							then we are destined to find excellence along the way...
						</span>
						<span className='author'>VINCE LOMBARDI, NFL FOOTBALL COACH</span>
					</div>
				</div>
				<div className='section' id='services'>
					<img src={images.texture2} alt='texture' className='background texture' />
					<img src={images.down_arrow} alt='down arrow' className='down-arrow top' />
					<div className='services-container'>
						<div className='service'>
							<img
								src={images.services.remodeling}
								alt='remodeling'
								title='Remodeling'
							/>
							<h4>Remodeling</h4>
						</div>
						<div className='service'>
							<img
								src={images.services.window}
								alt='windows, siding & gutter'
								title='Windows, Siding & Gutter'
							/>
							<h4>Windows, Siding &amp; Gutter Replacements</h4>
						</div>
						<div className='service'>
							<img src={images.services.flooring} alt='flooring' title='Flooring' />
							<h4>Flooring</h4>
						</div>
						<div className='service'>
							<img
								src={images.services.additions}
								alt='additions'
								title='Additions'
							/>
							<h4>Room Additions</h4>
						</div>
						<div className='service'>
							<img src={images.services.paint} alt='painting' title='Painting' />
							<h4>Interior &amp; Exterior Painting</h4>
						</div>
						<div className='service'>
							<img
								src={images.services.roof}
								alt='roof replacements'
								title='Roof Replacements'
							/>
							<h4>Roof Replacements</h4>
						</div>
					</div>
					<img src={images.down_arrow} alt='down arrow' className='down-arrow bottom' />
				</div>
				<div className='section' id='numbers'>
					<div className='numbers-container'>
						<div className='counter'>
							<span className='number'>500</span>
							<br />
							Projects Completed
							<br />
							Over 16 Years.
						</div>
						<div className='counter'>
							<span className='number percentage'>85</span>
							<br />
							Repeat &amp; Referral Rate.
						</div>
						<div className='counter'>
							<span className='number'>42000</span>
							<br />
							Hours On Job
							<br />
							Over 16 Years.
						</div>
					</div>
				</div>
				<div className='section' id='vendors'>
					<div className='title-container'>
						<h2 className='highlighted'>Vendors &amp;</h2>
						<br />
						<h2 className='highlighted'>Partners</h2>
					</div>
					<div className='vendors-container'>
						<div className='vendor'>
							<a
								href='https://accountingsolutionsltd.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.accsolutions_logo}
									alt='accounting solutions'
									title='Accounting Solutions'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.barringtonbank.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.barringtonbt_ogo}
									alt='barrington bank & trust company'
									title='Barrington Bank & Trust Company'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.bosch-home.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.bosch_logo} alt='bosch' title='Bosch' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.briddicktileandstone.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.briddickts_logo}
									alt='briddick tile and stone'
									title='Briddick Tile and Stone'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.centralglasschicago.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.centralglasschicago_logo}
									alt='central glass chicago'
									title='Central Glass Chicago'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.damicopaving.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.damicopaving_logo}
									alt='damico paving'
									title='Damico Paving'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.dewalt.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.dewalt_logo} alt='dewalt' title='Dewalt' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.grohe.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.grohe_logo} alt='grohe' title='Grohe' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.homedepot.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.homedepot_logo}
									alt='the home depot'
									title='The Home Depot'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.imtins.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.IMT_logo}
									alt='imt insurance'
									title='IMT Insurance'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.jspaluch.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.jsp_logo}
									alt='j.s. paluch company, inc'
									title='J.S. Paluch Company, Inc'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.us.kohler.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.kohler_logo} alt='kohler' title='Kohler' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.kraftmaid.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.kraftmaid_logo}
									alt='kraftmaid'
									title='KraftMaid'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.kwikprint8.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.kwikprint_logo}
									alt='kwik print'
									title='Kwik Print'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.luna.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.luna_logo} alt='luna' title='Luna' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://maherlumberco.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.maher_logo}
									alt='maher lumber'
									title='Maher Lumber'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.comfortbyrhc.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.ridgewayhvac_logo}
									alt='ridge way hvac'
									title='Ridge Way HVAC'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.robern.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img src={images.vendor.robern_logo} alt='robern' title='Robern' />
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.38thstreetstudios.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.three8ss_logo_and_text}
									alt='38th street studios'
									title='38th Street Studios'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='http://www.thomasvillecabinetry.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.thomasvillecabinets_logo}
									alt='thomasville cabinets'
									title='Thomasville Cabinets'
								/>
							</a>
						</div>
						<div className='vendor'>
							<a
								href='https://www.tileshop.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={images.vendor.tileshop_logo}
									alt='the tile shop'
									title='The Tile Shop'
								/>
							</a>
						</div>
					</div>
				</div>
				<div className='section' id='rpfpc'>
					<h3 className='title'>
						How We
						<br />
						Give Back
					</h3>
					<a href='http://www.rpfpc.com/' target='_blank' rel='noopener noreferrer'>
						<img src={images.rpfpc} alt='RPFPC' title='RPFPC' className='rpfpc-logo' />
					</a>
					<a
						href='http://www.rpfpc.com/'
						target='_blank'
						rel='noopener noreferrer'
						className='button'
					>
						Learn More
					</a>
				</div>
				{this.state.callToActionPopup && (
					<div className='call-to-action-popup'>
						<div className='content'>
							<div className='title-container'>
								<img
									src={images.logo_text}
									alt='T-Squared Logo'
									title='T-Squared Builders'
									className='logo'
								/>
							</div>
							<div className='close'>
								<button
									type='button'
									onClick={() => {
										this.setState({ callToActionPopup: false })
									}}
								>
									X
								</button>
							</div>
							<div className='copy-container'>
								{/*<h2 class="title">Brand New Galleries</h2>*/}
								<p className='copy'>See Some Of Our Newest Work!</p>
								<div className='images-container'>
									<a href='/gallery?gal=kitchens'>
										<img
											src={images.gallery.Kitchen.Kitchen11418.eight4}
											alt=''
											className='newWork'
										/>
									</a>
								</div>
								<a href='/gallery' className='button'>
									Check It Out
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

Home.propTypes = {}

const mapStateToProps = (state) => {
	//const { } = state.Home

	return {}
}

export default connect(mapStateToProps, {})(Radium(Home))
