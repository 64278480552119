import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Radium from 'radium'

import images from './../../../config/images'
// import styles from './styles'
import $ from 'jquery'

class Navbar extends Component {
  componentDidMount() {
    // Sandwich menu
    $('.mobile button').click(function() {
      $(this)
        .toggleClass('expanded')
        .siblings('div')
        .slideToggle()
      $('#header').toggleClass('nav-expanded')
    })

    //Highlight Active Page
    $('nav ul li a.nav-link').each(function() {
      if ($(this).attr('href') === window.location.pathname) {
        $(this).addClass('highlighted')
      }
    })
  }

  render() {
    return (
      <Fragment>
        <nav className="navbar">
          <div className="brand-container">
            <img
              src={images.logo_text}
              alt="T-squared Logo"
              title="T-squared Builders"
              className="logo"
            />
          </div>
          <div className="container">
            <ul>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/">
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/story">
                  STORY
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/guarantees">
                  GUARANTEES
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/gallery">
                  GALLERIES
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="highlighted"
                  exact
                  to="/testimonials"
                >
                  TESTIMONIALS
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/news">
                  {' '}
                  NEWS
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/contact">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="mobile">
          <div className="brand-container">
            <img
              src={images.logo_text}
              alt="T-Squared Logo"
              title="T-Squared Builders"
              className="logo"
            />
          </div>
          <button>
            <img src={images.sandwich_button} alt="menu button" />
          </button>
          <div className="container">
            <ul>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/">
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/story">
                  STORY
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/guarantees">
                  GUARANTEES
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/gallery">
                  GALLERIES
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  activeClassName="highlighted"
                  exact
                  to="/testimonials"
                >
                  TESTIMONIALS
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/news">
                  NEWS
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="highlighted" exact to="/contact">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </Fragment>
    )
  }
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

export default Radium(Navbar)
