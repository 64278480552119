//Used for Polyfills.
//TODO: Only import what is needed instead of whole things.
import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { StyleRoot } from 'radium'

import './index.css'
import './dependencies/photo-swipe/photoswipe.css'
import './dependencies/photo-swipe/default-skin/default-skin.css'
import './slick-theme.css'
import './slick.css'

import ScrollToTop from './component/ScrollToTop'

import GoogleAnalyticsTracking from './component/GoogleAnalyticsTracking'
import App from './App'
import reducers from './reducers'

const store = createStore(reducers, applyMiddleware(ReduxThunk))

const element = (
	<StyleRoot>
		<Provider store={store}>
			<Router>
				<ScrollToTop>
					<Route component={GoogleAnalyticsTracking(App)} />
				</ScrollToTop>
			</Router>
		</Provider>
	</StyleRoot>
)

ReactDOM.render(element, document.getElementById('root'))
unregister()
