import React from 'react'

import { bathroom3819 } from '../../../config/images'

const array = [
	{ path: '1-thumb.jpg', w: 150, h: 200 },
	{ path: '1.jpg', w: 1200, h: 1600 },
	{ path: '2-thumb.jpg', w: 150, h: 200 },
	{ path: '2.jpg', w: 1200, h: 1600 },
	{ path: '3-thumb.jpg', w: 150, h: 200 },
	{ path: '3.jpg', w: 1200, h: 1600 },
	{ path: '4-thumb.jpg', w: 150, h: 200 },
	{ path: '4.jpg', w: 1200, h: 1600 },
	{ path: '5-thumb.jpg', w: 150, h: 200 },
	{ path: '5.jpg', w: 1200, h: 1600 },
	{ path: '6-thumb.jpg', w: 150, h: 200 },
	{ path: '6.jpg', w: 1200, h: 1600 },
	{ path: '7-thumb.jpg', w: 150, h: 200 },
	{ path: '7.jpg', w: 1200, h: 1600 },
	{ path: '8-thumb.jpg', w: 150, h: 113 },
	{ path: '8.jpg', w: 1200, h: 900 },
	{ path: '9-thumb.jpg', w: 150, h: 200 },
	{ path: '9.jpg', w: 1200, h: 1600 },
	{ path: '10-thumb.jpg', w: 150, h: 113 },
	{ path: '10.jpg', w: 1200, h: 900 },
	{ path: '11-thumb.jpg', w: 150, h: 200 },
	{ path: '11.jpg', w: 1200, h: 1600 },
	{ path: '12-thumb.jpg', w: 150, h: 200 },
	{ path: '12.jpg', w: 1200, h: 1600 },
	{ path: '13-thumb.jpg', w: 150, h: 113 },
	{ path: '13.jpg', w: 1200, h: 900 },
	{ path: '14-thumb.jpg', w: 150, h: 200 },
	{ path: '14.jpg', w: 1200, h: 1600 },
	{ path: '15-thumb.jpg', w: 150, h: 200 },
	{ path: '15.jpg', w: 1200, h: 1600 },
	{ path: '16-thumb.jpg', w: 150, h: 113 },
	{ path: '16.jpg', w: 1200, h: 900 },
	{ path: '17-thumb.jpg', w: 150, h: 200 },
	{ path: '17.jpg', w: 1200, h: 1600 },
	{ path: '18-thumb.jpg', w: 150, h: 200 },
	{ path: '18.jpg', w: 1200, h: 1600 },
	{ path: '19-thumb.jpg', w: 150, h: 113 },
	{ path: '19.jpg', w: 1200, h: 900 },
	{ path: '20-thumb.jpg', w: 150, h: 113 },
	{ path: '20.jpg', w: 1200, h: 900 },
	{ path: '21-thumb.jpg', w: 150, h: 200 },
	{ path: '21.jpg', w: 1200, h: 1600 },
	{ path: '22-thumb.jpg', w: 150, h: 200 },
	{ path: '22.jpg', w: 1200, h: 1600 },
	{ path: '23-thumb.jpg', w: 150, h: 113 },
	{ path: '23.jpg', w: 1200, h: 900 },
	{ path: '24-thumb.jpg', w: 150, h: 200 },
	{ path: '24.jpg', w: 1200, h: 1600 },
	{ path: '25-thumb.jpg', w: 150, h: 113 },
	{ path: '25.jpg', w: 1200, h: 900 },
	{ path: '26-thumb.jpg', w: 150, h: 200 },
	{ path: '26.jpg', w: 1200, h: 1600 },
	{ path: '27-thumb.jpg', w: 150, h: 200 },
	{ path: '27.jpg', w: 1200, h: 1600 },
	{ path: '28-thumb.jpg', w: 150, h: 200 },
	{ path: '28.jpg', w: 1200, h: 1600 },
	{ path: '29-thumb.jpg', w: 150, h: 200 },
	{ path: '29.jpg', w: 1200, h: 1600 },
	{ path: '30-thumb.jpg', w: 150, h: 200 },
	{ path: '30.jpg', w: 1200, h: 1600 },
	{ path: '31-thumb.jpg', w: 150, h: 200 },
	{ path: '31.jpg', w: 1200, h: 1600 },
	{ path: '32-thumb.jpg', w: 150, h: 200 },
	{ path: '32.jpg', w: 1200, h: 1600 },
	{ path: '33-thumb.jpg', w: 150, h: 200 },
	{ path: '33.jpg', w: 1200, h: 1600 },
	{ path: '34-thumb.jpg', w: 150, h: 200 },
	{ path: '34.jpg', w: 1200, h: 1600 },
	{ path: '35-thumb.jpg', w: 150, h: 200 },
	{ path: '35.jpg', w: 1200, h: 1600 },
	{ path: '36-thumb.jpg', w: 150, h: 113 },
	{ path: '36.jpg', w: 1200, h: 900 },
	{ path: '37-thumb.jpg', w: 150, h: 200 },
	{ path: '37.jpg', w: 1200, h: 1600 },
	{ path: '38-thumb.jpg', w: 150, h: 113 },
	{ path: '38.jpg', w: 1200, h: 900 },
	{ path: '39-thumb.jpg', w: 150, h: 200 },
	{ path: '39.jpg', w: 1200, h: 1600 },
	{ path: '40-thumb.jpg', w: 150, h: 113 },
	{ path: '40.jpg', w: 1200, h: 900 },
]

//TODO: To improve this group thumb and full and make a nice full object.

export default () => {
	const componentArray = []
	for (let i = 0; i < array.length; i++) {
		componentArray.push(
			<figure
				itemProp="associatedMedia"
				itemScope="itemScope"
				itemType="http://schema.org/ImageObject"
				key={i}
			>
				<a
					href={bathroom3819[array[i + 1].path]}
					itemProp="contentUrl"
					data-size={`${array[i + 1].w}x${array[i + 1].h}`}
				>
					<img src={bathroom3819[array[i].path]} itemProp="thumbnail" alt="description" />
				</a>
				<figcaption itemProp="caption description">{array[i + 1].caption}</figcaption>
			</figure>
		)
		i++
	}
	return componentArray
}
