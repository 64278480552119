import React, { Component } from 'react'
import $ from 'jquery'
//import PhotoSwipe, { PhotoSwipeUI_Default } from 'photoswipe'
import TEHelmet from './../../../component/Header/TEHelmet'

import images from '../../../config/images'
import '../../../stylesheets/story.css'

class Story extends Component {
	state = {
		showReadMore: false,

		//Meet The Crew categories
		showSW: false,
		showPD: false,
		showTS: false,
		showHvac: false,
		showShower: false,
		showStucco: false,
		showPlumbers: false,
		showFlooring: false,
		showLeadership: true, //default
		showCarpenters: false,
		showElectricians: false,
	}

	componentDidMount() {
		scrollUpdate()
		loadAllImages()
		$('#STORY_PAGE .header .content h1').addClass('loaded')

		$(document).ready(function () {
			$(window).scroll(function () {
				scrollUpdate()
			})
		})

		function scrollUpdate() {
			if ($('#STORY_PAGE').length) {
				//Window Variables
				var windowTop = $(window).scrollTop()
				var windowHeight = $(window).height()
				var windowBottom = windowTop + windowHeight

				//Tom
				var tom = $('#tom h2')
				var tomBottom = tom.offset().top + tom.outerHeight() * 0.75

				if (windowBottom > tomBottom) {
					tom.addClass('slide-in')
				}

				//Story
				var story = $('#story h2')
				var storyBottom = story.offset().top + tom.outerHeight() * 0.75

				if (windowBottom > storyBottom) {
					story.addClass('slide-in')
				}
			}
		}

		function loadAllImages() {
			var allImages = $('img[delayedsrc]')

			//Start All Images Loading
			allImages.each(function () {
				$(this).attr('src', $(this).attr('delayedsrc'))
			})
		}
	}

	setCategory = (category) => {
		//set all divs to false
		this.setState({ showSW: false })
		this.setState({ showPD: false })
		this.setState({ showTS: false })
		this.setState({ showHvac: false })
		this.setState({ showShower: false })
		this.setState({ showStucco: false })
		this.setState({ showPlumbers: false })
		this.setState({ showFlooring: false })
		this.setState({ showLeadership: false })
		this.setState({ showCarpenters: false })
		this.setState({ showElectricians: false })

		//set active one to true
		switch (category) {
			case 'Leadership':
				this.setState({ showLeadership: true })
				break
			case 'Master Carpenters':
				this.setState({ showCarpenters: true })
				break
			case 'Master Electricians':
				this.setState({ showElectricians: true })
				break
			case 'Master Plumbers':
				this.setState({ showPlumbers: true })
				break
			case 'HVAC':
				this.setState({ showHvac: true })
				break
			case 'Stucco':
				this.setState({ showStucco: true })
				break
			case 'Glass Shower':
				this.setState({ showShower: true })
				break
			case 'Tile & Stone':
				this.setState({ showTS: true })
				break
			case 'Hardwood Flooring':
				this.setState({ showFlooring: true })
				break
			case 'Siding & Windows':
				this.setState({ showSW: true })
				break
			case 'Painting & Drywall':
				this.setState({ showPD: true })
				break
			default:
				break
		}
	}

	render() {
		const meta = {
			title: 'T-Squared Builders LLC | Story',
			description: '',
		}
		return (
			<div id='STORY_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<img src={images.story_header} alt='header background' className='background' />
					<div className='content'>
						<h1 className='highlighted'>Our Story</h1>
					</div>
				</header>
				<div className='section' id='tom'>
					<div className='column left'>
						<img
							src={images.tom_serpento}
							alt='tom serpento'
							title='Tom Serpento'
							className='tom'
						/>
						<h2 className='highlighted'>Tom Serpento</h2>
					</div>
					<div className='column right'>
						Tom Serpento is the President &amp; CEO of <b>T-Squared Builders LLC</b>, a
						general contracting and construction company serving the residential and
						commercial business markets within the Chicagoland suburban areas since
						2002. Throughout his professional career and prior to his founding of{' '}
						<b>T-Squared Builders LLC</b>, Tom held a number of positions that have
						honed his distinct managerial style and marketing expertise. His last
						exposure to “Corporate America” was as a nationally recognized marketing
						manager for General Motors “On-Star” division, before venturing off on his
						own. Tom oversees all areas of corporate operations: business development,
						project management, vendor management, finance &amp; marketing—all the while
						continuing to cultivate the very personal relationships shared by the
						company and their clientele. He is a hands-on, driven leader with an
						unwavering attention to detail who maintains a results-oriented approach to
						doing business. Tom gathered his formal education at Iowa State University,
						where he was also an NCAA collegiate athlete.
					</div>
				</div>
				<div className='section' id='story'>
					<img src={images.texture2} alt='texture' className='background texture' />
					<img src={images.down_arrow} alt='down arrow' className='down-arrow top' />
					<div className='story-container'>
						<div className='title-container'>
							<h2 className='highlighted'>The T-Squared Story</h2>
							<img src={images.crew.CoachAndTom} alt='Coach and Tom' />
							<h3 className='highlighted'>
								Longtime Friend &amp; DePaul Basketball Legend Ray Meyer with Tom
							</h3>
						</div>
						<p>
							In March of 2001, my life would be forever changed! After nearly a
							twenty-year career within the confines of “Corporate America”, the last
							of which where I had been enveloped in helping grow another venture
							capital business for General Motors Corporation, I was released from
							contract. My unfortunate demise, just months before the tragedy of 9/11,
							and the extreme drop in the job market, couldn’t have come at a worse
							time.
						</p>
						<p>
							There I was facing the prospect of seeking out another “professional
							management position” from within the realm of all that had become
							familiar to me throughout the years. I engaged in numerous interviews
							all over the country with various potential employers, in an effort to
							bring some stability back into my professional life. A full year went by
							with no concrete prospects on the horizon. During that time, I had
							exhausted all of my financial resources &amp; was literally living on
							unemployment benefits, coupled with the help of family &amp; friends. It
							was truly the darkest period of my life! From the age of sixteen, I had
							always had a job, and thrived on making a viable contribution to society
							and the workforce.
						</p>
						{!this.state.showReadMore && (
							<button
								type='button'
								className='read-more'
								onClick={(event) => {
									this.setState({ showReadMore: !this.state.showReadMore })
								}}
								title='Read More'
							>
								<span>Read Full Story</span>
								<img src={images.down_arrow} alt='read more' />
							</button>
						)}
						{this.state.showReadMore && (
							<div className='full-story'>
								<p>
									As a person of great faith and a highly competitive nature, I
									never surrendered to the idea that all was lost. My family and
									closest friends saw to that by their continual encouragement of
									me to “stay in the fight.” Until one Sunday following church,
									and while sitting at the kitchen table of one of my dearest
									&amp; beloved friends, legendary DePaul basketball coach, Ray
									Meyer; it was he that suggested: “…Ya know Thomas, I need some
									things repaired on my home, and I know you have the ability and
									knowledge to fix them. May I hire you to see them done?
								</p>
								<p>
									I was broke and needed the money. While a part of my spirit had
									been briefly overtaken by a useless sense of shame, I was,
									nevertheless, eternally grateful to “Coach Ray” for his
									unwavering support, friendship and belief in me throughout this
									very challenging time. So, I accepted his offer.
								</p>
								<p>
									For the weeks that followed, I found myself developing a genuine
									rebirth in my passion for building things, and actually seeing
									the tangible results of my labor at the end of each day. I was
									fully embracing this transformation in me, and more important,
									my outlook towards the future.
								</p>
								<p>
									That experience…that opportunity…became a defining moment in my
									life, and my search for clarity. It took me back to my roots
									growing up in Iowa where, on my mother’s side of the family,
									there had been a long and successful history in the construction
									business. I had been exposed to it most of my life, and even
									further as I worked during the summers to help pay for my
									education while attending Iowa State University.
								</p>
								<p>
									When I had completed the various projects for Coach Ray, he
									offered yet another suggestion; “…Instead of relying on your
									expertise towards growing someone else’s company, why not start
									your own?”
								</p>
								<p>
									The prospect of creating a business on my own to sustain myself
									was more than a little unnerving. Then again, how does any one
									of us ever come to know real success without the existence of
									risk, challenge, and that anxious anticipation of the unknown?
									One thing was certain however: I would never again put my
									professional destiny in the hands of those within the “corporate
									environment,” or consider myself a victim within the framework
									of a volatile, economic climate.
								</p>
								<p>
									With the grace of God, it would be I who would control my
									destiny!
								</p>
								<p>
									Clearly, I had arrived at the crossroads of my life and was
									faced with the prospect of reinventing myself…at age 40! It was
									the most challenging and exciting time of my life—and has been
									every day since then!
								</p>
								<p>
									Coach And Tom It was no surprise that my beloved friend, Coach
									Ray, would come up with the name for the company, T-Squared. And
									so it began. With nothing more than a $614 unemployment check to
									cover the legal costs of incorporating, T-Squared Builders LLC
									was born.
								</p>
								<p>
									Fifteen-years later, we have caringly established (one client,
									one project at a time) a very significant presence and wonderful
									reputation within the construction market for the Chicago
									suburbs. And while I, along with the world, deeply grieved the
									loss of my beloved friend, “The Coach” on March 17th, 2006,
									there is not a single day that goes by where I don’t think of
									him fondly, along with the invaluable lessons and insights with
									which he gifted my life.
								</p>
								<p>
									Today, after hundreds of successful projects &amp; years of
									satisfied clients, T-Squared Builders LLC continues in its
									pursuit of excellence while bringing the type of joy that only
									quality work can bring.
								</p>
								<p>
									Through much sweat, tears, and even blood, we are now a family
									of highly skilled trades people, representing every facet of the
									construction business. We relish the opportunity to build &amp;
									create, and are fueled by an unending level of pride in our
									craft &amp; workmanship.
								</p>
								<p>
									My goal has always been to provide a much different approach to
									the construction process, one that treats the client with
									extraordinary respect and unwavering truth, combined with a
									genuine and heartfelt sense of gratitude for the enormous degree
									of trust put in our organization each &amp; every time we’re
									hired to upgrade or beautify a client’s home or business.
								</p>
								<p>
									Every single day, I get up knowing that I, along with our
									fabulous team, look forward to serving the needs of
									others…because we LOVE what we do!
								</p>
								<p>
									NOTHING matters more to me and those that work with me, than the
									continued growth and cultivation of those relationships we’ve
									been blessed to share in with our clients as we deliver a
									quality product and service.
								</p>
								<p>It’s just that simple.</p>
								<p>To that end, the company motto will never change:</p>
								<p className='final-quote'>
									"Your complete satisfaction is not just our goal, it’s what we
									assure!"
								</p>
							</div>
						)}
					</div>
					<img src={images.down_arrow} alt='down arrow' className='down-arrow bottom' />
				</div>
				<div className='section' id='crew'>
					<h2 className='highlighted'>Meet The Crew</h2>
					<div className='category-container'>
						<fieldset id='team'>
							<input
								type='radio'
								name='team'
								defaultValue='Leadership'
								id='Leadership'
								onChange={(event) => {
									this.setCategory('Leadership')
								}}
								checked={this.state.showLeadership}
							/>
							<label htmlFor='Leadership' className='segment'>
								Leadership
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Master Carpenters'
								id='Master Carpenters'
								onChange={(event) => {
									this.setCategory('Master Carpenters')
								}}
								checked={this.state.showCarpenters}
							/>
							<label htmlFor='Master Carpenters' className='segment'>
								Master Carpenters
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Master Electricians'
								id='Master Electricians'
								onChange={(event) => {
									this.setCategory('Master Electricians')
								}}
								checked={this.state.showElectricians}
							/>
							<label htmlFor='Master Electricians' className='segment'>
								Master Electricians
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Master Plumbers'
								id='Master Plumbers'
								onChange={(event) => {
									this.setCategory('Master Plumbers')
								}}
								checked={this.state.showPlumbers}
							/>
							<label htmlFor='Master Plumbers' className='segment'>
								Master Plumbers
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='HVAC'
								id='HVAC'
								onChange={(event) => {
									this.setCategory('HVAC')
								}}
								checked={this.state.showHvac}
							/>
							<label htmlFor='HVAC' className='segment'>
								HVAC
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Stucco'
								id='Stucco'
								onChange={(event) => {
									this.setCategory('Stucco')
								}}
								checked={this.state.showStucco}
							/>
							<label htmlFor='Stucco' className='segment'>
								Stucco, E.I.F.S., Concrete, Masonry
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Glass Shower'
								id='Glass Shower'
								onChange={(event) => {
									this.setCategory('Glass Shower')
								}}
								checked={this.state.showShower}
							/>
							<label htmlFor='Glass Shower' className='segment'>
								Custom Glass Shower Enclosures
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Tile & Stone'
								id='Tile & Stone'
								onChange={(event) => {
									this.setCategory('Tile & Stone')
								}}
								checked={this.state.showShower}
							/>
							<label htmlFor='Tile & Stone' className='segment'>
								Tile &amp; Stone
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Hardwood Flooring'
								id='Hardwood Flooring'
								onChange={(event) => {
									this.setCategory('Hardwood Flooring')
								}}
								checked={this.state.showFlooring}
							/>
							<label htmlFor='Hardwood Flooring' className='segment'>
								Hardwood Flooring
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Siding & Windows'
								id='Siding & Windows'
								onChange={(event) => {
									this.setCategory('Siding & Windows')
								}}
								checked={this.state.showSW}
							/>
							<label htmlFor='Siding & Windows' className='segment'>
								Siding &amp; Windows
							</label>
							<input
								type='radio'
								name='team'
								defaultValue='Painting & Drywall'
								id='Painting & Drywall'
								onChange={(event) => {
									this.setCategory('Painting & Drywall')
								}}
								checked={this.state.showPD}
							/>
							<label htmlFor='Painting & Drywall' className='segment'>
								Painting &amp; Drywall
							</label>
						</fieldset>
					</div>
					<div className='member-container'>
						{/*LEADERSHIP*/}
						{this.state.showLeadership && (
							<div>
								<div className='member'>
									<img
										src={images.crew.TomSerpento_blue}
										alt='tom serpento'
										title='Tom Serpento'
									/>
									<div className='name'>Tom Serpento</div>
									<div className='credentials'>President &amp; CEO</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.ClayLamb}
										alt='Clay Lamb'
										title='Clay Lamb'
									/>
									<div className='name'>Clay Lamb</div>
									<div className='credentials'>
										Senior On-Site Project Manager
									</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BrianAllen}
										alt='Brian Allen'
										title='Brian Allen'
									/>
									<div className='name'>Brian Allen</div>
									<div className='credentials'>
										Senior On-Site Project Manager
									</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BrianCarlborg}
										alt='Brian Carlborg'
										title='Brian Carlborg'
									/>
									<div className='name'>Brian Carlborg</div>
									<div className='credentials'>
										Senior On-Site Project Manager
									</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.JamieHolt}
										alt='Jamie Holt'
										title='Jamie Holt'
									/>
									<div className='name'>Jamie Holt</div>
									<div className='credentials'>Administrative Assistant</div>
								</div>
							</div>
						)}
						{/*Master Carpenters*/}
						{this.state.showCarpenters && (
							<div>
								<div className='member'>
									<img
										src={images.crew.ClayLamb}
										alt='Clay Lamb'
										title='Clay Lamb'
									/>
									<div className='name'>Clay Lamb</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.GregPitek}
										alt='Greg Pitek'
										title='Greg Pitek'
									/>
									<div className='name'>Greg Pitek</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BillGalichio}
										alt='Bill Galichio'
										title='Bill Galichio'
									/>
									<div className='name'>Bill Galichio</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BrianAllen}
										alt='Brian Allen'
										title='Brian Allen'
									/>
									<div className='name'>Brian Allen</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.PhillOlsen}
										alt='Phill Olsen'
										title='Phill Olsen'
									/>
									<div className='name'>Phill Olsen</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.PeteLamb}
										alt='Pete Lamb'
										title='Pete Lamb'
									/>
									<div className='name'>Pete Lamb</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.RichardAceDulceak}
										alt='Richard Ace Dulceak'
										title='Richard Ace Dulceak'
									/>
									<div className='name'>Richard "Ace" Dulceak</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JasonKeeley}
										alt='Jason Keeley'
										title='Jason Keeley'
									/>
									<div className='name'>Jason Keeley</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Master Electricians*/}
						{this.state.showElectricians && (
							<div>
								<div className='member'>
									<img
										src={images.crew.KenBogus}
										alt='Ken Bogus'
										title='Ken Bogus'
									/>
									<div className='name'>Ken Bogus</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.GregHacker}
										alt='Greg Hacker'
										title='Greg Hacker'
									/>
									<div className='name'>Greg Hacker</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Master Plumbers*/}
						{this.state.showPlumbers && (
							<div>
								<div className='member'>
									<img
										src={images.crew.BobShultz}
										alt='Bob Schultz'
										title='Bob Schultz'
									/>
									<div className='name'>Bob Schultz</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.ScottSasman}
										alt='Scott Sasman'
										title='Scott Sasman'
									/>
									<div className='name'>Scott Sasman</div>
									<div className='credentials'></div>
								</div>
								<div className='member'>
									<img
										src={images.crew.DanMooney}
										alt='Dan Mooney'
										title='Dan Mooney'
									/>
									<div className='name'>Dan Mooney</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.SonnySmith}
										alt='Sonny Smith'
										title='Sonny Smith'
									/>
									<div className='name'>Sonny Smith</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.MikeSlabich}
										alt='Mike Slabich'
										title='Mike Slabich'
									/>
									<div className='name'>Mike Slabich</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.SteveCibrowski}
										alt='Steve Smash Cibrowski'
										title='Steve Smash Cibrowski'
									/>
									<div className='name'>Steve "Smash" Cibrowski</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.ShawnGunderson}
										alt='Shawn Gunderson'
										title='Shawn Gunderson'
									/>
									<div className='name'>Shawn Gunderson</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*HVAC*/}
						{this.state.showHvac && (
							<div>
								<div className='member'>
									<img
										src={images.crew.RichBarfield}
										alt='Rich Barfield'
										title='Rich Barfield'
									/>
									<div className='name'>Rich Barfield</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BarryNagle}
										alt='Barry Nagle'
										title='Barry Nagle'
									/>
									<div className='name'>Barry Nagle</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.GeorgeJackson}
										alt='George Jackson'
										title='George Jackson'
									/>
									<div className='name'>George Jackson</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JeremyOlson}
										alt='Jeremy Olson'
										title='Jeremy Olson'
									/>
									<div className='name'>Jeremy Olson</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.LeonardHouda}
										alt='Leonard Houda'
										title='Leonard Houda'
									/>
									<div className='name'>Leonard Houda</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.RandyGabel}
										alt='Randy Gabel'
										title='Randy Gabel'
									/>
									<div className='name'>Randy Gabel</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Stucco*/}
						{this.state.showStucco && (
							<div>
								<div className='member'>
									<img
										src={images.crew.AndrzejAndySmiarowski}
										alt='Andrzej Andy Smiarowski'
										title='Andrzej Andy Smiarowski'
									/>
									<div className='name'>Andrzej "Andy" Smiarowski</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.PaulMeintzer}
										alt='Paul Meintzer'
										title='Paul Meintzer'
									/>
									<div className='name'>Paul Meintzer</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.AndrewMeintzer}
										alt='Andrew Meintzer'
										title='Andrew Meintzer'
									/>
									<div className='name'>Andrew Meintzer</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Glass Shower*/}
						{this.state.showShower && (
							<div>
								<div className='member'>
									<img
										src={images.crew.MichaelAbrignani}
										alt='Michael Abrignani'
										title='Michael Abrignani'
									/>
									<div className='name'>Michael Abrignani</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.JoeKalous}
										alt='Joe Kalous'
										title='Joe Kalous'
									/>
									<div className='name'>Joe Kalous</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Tile & Stone*/}
						{this.state.showTS && (
							<div>
								<div className='member'>
									<img
										src={images.crew.KrzysztofKrisLupinski}
										alt='Krzysztof Kris Lupinski'
										title="Krzysztof 'Kris' Lupinski"
									/>
									<div className='name'>Krzysztof "Kris" Lupinski</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.AndreiViscribov}
										alt='Andrei Viscribov'
										title='Andrei Viscribov'
									/>
									<div className='name'>Andrei Viscribov</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.MykolaRomanyuk}
										alt='Mykola Romanyuk'
										title='Mykola Romanyuk'
									/>
									<div className='name'>Mykola Romanyuk</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.LuisGuzman}
										alt='Luis Guzman'
										title='Luis Guzman'
									/>
									<div className='name'>Luis Guzman</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.OlehVivcharyk}
										alt='Oleh Vivcharyk'
										title='Oleh Vivcharyk'
									/>
									<div className='name'>Oleh Vivcharyk</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Hardwood Flooring*/}
						{this.state.showFlooring && (
							<div>
								<div className='member'>
									<img
										src={images.crew.RobertVarlam}
										alt='Robert Varlam'
										title='Robert Varlam'
									/>
									<div className='name'>Robert Varlam</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.HoracioMuresan}
										alt='Horacio Muresan'
										title='Horacio Muresan'
									/>
									<div className='name'>Horacio Muresan</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.IosifHardar}
										alt='Iosif Hardar'
										title='Iosif Hardar'
									/>
									<div className='name'>Iosif Hardar</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.GrigoreAndyDobrican}
										alt='Grigore Andy Dobrican'
										title='Grigore Andy Dobrican'
									/>
									<div className='name'>Grigore "Andy" Dobrican</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.PaulSirbu}
										alt='Paul Sirbu'
										title='Paul Sirbu'
									/>
									<div className='name'>Paul Sirbu</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.IoanPop}
										alt='Ioan Pop'
										title='Ioan Pop'
									/>
									<div className='name'>Ioan Pop</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.AndrewDeak}
										alt='Andrew Deak'
										title='Andrew Deak'
									/>
									<div className='name'>Andrew Deak</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.TeodorTeoSilas}
										alt='Teodor Teo Silas'
										title='Teodor Teo Silas'
									/>
									<div className='name'>Teodor "Teo" Silas</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Siding & Windows*/}
						{this.state.showSW && (
							<div>
								<div className='member'>
									<img
										src={images.crew.BrianCarlborg}
										alt='Brian Carlborg'
										title='Brian Carlborg'
									/>
									<div className='name'>Brian Carlborg</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img src={images.crew.TomLeo} alt='Tom Leo' title='Tom Leo' />
									<div className='name'>Tom Leo</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.BillBiewer}
										alt='Bill Biewer, Sr.'
										title='Bill Biewer, Sr.'
									/>
									<div className='name'>Bill Biewer, Sr.</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.PeteHavel}
										alt='Pete Havel'
										title='Pete Havel'
									/>
									<div className='name'>Pete Havel</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img src={images.crew.TimLeo} alt='Tim Leo' title='Tim Leo' />
									<div className='name'>Tim Leo</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.AudiHavel}
										alt='Audi Havel'
										title='Audi Havel'
									/>
									<div className='name'>Audi Havel</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.WilliamBiewerJr}
										alt='William Biewer, Jr.'
										title='William Biewer, Jr.'
									/>
									<div className='name'>William Biewer, Jr.</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.AndrewBiewer}
										alt='Andrew Drew Biewer'
										title='Andrew Drew Biewer'
									/>
									<div className='name'>Andrew "Drew" Biewer</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.ZachLeo}
										alt='Zach Leo'
										title='Zach Leo'
									/>
									<div className='name'>Zach Leo</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JasonKeeley}
										alt='Jason Keeley'
										title='Jason Keeley'
									/>
									<div className='name'>Jason Keeley</div>
									<div className='credentials' />
								</div>
							</div>
						)}
						{/*Painting & Drywall*/}
						{this.state.showPD && (
							<div>
								<div className='member'>
									<img
										src={images.crew.JoseRecendizSr}
										alt='Jose Recendiz, Sr.'
										title='Jose Recendiz, Sr.'
									/>
									<div className='name'>Jose Recendiz, Sr.</div>
									<div className='credentials'>Team Leader</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.CesarRecendiz}
										alt='Cesar Recendiz'
										title='Cesar Recendiz'
									/>
									<div className='name'>Cesar Recendiz</div>
									<div className='credentials'>Foreman</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.JoseRecendizJr}
										alt='Jose Recendiz, Jr.'
										title='Jose Recendiz, Jr.'
									/>
									<div className='name'>Jose Recendiz, Jr.</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JorgeGonzalez}
										alt='Jorge Gonzalez'
										title='Jorge Gonzalez'
									/>
									<div className='name'>Jorge Gonzalez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.CarlosEscobedo}
										alt='Carlos Escobedo'
										title='Carlos Escobedo'
									/>
									<div className='name'>Carlos Escobedo</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.MemoSanchez}
										alt='Memo Sanchez'
										title='Memo Sanchez'
									/>
									<div className='name'>Memo Sanchez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.MiguelMedoro}
										alt='Miguel Medoro'
										title='Miguel Medoro'
									/>
									<div className='name'>Miguel Medoro</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.ManuelTheCowFernandez}
										alt='Manuel The Cow Torres'
										title='Manuel The Cow Torres'
									/>
									<div className='name'>Manuel "The Cow" Torres</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.IsmaelJrSanchez}
										alt='Ismael Jr Sanchez'
										title='Ismael Jr Sanchez'
									/>
									<div className='name'>Ismael "Jr" Sanchez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.IvanCamarzo}
										alt='Ivan Camarzo'
										title='Ivan Camarzo'
									/>
									<div className='name'>Ivan Camarzo</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JoseLuisRodriguez}
										alt='Jose Luis Rodriguez'
										title='Jose Luis Rodriguez'
									/>
									<div className='name'>Jose Luis Rodriguez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.RaydelMendoza}
										alt='Raydel Mendoza'
										title='Raydel Mendoza'
									/>
									<div className='name'>Raydel Mendoza</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JavierAlvarez}
										alt='Javier Alvarez'
										title='Javier Alvarez'
									/>
									<div className='name'>Javier Alvarez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.RyanVegaPerez}
										alt='Ryan Vega-Perez'
										title='Ryan Vega-Perez'
									/>
									<div className='name'>Ryan Vega-Perez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.LuisAlvarez}
										alt='Luis Alvarez'
										title='Luis Alvarez'
									/>
									<div className='name'>Luis Alvarez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JoseSoto}
										alt='Jose Soto'
										title='Jose Soto'
									/>
									<div className='name'>Jose Soto</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.ErnieIbarra}
										alt='Ernie Ibarra'
										title='Ernie Ibarra'
									/>
									<div className='name'>Ernie Ibarra</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.AdamRamirez}
										alt='Adam Ramirez'
										title='Adam Ramirez'
									/>
									<div className='name'>Adam Ramirez</div>
									<div className='credentials' />
								</div>
								<div className='member'>
									<img
										src={images.crew.JacobRecendiz}
										alt='Jacob Recendiz'
										title='Jacob Recendiz'
									/>
									<div className='name'>Jacob Recendiz</div>
									<div className='credentials'>Apprentice</div>
								</div>
								<div className='member'>
									<img
										src={images.crew.KavinHortado}
										alt='Kavin Hortado'
										title='Kavin Hortado'
									/>
									<div className='name'>Kavin Hortado</div>
									<div className='credentials'>Apprentice</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default Story
