import React, { Component } from 'react'
import $ from 'jquery'
//import PhotoSwipe, { PhotoSwipeUI_Default } from 'photoswipe'
import TEHelmet from './../../../component/Header/TEHelmet'

import images from '../../../config/images'
import '../../../stylesheets/testimonials.css'

import testimonialData from './testimonialData'

class Testimonials extends Component {
	componentDidMount() {
		$('#TESTIMONIALS_PAGE .header .content h1').addClass('loaded')
	}

	render() {
		const meta = {
			title: 'T-Squared Builders LLC | Testimonials',
			description: '',
		}
		return (
			<div id='TESTIMONIALS_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<img
						src={images.testimonials_header}
						alt='header background'
						className='background'
					/>
					<div className='content'>
						<h1 className='highlighted'>Testimonials</h1>
					</div>
				</header>
				<div className='section' id='testimonials'>
					{testimonialData.map((data, index) => {
						const { name, credentials, message } = data
						return (
							<div className='testimonial'>
								<div className='content'>
									<div className='author'>
										<div className='name'>{name}</div>
										<div className='credentials'>{credentials}</div>
									</div>
									<div className='message'>{message}</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default Testimonials
