import React, { Component } from 'react'
import $ from 'jquery'
import TEHelmet from './../../../component/Header/TEHelmet'

import images from '../../../config/images'
import '../../../stylesheets/guarantees.css'

class Guarantees extends Component {
	componentDidMount() {
		scrollUpdate()
		$('#GUARANTEES_PAGE .header .content h1').addClass('loaded')

		$(document).ready(function () {
			$(window).scroll(function () {
				scrollUpdate()
			})
		})

		function scrollUpdate() {
			if ($('#GUARANTEES_PAGE').length) {
				//Window Variables
				var windowTop = $(window).scrollTop()
				var windowHeight = $(window).height()
				var windowBottom = windowTop + windowHeight

				//Statement
				var statement = $('#statement')
				var statementBottom = statement.offset().top + statement.outerHeight() * 0.75

				if (windowBottom > statementBottom) {
					statement.addClass('slide-in')
				}

				//Table
				var table = $('#promises table')
				var tableBottom = table.offset().top + table.outerHeight() * 0.1

				if (windowBottom > tableBottom) {
					table.addClass('fade-in')
				}
			}
		}
	}

	render() {
		const meta = {
			title: 'T-Squared Builders LLC | Guarantees',
			description: '',
		}
		return (
			<div id='GUARANTEES_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<img
						src={images.promises_header}
						alt='header background'
						className='background'
					/>
					<div className='content'>
						<h1>Our Guarantees</h1>
					</div>
				</header>
				<div className='section' id='statement'>
					T-Squared Builders LLC. is not your typical contracting company—we care about
					our clients, and we care about getting the job done right each and every time.{' '}
				</div>
				<div className='section' id='promises'>
					<img src={images.texture2} alt='texture' className='background texture' />
					<img src={images.down_arrow} alt='down arrow' className='down-arrow top' />
					<h2>Our Guarantees To Our Customers</h2>
					<table>
						<tbody>
							<tr>
								<th>01</th>
								<td>
									We will never assign a profit margin to materials used. Our
									clients will be treated to our “contractor rates” for all
									specialty materials, including plumbing and electric fixtures,
									stone and tiles, etc. We have saved our clients thousands of
									dollars by shifting this mindset, and allowing them to be the
									benefactor of these savings.
								</td>
							</tr>
							<tr>
								<th>02</th>
								<td>
									{' '}
									We will never cross a legal line. Our company is licensed,
									bonded and highly insured.
								</td>
							</tr>
							<tr>
								<th>03</th>
								<td>
									For larger scale projects, clients will accompany our team to
									all Design Meetings with our vendors, ensuring that project
									expectations and desires are meet from the very start.
								</td>
							</tr>
							<tr>
								<th>04</th>
								<td>
									The General Contractor will be on-site, visible and available to
									clients every single day that work is being performed on a
									project.
								</td>
							</tr>
							<tr>
								<th>05</th>
								<td>
									We will safeguard our clients from the exposure of additional
									costs that typically accompany large remodeling projects. Our
									crew has earned a wonderful reputation with regard to this
									initiative by being absolutely thorough in the construction of
									our proposals.
								</td>
							</tr>
							<tr>
								<th>06</th>
								<td>
									We will never walk away from a project until our clients are 100
									percent happy with the job—guaranteed.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

export default Guarantees
