import React, { Component } from 'react'
import $ from 'jquery'
//import PhotoSwipe, { PhotoSwipeUI_Default } from 'photoswipe'

import images from '../../../config/images'
import '../../../stylesheets/contact.css'

import TEHelmet from './../../../component/Header/TEHelmet'
//import TEForm from './../../../component/Form/TEForm'
//import TEInputRow from './../../../component/Form/TEInputRow'
//import TESubmit from './../../../component/Form/TESubmit'
import TESimpleEmail from './../../../component/Email/TESimpleEmail'
import { formatEmailString, sendEmail, isEmail } from './../../../helpers'

class Contact extends Component {
	state = {
		formSubmitting: false,
		name: '',
		subject: '',
		message: '',
		emailAddress: '',
	}

	componentDidMount() {
		$('#CONTACT_PAGE .header .content h1').addClass('loaded')
		$('#CONTACT_PAGE div.message').addClass('slide-in')
	}

	contactFormSubmit = (event) => {
		event.preventDefault()
		this.setState({ formSubmitting: true })
		const { name, subject, email, message } = this.state
		console.log(name, subject, email, message)
		if (!name || !subject || !email || !message) {
			alert('Please fill out all form inputs')
			this.setState({ formSubmitting: false })
			return
		} else if (!isEmail(email)) {
			alert('Invalid email address.')
			this.setState({ formSubmitting: false })
			return
		}

		const emailString = `Name: ${name} \r\n\r\n Subject: ${subject} \r\n\r\n Email: ${email}\r\n\r\nMessage: ${message}`

		const emailHTMLString = formatEmailString(
			<TESimpleEmail
				title={subject}
				body={
					<span style={{ whiteSpace: 'pre-line' }}>
						{' '}
						Name: {name}
						<br />
						<br />
						Email: {email}
						<br />
						<br />
						Message: {message}
					</span>
				}
			/>,
		)

		const data = {
			bccEmailAddresses: [],
			ccEmailAdresses: [],
			toEmailAddresses: ['tom@t-squaredbuilders.com'], //["robinsonluked@gmail.com"],
			subjectdata: subject,
			bodyData: emailString,
			htmlData: emailHTMLString,
			bodyCharset: 'UTF-8',
			subjestCharset: 'UTF-8',
			sourceEmail: '"38th Street Studios" <info@38thstreetstudios.com>',
			replyToAddresses: [email],
		}
		sendEmail(data)
			.then((response) => {
				this.setState({
					formSubmitting: false,
					name: '',
					subject: '',
					email: '',
					message: '',
				})
				console.log(response)
			})
			.catch((error) => {
				console.log(error)
				alert(error.alertMessage)
				this.setState({ formSubmitting: false })
			})
		console.log(data)
	}

	render() {
		const meta = {
			title: 'T-Squared Builders LLC | Contact',
			description: '',
		}
		return (
			<div id='CONTACT_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<img
						src={images.contact_header}
						alt='header background'
						className='background'
					/>
					<div className='content'>
						<h1 className='highlighted'>Contact</h1>
					</div>
				</header>
				<div className='message'>
					Have a project you would like us to bid on? Give us a call or fill out our
					Contact Form, and a representative will reach out to you shortly.
				</div>
				<div className='section' id='contact'>
					<img src={images.texture2} alt='texture' className='background texture' />
					<img src={images.down_arrow} alt='down arrow' className='down-arrow top' />
					<form className='contact-form' onSubmit={this.contactFormSubmit}>
						<div className='form-row'>
							<label htmlFor='name'>Name</label>
							<input
								type='text'
								name='name'
								placeholder='name'
								onChange={(event) => {
									this.setState({ name: event.target.value })
								}}
								id='name'
								disabled={this.state.formSubmitting}
							/>
						</div>
						<div className='form-row'>
							<label htmlFor='email'>Email</label>
							<input
								type='text'
								name='email'
								placeholder='email address'
								onChange={(event) => {
									this.setState({ email: event.target.value })
								}}
								id='email'
								disabled={this.state.formSubmitting}
							/>
						</div>
						<div className='form-row'>
							<label htmlFor='subject'>Subject</label>
							<input
								type='text'
								name='subject'
								placeholder='subject'
								onChange={(event) => {
									this.setState({ subject: event.target.value })
								}}
								id='subject'
								disabled={this.state.formSubmitting}
							/>
						</div>
						<div className='form-row'>
							<label htmlFor='message'>Message</label>
							<textarea
								name='message'
								placeholder='message'
								onChange={(event) => {
									this.setState({ message: event.target.value })
								}}
								id='message'
								disabled={this.state.formSubmitting}
								defaultValue={''}
							/>
						</div>
						<div className='form-row'>
							<div
								className='g-recaptcha'
								data-sitekey='6LeTYCEUAAAAABNZclmY0yzQnzawRWneGlLyFNBC'
							/>
						</div>
						<div className='form-row push-right'>
							<input type='submit' defaultValue='Send' />
						</div>
					</form>
				</div>
			</div>
		)
	}
}

export default Contact
