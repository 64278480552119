import React, { Component } from 'react'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js'
import TEHelmet from './../../../component/Header/TEHelmet'

import images from '../../../config/images'
import '../../../stylesheets/work.css'

import Basement21419 from './basement21419'
import Bathroom3819 from './bathroom3819'

class Work extends Component {
  state = {
    showBasement: true, //default
    showBathroom: false,
    showCommercial: false,
    showExteriors: false,
    showHF: false,
    showKitchen: false,
    showLR: false,
    showMisc: false,
  }

  componentDidMount() {
    var initPhotoSwipeFromDOM = function(gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i] // <figure> element

          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue
          }

          linkEl = figureEl.children[0] // <a> element
          size = linkEl.getAttribute('data-size').split('x')

          // create slide object
          item = {
            src: linkEl.getAttribute('href'),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10),
          }

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute('src')
          }

          item.el = figureEl // save link to element for getThumbBoundsFn
          items.push(item)
        }

        return items
      }

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn))
      }

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function(e) {
        e = e || window.event
        e.preventDefault ? e.preventDefault() : (e.returnValue = false)

        var eTarget = e.target || e.srcElement

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
          return el.tagName && el.tagName.toUpperCase() === 'FIGURE'
        })

        if (!clickedListItem) {
          return
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex
            break
          }
          nodeIndex++
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery)
        }
        return false
      }

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
          params = {}

        if (hash.length < 5) {
          return params
        }

        var vars = hash.split('&')
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue
          }
          var pair = vars[i].split('=')
          if (pair.length < 2) {
            continue
          }
          params[pair[0]] = pair[1]
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10)
        }

        return params
      }

      var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
          gallery,
          options,
          items

        items = parseThumbnailElements(galleryElement)

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute('data-pswp-uid'),

          getThumbBoundsFn: function(index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
              pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect()

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width,
            }
          },
        }

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid === index) {
                options.index = j
                break
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1
          }
        } else {
          options.index = parseInt(index, 10)
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)
        gallery.init()
      }

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector)

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1)
        galleryElements[i].onclick = onThumbnailsClick
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash()
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true)
      }
    }

    //$(document).ready(function(){

    initPhotoSwipeFromDOM('.my-gallery')
    //});
  }

  setCategory = (category) => {
    //set all divs to false
    this.setState({ showBasement: false })
    this.setState({ showBathroom: false })
    this.setState({ showCommercial: false })
    this.setState({ showExteriors: false })
    this.setState({ showHF: false })
    this.setState({ showKitchen: false })
    this.setState({ showLR: false })
    this.setState({ showMisc: false })

    //set active one to true
    switch (category) {
      case 'Basement':
        this.setState({ showBasement: true })
        break
      case 'Bathrooms':
        this.setState({ showBathroom: true })
        break
      case 'Commercial':
        this.setState({ showCommercial: true })
        break
      case 'Exterior':
        this.setState({ showExteriors: true })
        break
      case 'Hardwood Flooring':
        this.setState({ showHF: true })
        break
      case 'Kitchens':
        this.setState({ showKitchen: true })
        break
      case 'Living Rooms':
        this.setState({ showLR: true })
        break
      case 'Miscellaneous':
        this.setState({ showMisc: true })
        break
      default:
        break
    }
  }

  render() {
    const meta = {
      title: 'T-Squared Builders LLC | Gallery',
      description: '',
    }
    return (
      <div id="WORK_PAGE">
        <TEHelmet {...meta} />
        <header className="header categories">
          <h1 className="highlighted">Our Work</h1>
          <div className="category-container">
            <fieldset id="gallery">
              <input
                type="radio"
                name="gallery"
                value="Basement"
                id="Basement"
                onChange={(event) => {
                  this.setCategory('Basement')
                }}
                checked={this.state.showBasement}
              />
              <label htmlFor="Basement" className="segment">
                Basement
              </label>
              <input
                type="radio"
                name="gallery"
                value="Bathroom"
                id="Bathrooms"
                onChange={(event) => {
                  this.setCategory('Bathrooms')
                }}
                checked={this.state.showBathroom}
              />
              <label htmlFor="Bathrooms" className="segment">
                Bathrooms
              </label>
              <input
                type="radio"
                name="gallery"
                value="Commercial"
                id="Commercial"
                onChange={(event) => {
                  this.setCategory('Commercial')
                }}
                checked={this.state.showCommercial}
              />
              <label htmlFor="Commercial" className="segment">
                Commercial
              </label>
              <input
                type="radio"
                name="gallery"
                value="Exteriors"
                id="Exterior"
                onChange={(event) => {
                  this.setCategory('Exterior')
                }}
                checked={this.state.showExteriors}
              />
              <label htmlFor="Exterior" className="segment">
                Exterior
              </label>
              <input
                type="radio"
                name="gallery"
                value="Hardwood Flooring"
                id="Hardwood-Flooring"
                onChange={(event) => {
                  this.setCategory('Hardwood Flooring')
                }}
                checked={this.state.showHF}
              />
              <label htmlFor="Hardwood-Flooring" className="segment">
                Hardwood Flooring
              </label>
              <input
                type="radio"
                name="gallery"
                value="Kitchen"
                id="Kitchens"
                onChange={(event) => {
                  this.setCategory('Kitchens')
                }}
                checked={this.state.showKitchen}
              />
              <label htmlFor="Kitchens" className="segment">
                Kitchens
              </label>
              <input
                type="radio"
                name="gallery"
                value="Living Room"
                id="Living-Rooms"
                onChange={(event) => {
                  this.setCategory('Living Rooms')
                }}
                checked={this.state.showLR}
              />
              <label htmlFor="Living-Rooms" className="segment">
                Living Rooms
              </label>
              <input
                type="radio"
                name="gallery"
                value="Miscellaneous"
                id="Miscellaneous"
                onChange={(event) => {
                  this.setCategory('Miscellaneous')
                }}
                checked={this.state.showMisc}
              />
              <label htmlFor="Miscellaneous" className="segment">
                Miscellaneous
              </label>
            </fieldset>
          </div>
        </header>
        <div
          className="gallery Basement"
          style={{ display: this.state.showBasement ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>
              Basement A<span className="new">New Upload 2019</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <Basement21419 />
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Basement B</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.PALATINE_BASEMENT_July_2013_032}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.PALATINE_BASEMENT_July_2013_032_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  PALATINE-BASEMENT-July-2013-032
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.five}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.five_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.seven}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.seven_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.eight}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.eight_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.PALATINE_BASEMENT_July_2013_072}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.PALATINE_BASEMENT_July_2013_072_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  PALATINE-BASEMENT-July-2013-072
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one2_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one8_5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one8_thumb_5}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one9_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one9_1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one9_thumb_1}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.one9_2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.one9_thumb_2}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two0_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">20</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">21</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two2_5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two2_thumb_5}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two3_1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two3_thumb_1}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two3_2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two3_thumb_2}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two4_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">24</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">25</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">26</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two7_5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two7_thumb_5}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">27</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">27</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two8}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two8_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">28</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.two9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.two9_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">29</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.three0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_A.three0_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">30</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_A.three1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_A.three1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">31</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>
              Basement C <span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.five3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.five3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">53</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.five2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.five2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">52</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.five1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.five1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">51</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.five0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.five0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">50</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">49</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">48</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">47</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">46</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">45</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">44</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">43</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.four2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">42</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">41</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.four0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.four0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">40</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.three9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">39</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">38</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">37</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">36</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">35</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">34</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">33</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">32</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.three1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">31</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.three0}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.three0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">30</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.two9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">29</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two8}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.two8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">28</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.two7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">27</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.two6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">26</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.two5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">25</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.basement11418.two4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">24</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two3}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.basement11418.two3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two2}
                  itemProp="contentUrl"
                  data-size="480x640"
                >
                  <img
                    src={images.gallery.basement.basement11418.two2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.basement11418.two1}
                  itemProp="contentUrl"
                  data-size="1152x392"
                >
                  <img
                    src={images.gallery.basement.basement11418.two1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">21</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Basement E</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc10_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc11}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc11_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc12}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc12_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc13}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc13_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc14}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc14_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc15}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc15_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc16}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc16_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc17}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc17_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc18}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc18_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc19}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc19_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc2_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc20}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc20_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc20</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc21}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc21_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc21</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc22}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc22_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc3}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc4_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc8_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_B.basementc9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_B.basementc9_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementc9</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Basement D</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa2_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa4_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_C.basementa8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_C.basementa8_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementa8</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Basement F</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb1}
                  itemProp="contentUrl"
                  data-size="1200x803"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb2}
                  itemProp="contentUrl"
                  data-size="1200x803"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb2_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb3}
                  itemProp="contentUrl"
                  data-size="1200x1793"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb4}
                  itemProp="contentUrl"
                  data-size="1200x1793"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb4_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb5}
                  itemProp="contentUrl"
                  data-size="1200x803"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb6}
                  itemProp="contentUrl"
                  data-size="1200x803"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb7}
                  itemProp="contentUrl"
                  data-size="1200x1793"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb8}
                  itemProp="contentUrl"
                  data-size="1200x1793"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb8_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_D.basementb9}
                  itemProp="contentUrl"
                  data-size="1200x1793"
                >
                  <img
                    src={images.gallery.basement.Basement_D.basementb9_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementb9</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Basement G</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd1_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd10_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd11}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd11_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd12}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd12_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd13}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd13_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd14}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd14_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd15}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd15_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd16}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd16_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd17}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd17_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd18}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd18_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd2_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd3}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd3_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd4_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd5_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd6_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd7_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd8_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.basement.Basement_E.basementd9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.basement.Basement_E.basementd9_thumb}
                    itemProp="thumbnail"
                    alt="basement remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">basementd9</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Bathroom"
          style={{ display: this.state.showBathroom ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>
              Bathroom A <span className="new">New Upload 2019</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <Bathroom3819 />
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>
              Bathroom B <span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7503475_1753379381363426_7267394460758364255_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7503475_1753379381363426_7267394460758364255_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7164789_1753379578030073_5543615101300809640_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7164789_1753379578030073_5543615101300809640_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173436_1753380454696652_2268294358301683210_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173436_1753380454696652_2268294358301683210_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7503384_1753380731363291_1605705214796951760_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7503384_1753380731363291_1605705214796951760_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173554_1753380988029932_3599533489285138365_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173554_1753380988029932_3599533489285138365_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7368240_1753381161363248_3344752193978213764_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7368240_1753381161363248_3344752193978213764_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7503323_1753381841363180_3892215032490599243_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7503323_1753381841363180_3892215032490599243_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7504101_1753382044696493_4008483035593525174_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7504101_1753382044696493_4008483035593525174_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7625259_1753382221363142_2322432244561353980_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7625259_1753382221363142_2322432244561353980_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7368467_1753382491363115_4228099802933527875_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7368467_1753382491363115_4228099802933527875_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7629022_1753382724696425_7873294011783457145_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7629022_1753382724696425_7873294011783457145_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7368359_1753382991363065_6053454845642202207_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7368359_1753382991363065_6053454845642202207_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173461_1753385874696110_4514737323847673671_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173461_1753385874696110_4514737323847673671_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173171_1753386038029427_1474392571427620084_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173171_1753386038029427_1474392571427620084_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7500489_1753386214696076_559182482792094209_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7500489_1753386214696076_559182482792094209_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173574_1753386581362706_2357929751423403372_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173574_1753386581362706_2357929751423403372_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7503845_1753391234695574_8835126338471871689_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7503845_1753391234695574_8835126338471871689_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two6231917_1753391401362224_6076065338787609832_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two6231917_1753391401362224_6076065338787609832_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7503671_1753391684695529_8145982241089853654_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7503671_1753391684695529_8145982241089853654_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7174047_1753391871362177_4314125268137752014_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7174047_1753391871362177_4314125268137752014_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7500302_1753392101362154_1879691126127638186_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7500302_1753392101362154_1879691126127638186_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7500686_1753392311362133_8052081710967044649_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7500686_1753392311362133_8052081710967044649_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7164329_1753392474695450_3343633431891565210_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7164329_1753392474695450_3343633431891565210_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7356251_1753392628028768_8686660137995076668_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7356251_1753392628028768_8686660137995076668_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173499_1753392798028751_6645976795975432550_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173499_1753392798028751_6645976795975432550_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7624813_1753392951362069_8670791027055060181_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7624813_1753392951362069_8670791027055060181_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7164360_1753393238028707_4581768317765714974_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7164360_1753393238028707_4581768317765714974_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7164663_1753393471362017_774049014094611551_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7164663_1753393471362017_774049014094611551_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7173507_1753393781361986_4626873612671058023_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7173507_1753393781361986_4626873612671058023_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7500683_1753393908028640_6246584967856502424_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7500683_1753393908028640_6246584967856502424_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018_2
                      .two7709694_1753394074695290_3335447658628649075_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1609"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018_2
                        .two7709694_1753394074695290_3335447658628649075_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>
              Bathroom C<span className="sub">ADA Compliant</span>
              <span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7625167_1754655721235792_308331465274540034_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7625167_1754655721235792_308331465274540034_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173176_1754656114569086_4692281573878838672_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173176_1754656114569086_4692281573878838672_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7501028_1754656267902404_3022032184525868431_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7501028_1754656267902404_3022032184525868431_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two1457770_1754656507902380_7933157837628445841_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two1457770_1754656507902380_7933157837628445841_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7164940_1754656607902370_6767809898384628241_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7164940_1754656607902370_6767809898384628241_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173266_1754656737902357_4319919078534305931_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173266_1754656737902357_4319919078534305931_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7368756_1754657237902307_3613872706721921400_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7368756_1754657237902307_3613872706721921400_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7355536_1754657381235626_17678530765965128_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7355536_1754657381235626_17678530765965128_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7629042_1754657701235594_39980766908952804_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7629042_1754657701235594_39980766908952804_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173273_1754657861235578_3210568863745427135_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173273_1754657861235578_3210568863745427135_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7355607_1754658064568891_6936248619637201205_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7355607_1754658064568891_6936248619637201205_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7355538_1754658354568862_446191542480735416_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7355538_1754658354568862_446191542480735416_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .one9264355_1754658771235487_4550009420302430430_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .one9264355_1754658771235487_4550009420302430430_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7356420_1754659017902129_7247951066095167570_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7356420_1754659017902129_7247951066095167570_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7356355_1754659324568765_2240424415391257849_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7356355_1754659324568765_2240424415391257849_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7368721_1754660217902009_1590331930725399329_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7368721_1754660217902009_1590331930725399329_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7368858_1754660367901994_5957740495480162577_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7368858_1754660367901994_5957740495480162577_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7501069_1754660611235303_3966466747641891871_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7501069_1754660611235303_3966466747641891871_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7625519_1754660777901953_6163126241123043869_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7625519_1754660777901953_6163126241123043869_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173540_1754661137901917_7669921723155978052_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173540_1754661137901917_7669921723155978052_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173270_1754661551235209_2248266992883331817_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173270_1754661551235209_2248266992883331817_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7500441_1754662011235163_521374104750133113_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7500441_1754662011235163_521374104750133113_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7624886_1754662347901796_6019368570289152143_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7624886_1754662347901796_6019368570289152143_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7504058_1754662517901779_5335742940718835767_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7504058_1754662517901779_5335742940718835767_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7500422_1754662677901763_698827374946890248_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7500422_1754662677901763_698827374946890248_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7164812_1754663101235054_495977173956721240_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7164812_1754663101235054_495977173956721240_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173419_1754663467901684_8159449207333977109_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173419_1754663467901684_8159449207333977109_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7355607_1754663681234996_6443937458775262068_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7355607_1754663681234996_6443937458775262068_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173706_1754663997901631_6844508328086789167_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173706_1754663997901631_6844508328086789167_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7356317_1754664201234944_3906847881084252244_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7356317_1754664201234944_3906847881084252244_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7503808_1754665094568188_5573599284312084530_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7503808_1754665094568188_5573599284312084530_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7368436_1754665261234838_7353116156775303123_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7368436_1754665261234838_7353116156775303123_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7625535_1754665474568150_551136948523261531_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7625535_1754665474568150_551136948523261531_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7368761_1754665791234785_3325316719660652772_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7368761_1754665791234785_3325316719660652772_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.bathroom02052018
                      .two7173586_1754665894568108_4232462850707029663_o
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.bathroom02052018
                        .two7173586_1754665894568108_4232462850707029663_o_thumb
                    }
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description" />
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom D</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_16_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_16_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_16_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_5_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_5_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_5_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_2_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_2_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_2_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_22_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_22_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_22_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_20_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_20_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_20_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_12_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_12_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_12_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_15_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_15_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_15_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_4_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_4_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_4_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_13_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_13_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_13_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_21_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_21_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_21_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_1_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_1_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_1_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_9_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_9_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_9_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_14_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_14_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_14_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_19_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_19_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_19_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_7_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_7_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_7_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_8_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_8_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_8_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_10_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_10_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_10_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_17_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_17_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_17_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_6_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_6_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_6_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_11_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_11_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_11_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_18_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_18_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_18_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_A.image_3_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_A.image_3_revised_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">image_3_revised</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom E</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha1_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha2_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha3_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha4_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha5_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha6_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha7_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha8}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha8_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_B.masterbatha9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_B.masterbatha9_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbatha9</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom F</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb1_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb2_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb3_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb4a}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb4a_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb4a</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb4_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb5_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb13}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb13_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb6_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb7_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb8_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb9_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb10_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb11}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb11_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_C.masterbathb12}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_C.masterbathb12_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathb12</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom G</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc2_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc3_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc1_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc4_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc10}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc10_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc5_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc6_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc7_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc8_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_D.masterbathc9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_D.masterbathc9_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathc9</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom H</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd1_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd2_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd3_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd4_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd5_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd6_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_E.masterbathd7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_E.masterbathd7_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathd7</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom I</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe1_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe2_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe3}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe3_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe4_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe5_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe6_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe7_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe9}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe9_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe10_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe11}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe11_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_F.masterbathe12}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Bathroom.Bathroom_F.masterbathe12_thumb}
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">masterbathe12</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom J</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_017
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_017_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_017
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_019
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_019_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_019
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_020
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_020_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_020
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_021
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_021_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_021
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_033
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_033_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_033
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_024
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_024_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_024
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_026
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_026_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_026
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_034
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_034_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_034
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_029
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_029_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_029
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Bathroom.Bathroom_G
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_031
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_G
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_031_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_031
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Bathroom K</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_006}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_006_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_006
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_008}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_008_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_008
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_010}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_010_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_010
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_011}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_011_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_011
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_013}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_013_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_013
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_021}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_021_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_021
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_018}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_018_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_018
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_027}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_027_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_027
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_028}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_028_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_028
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_030}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_030_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_030
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_032}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_032_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_032
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_041}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_041_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_041
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_042}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_042_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_042
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_045}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_045_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_045
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_049}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_049_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_049
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_050}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_050_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_050
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_053}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_053_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_053
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_001}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_001_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_001
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_052}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Bathroom.Bathroom_H.Cruz_Hall_Bathroom___June_2013_052_thumb
                    }
                    itemProp="thumbnail"
                    alt="bathroom remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Cruz_Hall_Bathroom___June_2013_052
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Commercial"
          style={{ display: this.state.showCommercial ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>Commercial Asphalt</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt1_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercialasphalt1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt2_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercialasphalt2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt3_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercialasphalt3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt4_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercialasphalt4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt5}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Commercial.Commercial_Asphalt.commercialasphalt5_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercialasphalt5</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Siding &amp; Gutter Replacement</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial1_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial19}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial19_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial2_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial21}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial21_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial21</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial22}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial22_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial23}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial23_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial24}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial24_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial24</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial3_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial4_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">commercial5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial6_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial7_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Siding_Gutter_Replacement.commercial8}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Commercial.Siding_Gutter_Replacement.commercial8_thumb}
                    itemProp="thumbnail"
                    alt="Commercial Asphalt"
                  />
                </a>
                <figcaption itemProp="caption description">commercial8</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Structural Refurbishing &amp; Roofing</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial9_thumb}
                    itemProp="thumbnail"
                    alt="IStructural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial9</figcaption>
              </figure>{' '}
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial10_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial11}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial11_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial12}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial12_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial13}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial13_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial14}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial14_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial16}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial16_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial17}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial17_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial18}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Commercial.Structual_Refurbishing_Roofing.commercial18_thumb
                    }
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commercial18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Commercial.Structual_Refurbishing_Roofing.commstruct9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Commercial.Structual_Refurbishing_Roofing.commstruct9_thumb}
                    itemProp="thumbnail"
                    alt="Structural Refurbishing & Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">commstruct9</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Exteriors"
          style={{ display: this.state.showExteriors ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>Decks</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck12}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck12_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck13}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck13_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck14}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck14_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck15}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck15_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck16}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck16_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck1_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck2_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck3_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck4_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck5_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck6_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck7_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck8_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck9_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck10}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck10_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Decks.deck11}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Decks.deck11_thumb}
                    itemProp="thumbnail"
                    alt="deck remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">deck11</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Exterior Face Renovation</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior1_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior2_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior3_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior4_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior5_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior6_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Exterior_Face_Renovation.Exterior7_thumb}
                    itemProp="thumbnail"
                    alt="Exterior Face Renovation"
                  />
                </a>
                <figcaption itemProp="caption description">Exterior7</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Roofing</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing1_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing2_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing3_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing4_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing5_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Roofing.roofing6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Exteriors.Roofing.roofing6_thumb}
                    itemProp="thumbnail"
                    alt="Roofing"
                  />
                </a>
                <figcaption itemProp="caption description">roofing6</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Siding &amp; Window Replacement</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid1_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid2_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid3_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid5_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid6_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid7_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid8_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid9_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid10}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid10_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Siding_Window_Replacement.ressid11}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Exteriors.Siding_Window_Replacement.ressid11_thumb}
                    itemProp="thumbnail"
                    alt="Siding & Window Replacement"
                  />
                </a>
                <figcaption itemProp="caption description">ressid11</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Stucco</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa1}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa1_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa2}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa2_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa3}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa3_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa4}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa4_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa5}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa5_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa6}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa6_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa7}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa7_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa8}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa8_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa9}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa9_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa10}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa10_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa11}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa11_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa12}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa12_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa13}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa13_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa14}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa14_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa15}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa15_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa16}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa16_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa17}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa17_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa18}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa18_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa19}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa19_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa20}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa20_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa20</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa21}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa21_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa21</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa22}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa22_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Exteriors.Stucco.stuccoa23}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Exteriors.Stucco.stuccoa23_thumb}
                    itemProp="thumbnail"
                    alt="Stucco"
                  />
                </a>
                <figcaption itemProp="caption description">stuccoa23</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Hardwood Flooring"
          style={{ display: this.state.showHF ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>
              Floor A <span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.two0}
                  itemProp="contentUrl"
                  data-size="1152x2048"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.two0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">20</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one9}
                  itemProp="contentUrl"
                  data-size="1152x2048"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one8}
                  itemProp="contentUrl"
                  data-size="1200x675"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one7}
                  itemProp="contentUrl"
                  data-size="1152x2048"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one6}
                  itemProp="contentUrl"
                  data-size="1152x2048"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one5}
                  itemProp="contentUrl"
                  data-size="1152x2048"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one4}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one3}
                  itemProp="contentUrl"
                  data-size="1200x1800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one2}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one1}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one0}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.nine}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.nine_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.eight}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.eight_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.seven}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.seven_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.six}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.six_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.five}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.five_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.four}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.four_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.three}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.three_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.two}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.two_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.hardwood12118.one}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.hardwood12118.one_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">1</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Floor B</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb1}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb1_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb2}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb2_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb3}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb3_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb4}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb4_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb4</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb5}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb5_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb6}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb6_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb7}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb7_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb8}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb8_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb9}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb9_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb10}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb10_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb11}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb11_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb11</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb12}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb12_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb12</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb13}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb13_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb13</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb14}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb14_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb15}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb15_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb16}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb16_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb17}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb17_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb18}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb18_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb18</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb19}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb19_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb19</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb20}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb20_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb20</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb21}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb21_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb21</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb22}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb22_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb22</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb23}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb23_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb23</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb24}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb24_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb24</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb25}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb25_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb25</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb26}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb26_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb26</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb27}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb27_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb27</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb28}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb28_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb28</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb29}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb29_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb29</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb30}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb30_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb30</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb31}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb31_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb31</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb32}
                  itemProp="contentUrl"
                  data-size="450x600"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Floor_B.hardwoodb32_thumb}
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwoodb32</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Miscellaneous Flooring</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood1}
                  itemProp="contentUrl"
                  data-size="1024x768"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood1_thumb}
                    itemProp="thumbnail"
                    alt="flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwood1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood2_thumb}
                    itemProp="thumbnail"
                    alt="flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwood2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Hardwood_Flooring.Miscellaneous_Flooring.hardwood3_thumb}
                    itemProp="thumbnail"
                    alt="flooring"
                  />
                </a>
                <figcaption itemProp="caption description">hardwood3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Hardwood_Flooring.Floor_A
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_008
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Hardwood_Flooring.Floor_A
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_008_thumb
                    }
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_008
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Hardwood_Flooring.Floor_A
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_013
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Hardwood_Flooring.Floor_A
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_013_thumb
                    }
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_013
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Hardwood_Flooring.Floor_A
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_016
                  }
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={
                      images.gallery.Hardwood_Flooring.Floor_A
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_016_thumb
                    }
                    itemProp="thumbnail"
                    alt="hardwood flooring"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_016
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Kitchen"
          style={{ display: this.state.showKitchen ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>
              Kitchen A <span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one12}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one12_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">112</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one11}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one11_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">111</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one10}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one10_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">110</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one09}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one09_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">109</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one08}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one08_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">108</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one07}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one07_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">107</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one06}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one06_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">106</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one05}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one05_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">105</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one04}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one04_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">104</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one03}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one03_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">103</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one02}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one02_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">102</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one01}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one01_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">101</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.one00}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.one00_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">100</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine9}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">99</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">98</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">97</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine6}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">96</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine5}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">95</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine4}
                  itemProp="contentUrl"
                  data-size="1200x1800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">94</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine3}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">93</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine2}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">92</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine1}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">91</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.nine0}
                  itemProp="contentUrl"
                  data-size="1200x1800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.nine0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">90</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight9}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">89</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight8}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">88</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight7}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">87</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight6}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">86</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight5}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">85</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen11418.eight4}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen11418.eight4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">84</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Kitchen B</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_2_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_2_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_2_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_3_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_3_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_3_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_4_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_4_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_4_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_5_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_5_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_5_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_6_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_6_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_6_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_7_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_7_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_7_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_10_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_10_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_10_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_14_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_14_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_14_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_15_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_15_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_15_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_16_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_16_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_16_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_19_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_19_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_19_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_20_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_20_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_20_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_23_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_23_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_23_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_24_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_24_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_24_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_26_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_26_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_26_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_27_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_27_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_27_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_28_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_28_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_28_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_30_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_30_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_30_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_33_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_33_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_33_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_34_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_34_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_34_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_35_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_35_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_35_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_38_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_38_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_38_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_39_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_39_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_39_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_40_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_40_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_40_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_43_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_43_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_43_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_44_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_44_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_44_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_45_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_45_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_45_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_46_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_46_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_46_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_47_revised}
                  itemProp="contentUrl"
                  data-size="800x600"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_47_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_47_revised</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_A.coco_49_revised}
                  itemProp="contentUrl"
                  data-size="600x800"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_A.coco_49_revised_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">coco_49_revised</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Kitchen C</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Kitchen.Kitchen_B
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_004
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Kitchen.Kitchen_B
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_004_thumb
                    }
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_004
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Kitchen.Kitchen_B
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_003
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Kitchen.Kitchen_B
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_003_thumb
                    }
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_003
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Kitchen.Kitchen_B
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_011
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Kitchen.Kitchen_B
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_011_thumb
                    }
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_011
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Kitchen.Kitchen_B
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_001
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Kitchen.Kitchen_B
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_001_thumb
                    }
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_001
                </figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchen_B.kitchenb5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchen_B.kitchenb5_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">kitchenb5</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={
                    images.gallery.Kitchen.Kitchen_B
                      .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_002
                  }
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={
                      images.gallery.Kitchen.Kitchen_B
                        .Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_002_thumb
                    }
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">
                  Lake_Barrington_Showcase___KITCHEN___MASTER_BATH___HARDWOOD_002
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Kitchens (Misc. Others)</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchens_Misc.kitchen1}
                  itemProp="contentUrl"
                  data-size="576x768"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchens_Misc.kitchen1_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">kitchen1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchens_Misc.kitchen2}
                  itemProp="contentUrl"
                  data-size="1024x768"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchens_Misc.kitchen2_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">kitchen2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchens_Misc.kitchen3}
                  itemProp="contentUrl"
                  data-size="1024x768"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchens_Misc.kitchen3_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">kitchen3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Kitchen.Kitchens_Misc.kitchen4}
                  itemProp="contentUrl"
                  data-size="1200x896"
                >
                  <img
                    src={images.gallery.Kitchen.Kitchens_Misc.kitchen4_thumb}
                    itemProp="thumbnail"
                    alt="kitchen remodeling"
                  />
                </a>
                <figcaption itemProp="caption description">kitchen4</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Living Room"
          style={{ display: this.state.showLR ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>
              Living Room A<span className="new">New Upload 2018</span>
            </h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.eight3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.eight3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">83</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.eight2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.eight2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">82</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.eight1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.eight1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">81</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.eight0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.eight0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">80</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">79</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven8}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">78</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">77</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">76</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven5}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">75</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">74</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven3}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">73</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">72</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven1}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">71</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.seven0}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.seven0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">70</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">69</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">68</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six7}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">67</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six6}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">66</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six5}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">65</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six4}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">64</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six3_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">63</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six2}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six2_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">62</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six1}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six1_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">61</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.six0}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.six0_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">60</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five9}
                  itemProp="contentUrl"
                  data-size="1200x1800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five9_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">59</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five8}
                  itemProp="contentUrl"
                  data-size="1200x1800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five8_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">58</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five7}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five7_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">57</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five6}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five6_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">56</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five5}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five5_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">55</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.livingRoom11418.five4}
                  itemProp="contentUrl"
                  data-size="1200x800"
                >
                  <img
                    src={images.gallery.Living_Room.livingRoom11418.five4_thumb}
                    itemProp="thumbnail"
                    alt="our work"
                  />
                </a>
                <figcaption itemProp="caption description">54</figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery-wrapper">
            <h2>Living Room B</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga3_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga2}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga2_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga7}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga7_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga7</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga6}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga6_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga6</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga8}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga8_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga8</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga9}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga9_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga9</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga14}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga14_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga14</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga15}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga15_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga15</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga16}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga16_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga16</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga17}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga17_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga17</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga10}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga10_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga10</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Living_Room.Living_Room_A.livinga13}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Living_Room.Living_Room_A.livinga13_thumb}
                    itemProp="thumbnail"
                    alt="living rooms"
                  />
                </a>
                <figcaption itemProp="caption description">livinga13</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div
          className="gallery Miscellaneous"
          style={{ display: this.state.showMisc ? 'block' : 'none' }}
        >
          <div className="gallery-wrapper">
            <h2>Stonework</h2>
            <div
              className="my-gallery"
              itemScope="itemScope"
              itemType="http://schema.org/ImageGallery"
            >
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Miscellaneous.Stonework.stone1}
                  itemProp="contentUrl"
                  data-size="576x768"
                >
                  <img
                    src={images.gallery.Miscellaneous.Stonework.stone1_thumb}
                    itemProp="thumbnail"
                    alt="stonework"
                  />
                </a>
                <figcaption itemProp="caption description">stone1</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Miscellaneous.Stonework.stone2}
                  itemProp="contentUrl"
                  data-size="1024x768"
                >
                  <img
                    src={images.gallery.Miscellaneous.Stonework.stone2_thumb}
                    itemProp="thumbnail"
                    alt="stonework"
                  />
                </a>
                <figcaption itemProp="caption description">stone2</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Miscellaneous.Stonework.stone3}
                  itemProp="contentUrl"
                  data-size="1200x900"
                >
                  <img
                    src={images.gallery.Miscellaneous.Stonework.stone3_thumb}
                    itemProp="thumbnail"
                    alt="stonework"
                  />
                </a>
                <figcaption itemProp="caption description">stone3</figcaption>
              </figure>
              <figure
                itemProp="associatedMedia"
                itemScope="itemScope"
                itemType="http://schema.org/ImageObject"
              >
                <a
                  href={images.gallery.Miscellaneous.Stonework.stone4}
                  itemProp="contentUrl"
                  data-size="1200x1600"
                >
                  <img
                    src={images.gallery.Miscellaneous.Stonework.stone4_thumb}
                    itemProp="thumbnail"
                    alt="stonework"
                  />
                </a>
                <figcaption itemProp="caption description">stone4</figcaption>
              </figure>
            </div>
          </div>
        </div>
        {/* Root element of PhotoSwipe. Must have class pswp. */}
        <div className="pswp" tabIndex={-1} role="dialog" aria-hidden="true">
          {/* Background of PhotoSwipe.
      It's a separate element as animating opacity is faster than rgba(). */}
          <div className="pswp__bg" />
          {/* Slides wrapper with overflow:hidden. */}
          <div className="pswp__scroll-wrap">
            {/* Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. */}
            <div className="pswp__container">
              <div className="pswp__item" />
              <div className="pswp__item" />
              <div className="pswp__item" />
            </div>
            {/* Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. */}
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                {/* Controls are self-explanatory. Order can be changed. */}
                <div className="pswp__counter" />
                <button className="pswp__button pswp__button--close" title="Close (Esc)" />
                <button className="pswp__button pswp__button--share" title="Share" />
                <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />
                <button className="pswp__button pswp__button--zoom" title="Zoom in/out" />{' '}
                {/* Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR */}
                {/* element will get class pswp__preloader--active when preloader is running */}
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div className="pswp__share-tooltip" />
              </div>
              <button
                className="pswp__button pswp__button--arrow--left"
                title="Previous (arrow left)"
              />
              <button
                className="pswp__button pswp__button--arrow--right"
                title="Next (arrow right)"
              />
              <div className="pswp__caption">
                <div className="pswp__caption__center" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Work
