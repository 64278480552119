import React, { Fragment } from 'react'

export default [
	{
		name: 'Nathan & Mandy Lowe',
		credentials: 'Barrington, IL',
		message: (
			<Fragment>
				Let me start by saying that as brand new clients of T-Squared Builders LLC, that
				this company far exceeded our expectations on a sizable project recently completed
				on our new home!
				<br />
				<br />
				Our family has just moved into Barrington from out of state. Our whole family was
				huddled up in a hotel while Tom and his team worked on the house. With this in mind
				T-Squared treated the job with an appropriate amount of urgency while still taking
				the necessary care to get the job done right. From the day that the building permit
				was posted in the window there were very few days when either Tom or members of
				their team weren’t on site progressing the job which was greatly appreciated! Tom
				was extremely flexible throughout contract / proposal process so as to accommodate
				those times when we were in the Chicago area prior to actually relocating. He went
				out of his way to make himself available (during off hours) the first weekend that
				we were all in town so he could meet the entire family. That dedication and personal
				care meant a great deal to me, my wife and our children.
				<br />
				<br />
				Let’s start at the beginning. Tom engaged with his team leaders during the quoting
				process and made sure that the right people from his team looked at the more complex
				pieces of the job. One blatantly obvious and appreciated difference from other
				contracting groups we visited with is the incredible thoroughness of T-Squared’s
				proposals, leaving little-to-no ambiguity when it came to the scope of work, but at
				the same time comprehensively accounting for most unexpected 'curve balls' that
				could have arisen as a result. A quick look on their website will also reveal how
				much pride T-Squared Builders LLC takes in their work, and this comes through in
				every interaction with Tom and his team.
				<br />
				<br />
				Their tradesmen were all clearly experienced in their fields as evidenced by the
				quality of work. Equally as important when you have people working in your house is
				their conduct. Their whole team treated the property, neighbors, and my family with
				the utmost respect throughout the job. As with most projects, our new property
				presented challenges along the way, but Tom’s clear and timely communication coupled
				with his solution-oriented team allowed us to work through issues as they arose and
				still arrived at the desired outcome. While T-Squared was not engaged to provide
				design ideas, it was an added bonus that Tom and team regularly presented ideas on
				paint colors, floor staining, finishing touches, etc.
				<br />
				<br />
				T-Squared did a great deal of work on our new home ranging from refinishing of the
				hardwood floors, extensive painting and drywall repair, electrical modifications,
				HVAC alterations, etc. But by far the most significant element of this project was
				the removal of a load-bearing wall between the breakfast / kitchen area and the
				family room. This was nothing short of a major transformation, and we couldn’t
				imagine living in here without this highly detailed improvement! Our punch list at
				the end was minimal (especially considering how much T-Squared hustled to meet our
				time frame) and all items were addressed within (48) hours of our moving into our
				new home. We would absolutely work with T-Squared Builders LLC again in the future
				and would not hesitate to recommend their services to family, friends, and whoever
				else wants to listen.
				<br />
				<br />
				Thanks T-Squared!
			</Fragment>
		),
	},
	{
		name: 'Paige & Masako Adams',
		credentials: 'Arlington Heights, IL',
		message: (
			<Fragment>
				After nearly two years in our new-construction home, we decided it was time, in
				2018, to complete the final major project: finishing our basement. We had a very
				specific vision in mind and needed someone that could help us realize it. It was
				important to us that we find someone trustworthy, capable, and reliable that would
				keep us engaged and updated through the process. My work colleague recommended{' '}
				<b>T-Squared Builders LLC</b> as they had recently remodeled his basement.
				<br />
				<br />
				After meeting Tom Serpento, president of T-Squared Builders LLC and going through
				their design consultation process in route to gathering a highly detailed proposal,
				I knew that they were the ones to create our dream basement. From the time we signed
				the contract, Tom and team were completely professional and took total ownership of
				all aspects of the process, keeping us informed of every intricate detail
				throughout. Beyond the outstanding workmanship, I was completely impressed with the
				way their team was orchestrated through the various stages of the build out. The
				team worked liked clockwork, with the various tradespeople getting all of their
				respective jobs done on schedule and as promised. Tom gave us expert guidance and
				recommendations throughout this project while working seamlessly with the local
				municipalities insuring that every stage was in complete compliance with all
				national, state and local building codes. Additionally, he saved us 'Big $$$' on
				major finish materials by leveraging their contractor rates through his supplier
				network.
				<br />
				<br />
				In the end, we got the GORGEOUS basement of our dreams, complete with bar, beautiful
				stacked stone walls, and a custom bathroom with shower highlighting beautiful
				carrara marble on the floor and shower walls, along with a maple vanity with quartz
				counter top. This amazing company EXCEEDED our expectations in every regard! It’s
				now my favorite place in the house and I absolutely love showing it off to friends.
				I confidently and WITHOUT HESITATION recommend <b>T-Squared Builders LLC</b> for any
				home construction or remodeling need.
			</Fragment>
		),
	},
	{
		name: 'Sharon H.',
		credentials: 'Lake Zurich, IL',
		message: (
			<Fragment>
				In early 2018, I reached out to several contractors for the purpose of remodeling my
				master bathroom. Tom Serpento, president of <b>T-Squared Builders LLC</b> was my
				third estimate.
				<br />
				<br />
				Their proposal was very thorough, and by far, the most professional of the three
				proposals. His detail in description of their “Scope of Work”, and their pricing is
				what convinced me that they were the right team for the job.
				<br />
				<br />
				From the moment the contract was signed, Tom managed the process thoroughly and I
				was kept informed every step of the way. I knew who would be at my house each day,
				what time to expect them and what was needed of me. It was obvious Tom has a great
				deal of experience. He had several suggestions on improvements with my ideas, which
				were greatly appreciated. He has a great crew of knowledgeable contractors who were
				easy to work with. My new master bathroom was completed without a hitch and I
				couldn’t be happier!
				<br />
				<br />
				After completion of the bathroom, I decided to do an upgrade throughout my house,
				which included full interior painting & drywall repair, replacing all the trim and
				doors throughout and installing hardwood flooring in the main living areas. Every
				room in the house was affected by this.
				<br />
				<br />
				Tom and his crew did the best they could by working in phases so I had as many
				functioning rooms as possible on any given day. Shortly into the process, the
				flooring was installed. I decided to add additional hardwood flooring in another
				hallway, which I knew would cause delays to my schedule and theirs. When I called to
				discuss it with Tom, he said he would check schedules and get back to me. He agreed
				that the flow would be better and made an additional recommendation to replace the
				flooring in the hallway powder room. At that point, I decided to upgrade the powder
				room, as well. Because of my changes, he had to readjust the schedule of all of his
				crew. He managed to squeeze my additional work into his already very busy schedule.
				<br />
				<br />
				My home looks amazing!!
				<br />
				<br />
				Tom and his crew were professional and enjoyable to work with. I would highly
				recommend
				<b>T-Squared Builders LLC</b> for any construction or remodeling work.
			</Fragment>
		),
	},
	{
		name: 'G.R. & Kristina Schrotenboer',
		credentials: 'Geneva, IL',
		message: (
			<Fragment>
				We are writing this testimonial to let others know how delighted we were to have had
				the great pleasure of working with <b>T-Squared Builders LLC</b>
				<br />
				<br />
				Both my wife, Kristina, and I were extremely pleased with the commitment to quality,
				culture &amp; detail in the planning &amp; completion of our finished basement that
				employs a beautiful full bathroom, additional bedroom and great room. The
				exceptional leadership &amp; management skills displayed by their president, Tom
				Serpento, were instrumental in the quality performance &amp; pace delivered by their
				contractors. Their meticulous attention to every detail made our experience both
				positive &amp; memorable. We thought the whole process was going to be a bit
				overwhelming with the decisions that were to be made, but Tom made it a pleasurable
				experience. His caring &amp; knowledgeable advice during the planning stages made
				life easier during construction, and the quality was first rate! Tom’s extremely
				friendly demeanor, sense of humor &amp; interaction with our family both during the
				process &amp; long afterward truly set he and his company apart from any other
				builders we have interacted with in our past.
				<br />
				<br />
				We greatly appreciated their patience &amp; personal attention as this allowed for a
				development of design optimally addressing our specific needs. In short, it has been
				an absolute pleasure to work with <b>T-Squared Builders LLC</b>!
			</Fragment>
		),
	},
	{
		name: 'Brent Novoselsky',
		credentials:
			'Former NFL Player, Minnesota Vikings & Current Vice President at GCG Financial',
		message: (
			<Fragment>
				I have known Tom for over 18 years as a very close friend. Our home needed some
				pretty serious exterior repairs and Tom and his crew jumped in and restored it to
				the way it looked when we first bought it 18 years ago. It now, again, looks
				amazing! His crew was on time, hard working and a pleasure to deal with. They found
				a number of other problematic issues that required immediate attention while they
				were on site. They were able to them fix on the spot. My wife Andrea and I could not
				have been any happier with the results! We have just hired them to replace a broken
				skylight in our master bathroom and, would not hesitate recommending them to anyone,
				and will continue hiring them for all of our construction needs!{' '}
				<b>T-Squared Builders LLC</b> is an awesome company!!
			</Fragment>
		),
	},
	{
		name: 'Marcia & Doug Zboril',
		credentials: 'Fox River Grove, IL',
		message: (
			<Fragment>
				We have had <b>T-Squared Builders LLC</b> complete several projects over the last
				couple of years which included: new gutters &amp; downspouts with gutter screening,
				all new shutters, aluminizing our front porch &amp; replacement of our bathroom
				exhaust fans. So, when we discovered a prominent leak in our master bath shower, we
				didn’t hesitate in calling Tom Serpento, president of the company, to give us his
				honest opinion on what course of action we needed to take that was within our
				budget.
				<br />
				<br />
				Since we were newbies at large construction projects, Tom was always there
				supervising the entire effort and he was extremely patient answering our questions
				and kept us informed every step of the way. We always knew in advance who would be
				at our home &amp; when. In short, the communication was excellent! Everyone involved
				with the project from plumbing, custom carpentry (Tom), tile work, painting &amp;
				electric did exceptional work! Their crew was punctual, courteous, conscientious,
				professional &amp; organized. Throughout the entire process, Tom repeatedly would
				check in to see if were we satisfied with each phase of the project. The project was
				completed without any interruption to our regular daily routine. We would always
				look forward to the end of each work day to see the transformation taking place.
				<br />
				<br />
				Our new master bathroom is fabulous, …you would not believe the difference! We can’t
				thank them enough for taking our old 80’s bathroom with the loose tiles, thinning
				grout and leaking shower pan and making into a space we can enjoy for many years to
				come.
				<br />
				<br />
				If you value honesty, integrity, affability and superb craftsmanship, then we urge
				you to contact Tom Serpento. Not only will you gain an impeccable renovator &amp;
				skilled leader in the field of construction, but you will find that you’ll want him
				&amp; his team to revisit your home for all of your future needs, as we most
				certainly do!
				<br />
				<br />
				Thank you <b>T-Squared Builders LLC</b> for making our dream come true!
			</Fragment>
		),
	},
	{
		name: 'Leslie & Todd Shapiro',
		credentials: 'LaGrange Park, IL',
		message: (
			<Fragment>
				In 2013, my husband Todd &amp; I had the great pleasure of working with{' '}
				<b>T-Squared Builders LLC</b> when we addressed some much needed interior &amp;
				exterior repairs to our beloved 70-year old Georgian home.
				<br />
				<br />
				When we engaged Tom Serpento, (President of T-Squared Builders LLC), he came out to
				our home so as to assess the prospect of our having the original hardwood floors in
				both the living room &amp; dining room refinished, and so they would match the newer
				hardwood we had installed during the construction of an addition we had done some
				years before. He &amp; Robert Varlam (who heads their highly skilled hardwood
				flooring division) quickly recognized that the original hardwood needed to be
				completely replaced due to the collapse of the original sub-floor &amp; sleeper
				system underneath. So, they tore out the floor &amp; two levels of sleeper systems
				adjoining the original planked sub-floor, and restored it from scratch. Our new
				floors look fantastic!
				<br />
				<br />
				It was utterly amazing how Tom &amp; his crew were able to restore the flooring
				&amp; sub-floor system so they evenly transitioned with the newer hardwood. Once
				they refinished all the flooring (old &amp; new), it now looks as if they had always
				been a part of our Georgian home. And as a bonus: “They don’t squeak any longer!”
				<br />
				<br />
				Tom’s teams also repaired our plaster walls, repainted the interior, and replaced
				(2) large bay window systems. Lastly, he brought in an expert mason to repair the
				brickwork on the exterior of our house. We are simply thrilled with the end result,
				and feel as if we are now living in a new home &amp; that which we are genuinely
				proud to showcase! Thank you, thank you, <b>T-Squared Builders LLC</b>
				!!
			</Fragment>
		),
	},
	{
		name: 'Kathy & Dennis Cech',
		credentials: 'Deer Park, IL',
		message: (
			<Fragment>
				It is with great pleasure that we recommend the services of{' '}
				<b>T-Squared Builders LLC</b>!<br />
				<br />
				In April of 2014, extensive damage was done to both the roof &amp; exterior siding
				of our home following a horrendous hail &amp; wind storm. After various meetings
				with Tom Serpento, president of <b>T-Squared</b>, &amp; members of his field
				management team, the decision was made to engage the services of this firm based on
				their openness &amp; sensitivity to our needs. During the entire time frame of this
				project, their team displayed a superlative degree of professionalism &amp;
				communication at every stage of the work being executed. Their incredible attention
				to every detail resulted in a job well done.
				<br />
				<br />
				Based on this performance &amp; end result, we have absolutely no hesitation in
				highly recommending the services of <b>T-Squared Builders LLC</b> for any
				construction or remodeling need.
			</Fragment>
		),
	},
	{
		name: 'Georgeanna & Gregg Mehr',
		credentials: 'Barrington, IL',
		message: (
			<Fragment>
				Tom Serpento of <b>T-Squared Builders LLC</b> and his team of professional
				contractors have been servicing our construction needs for nearly a decade.
				Throughout that time, he has helped us to transform our 1970’s home into a custom
				designed, comfortable living space that our friends and family can enjoy, and that
				we can showcase with incredible pride! Our outdated family room – fashioned with the
				time-stamped faux ceiling beams and white brick face fireplace was miraculously
				converted to a style-friendly “Man Cave” for my husband Gregg, outfitted with
				beautiful Thomasville cabinetry and accented with a new field stone fireplace with a
				solid oak mantel piece resembling the best of a Colorado mountain ski lodge. Just
				beautiful!
				<br />
				<br />
				They also prepared my son’s home for sale by seeing to a number of custom trim
				repairs and repainted the entire dwelling. It sold after only three days on the
				market!
				<br />
				<br />
				This year I presented Tom with my wish list of many projects that we had been
				putting off for years. Under his management and skills of their amazing contractors,
				my wish list became a reality! The results were amazing! They provided us with
				beautifully artistic painted rooms, refinished all of our hardwood flooring and
				transformed our winding foyer staircase that had been forever covered in carpeting
				into a magnificent hardwood staircase and newly constructed railing system. Tom then
				designed a new wet bar, wine rack and counter top cabinet to replace the 1970’s
				white particle board wet bar that had always seemed so out of place but had acted as
				a bridge from our family room into the kitchen. He was able to gather an identical
				match in the same Thomasville cabinetry so as to perfectly coincide with the bank of
				cabinets they had previously employed in my husband’s “Man Cave”, as each cabinet
				system is within just feet of the other. Now, it all looks so balanced and
				beautiful, and is the focal point as you enter our kitchen from the family room. And
				finally, Tom arranged for our entire home to receive gorgeous new 60 oz. Mohawk
				carpeting at their contractor rates! On that segment alone, he saved us $3,500
				dollars!
				<br />
				<br />
				Tom and his crew are an absolute pleasure to deal with and produce nothing short of
				quality design and craftsmanship guaranteed to satisfy the most demanding customer.
				Both my husband and I interviewed other contracting companies, and not one of them
				could compare to <b>T-Squared Builders LLC</b>
				<br />
				<br />
				We will continue to be satisfied clients, and will happily refer family and friends
				with complete confidence regarding the performance and skill sets of this amazing
				company!
			</Fragment>
		),
	},
	{
		name: 'Don Grybas',
		credentials: 'Bolingbrook, IL',
		message: (
			<Fragment>
				I’ve had the pleasure of working with <b>T-Squared Builders LLC</b> on numerous
				projects over the past decade. During the course of this relationship, Tom Serpento
				and his custom tradesmen have provided contractor services to me regarding the build
				out of a local sports bar and grill, the remodeling of my business administrative
				offices, as well as with a number of residential projects including basement
				remodeling, custom master bathroom design and creation, and complete, whole-home
				siding and window replacement. Tom was also the only person I would trust to
				complete a bathroom remodeling project at my daughter’s first home. Professionalism,
				quality, attention to detail, and trust are but just a few of the reasons why{' '}
				<b>T-Squared Builders LLC</b> is my choice whenever I find myself in need of a
				contractor.
			</Fragment>
		),
	},
	{
		name: 'Merrilyn Richards',
		credentials: 'Algonquin, IL',
		message: (
			<Fragment>
				I just wanted to let you know how much I appreciate the wonderful remodeling job you
				&amp; your team did for me. You created a dream-come-true bathroom in my 45 year old
				home!
				<br />
				<br />I was amazed at how comfortable and enjoyable you and your team made my first
				foray into the world of custom design renovation. Your company came highly
				recommended so I expected a first rate job – but, had no idea how much fun I would
				have during the process. In the beginning I felt a little intimidated by the idea of
				selecting so many items such as flooring, hardware, cabinetry, plumbing fixtures,
				lighting, etc. But the atmosphere you created was relaxed and so congenial that I
				quickly began to feel at ease and really enjoy the process. As a new client of{' '}
				<b>T-Squared Builders LLC</b>, I received red carpet treatment and beautiful
				options, yet I could still remain within my budget.
				<br />
				<br />
				You are an amazingly organized leader! I was thrilled that you were always on site
				and available to keep me apprised of every single detail regarding my project. Your
				team was always on schedule, and all phases were completed in a very timely manner.
				Everyone was so polite, friendly and efficient, and absolute magicians when dealing
				with the many challenging tasks. I am already beginning plans for my next project,
				and am very much looking forward to the adventure!
				<br />
				<br />
				Thanks for everything!
			</Fragment>
		),
	},
	{
		name: 'Bruce Herron',
		credentials:
			'Former NFL Player, Chicago Bears & Current Director of Nat’l Accounts at Tecumseh Products Company, Inc.',
		message: (
			<Fragment>
				I’ve had the pleasure of knowing Tom Serpento for nearly seventeen years, and
				through our athletic associations. We are very close friends. And I have no
				reservations in offering a candid recommendation to anyone who may have an interest
				in hiring his company, <b>T-Squared Builders LLC</b> To that end, I have firsthand
				knowledge of all he’s been challenged with, and all he’s accomplished in building a
				very successful business. Tom maintains an exemplary work ethic, and has
				successfully channeled that invaluable attribute into the hearts and minds of all
				who work with him.
				<br />
				<br />I had called on Tom numerous times during a period when we were in search of a
				new home. Our need was in his guidance, knowledge &amp; expertise as we considered
				various properties. Each time, he was able to qualify the strengths and weaknesses
				of each house, and a path towards those unknown expenses that arrive following the
				purchase of a new home. His opinions were absolutely invaluable! I also know many
				people who have hired his company, and every single one of them raved with regard to
				the quality of work they received. But more important, the wonderful, professional
				and caring manner with which he and his team deal with their clientele. Whenever we
				have a construction or remodeling need, there is no other company we would approach
				other than his. They’re terrific!
			</Fragment>
		),
	},
	{
		name: 'Eric Soderholm',
		credentials:
			'Former MLB Player, Chicago White Sox & Owner of Soderworld Healing Arts Center & Spa',
		message: (
			<Fragment>
				Tom Serpento &amp; his team demonstrated a willingness in going above &amp; beyond
				the contracted services. Without question, the finished product was magnificent!
			</Fragment>
		),
	},
	{
		name: 'John Russo',
		credentials: 'Inverness, IL',
		message: (
			<Fragment>
				Kudos to <b>T-Squared Builders LLC</b>
				!!
				<br />
				<br />
				Having retained Tom Serpento’s company in the fall of 2013 for some significant
				stucco repairs to my home, &amp; thanks to Tom’s expertise &amp; diligent inspection
				of some failing seals on my windows, I can heartily endorse T-Squared Builders LLC
				not only for the quality of their work but, the timeliness of executing the job to
				completion without workmen leaving to go to another job-site, as so frequently
				occurs with other contracting companies.
				<br />
				<br />
				Quality workmanship at reasonable rates will offer you only a couple of reasons when
				considering &amp; selecting this firm to handle all of your construction, remodeling
				or repair needs!
			</Fragment>
		),
	},
	{
		name: 'John & Julie Jirschele',
		credentials: 'South Barrington, IL',
		message: (
			<Fragment>
				We engaged <b>T-Squared Builders LLC</b>, and met Tom Serpento in 2007 when we
				solicited estimates to finish our basement. Although Tom’s estimate was not the
				lowest, his comprehensive plan and thoughtful suggestions convinced us that he would
				do the best job, so we hired them. We made a great choice! When our basement was
				finished, and exactly as we wanted it—including some changes that Tom handled with
				aplomb—we also had some new friends.
				<br />
				<br />
				We like the way Tom does business, so when we have another construction project, our
				first call will be to <b>T-Squared Builders LLC</b> and Tom Serpento. We have also
				referred many friends, because we know firsthand that Tom and his team will do great
				work for a reasonable price.
			</Fragment>
		),
	},
	{
		name: 'Marty & Sandi Alter',
		credentials: 'Hoffman Estates, IL',
		message: (
			<Fragment>
				It is not very often that you meet an individual that possesses so many of the
				positive traits one looks for when seeking an individual to do work in your home.
				When my husband Marty and I met Tom Serpento a few years back we indeed found such a
				person. Tom’s outgoing personality is indeed a plus, but…, it is his honesty,
				integrity and ethical business practices that far exceeded our expectations.
				<br />
				<br />
				Our experiences with <b>T-Squared Builders LLC</b> began with some much needed
				exterior repairs. Since that time, some three years ago, we have hired them back for
				some major interior renovations that included a beautiful tiled floor in our foyer,
				hallway and kitchen, …the installation of an entirely new front door system, …custom
				trim work, …and most recently, the removal of a wall separating our living room
				&amp; dining room that we have wanted to do for over fifteen years.
				<br />
				<br />
				Tom and his team do amazing work, with huge kudos to master carpenters, Clay &amp;
				Billy! Jose &amp; Ivan were great as well! We’ve had many friends ask us if we ever
				seek out other estimates. The answer to that is “no”. My husband and I feel that Tom
				Serpento is one of the fairest businessmen around and we simply don’t feel the need
				to seek out other estimates. We have never been disappointed in the work, and you
				can rest assured that any time we need work done in the future, we will be
				contacting Tom and <b>T-Squared Builders LLC</b> We feel we have found a genuine
				friend, as well as a valued company with which do business. They are “second to
				none”!
			</Fragment>
		),
	},
	{
		name: 'Warren & Carol Fischer',
		credentials: 'Lake Barrington, IL',
		message: (
			<Fragment>
				Our time is important and we wanted to work with a general contractor that would
				honor our schedules. Tom Serpento and his crew consistently kept to the timeline
				created both daily and for the duration of the three projects we signed on for.
				<br />
				<br />
				Three years in a row, <b>T-Squared Builders LLC</b> sought to make our home a more
				beautiful place to cook, shower and soak up the outdoors. We have repeatedly
				recommended his team of painters, carpenters (Clay is the best!) plumbers and
				electricians for their workmanship, honesty and ability to get the job done!
			</Fragment>
		),
	},
	{
		name: 'Debby & Kevin Steele',
		credentials: 'Hoffman Estates, IL',
		message: (
			<Fragment>
				We’ve been working with Tom Serpento and <b>T-Squared Builders LLC</b> since 2007
				when we originally engaged him and his team for a rather substantial body of work
				that included the installation of Brazilian cherry hardwood flooring in both our
				living and dining rooms, porcelain tile flooring in many other areas of the first
				level of our home, crown molding, recessed can lighting in our kitchen and a full
				interior repaint… just two weeks prior to Thanksgiving!
				<br />
				<br />
				Thinking this was an impossible set of tasks to complete prior to the holiday;{' '}
				<b>T-Squared</b> swarmed our home like a home make-over TV show, finishing the work
				on time and with tremendous quality! Since then, Tom and his team have been our
				go-to contractors for countless other projects–both large and small–that have
				included custom trim work, replacement of our asphalt driveway, total home window
				replacement and various plumbing and electrical service calls to name just a few.
				<br />
				<br />
				They have consistently delivered outstanding results regardless of the size of the
				job.
			</Fragment>
		),
	},
	{
		name: 'Patricia & Tony',
		credentials: 'Burr Ridge, IL',
		message: (
			<Fragment>
				As longtime repeat customers, we have been extremely pleased with the quality of
				work &amp; craftsmanship performed by <b>T-Squared Builders LLC</b> over the past
				seven years.
				<br />
				<br />
				Tom Serpento has always been great to work with and his staff is always very
				professional, courteous and accommodating. We highly recommend this company, and are
				deeply grateful for all of their fine work.
			</Fragment>
		),
	},
]
