import {
	SHOW_ALERT,
	HIDE_ALERT,
	SHOW_CONFIRM,
	HIDE_CONFIRM,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
} from './../actions/types'

export const showAlert = (alertTitle, alertMessage) => {
	return {
		type: SHOW_ALERT,
		payload: { alertTitle, alertMessage }
	}
}
export const hideAlert = () => {
	return {
		type: HIDE_ALERT
	}
}
export const showConfirm = (confirmTitle, confirmMessage, confirmLeftTitle, confirmRightOnClick, confirmRightTitle) => {
	return {
		type: SHOW_CONFIRM,
		payload: { confirmTitle, confirmMessage, confirmLeftTitle, confirmRightOnClick, confirmRightTitle }
	}
}
export const hideConfirm = () => {
	return {
		type: HIDE_CONFIRM
	}
}
export const showNetworkActivity = (networkMessage) => {
	return {
		type: SHOW_NETWORK_ACTIVITY,
		payload: { networkMessage }
	}
}
export const hideNetworkActivity = () => {
	return {
		type: HIDE_NETWORK_ACTIVITY
	}
}
