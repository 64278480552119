import React from 'react'

import { basement21419 } from '../../../config/images'

const basement21419Array = [
	{ path: '51407036_2272630366104989_2856969007863431168_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51407036_2272630366104989_2856969007863431168_o.jpg', w: 1512, h: 2016 },
	{ path: '51769701_2272630646104961_8513252473530482688_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51769701_2272630646104961_8513252473530482688_o.jpg', w: 1512, h: 2016 },
	{ path: '52020720_2272630859438273_5252682768931880960_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '52020720_2272630859438273_5252682768931880960_o.jpg', w: 1512, h: 2016 },
	{ path: '51810461_2272631059438253_4566736617284304896_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51810461_2272631059438253_4566736617284304896_o.jpg', w: 2016, h: 1512 },
	{ path: '52029381_2272631196104906_7833596907078287360_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '52029381_2272631196104906_7833596907078287360_o.jpg', w: 2016, h: 1512 },
	{ path: '51646023_2272631366104889_1712472773097422848_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51646023_2272631366104889_1712472773097422848_o.jpg', w: 1512, h: 2016 },
	{ path: '52421005_2272631829438176_6925157542393806848_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '52421005_2272631829438176_6925157542393806848_o.jpg', w: 2016, h: 1512 },
	{ path: '51691351_2272631999438159_1520789958929940480_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51691351_2272631999438159_1520789958929940480_o.jpg', w: 2016, h: 1512 },
	{ path: '51584439_2272632246104801_8953581071996289024_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51584439_2272632246104801_8953581071996289024_o.jpg', w: 1512, h: 2016 },
	{ path: '51718645_2272632446104781_3371402658521808896_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51718645_2272632446104781_3371402658521808896_o.jpg', w: 2016, h: 1512 },
	{ path: '52536793_2272632642771428_1252659595931811840_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '52536793_2272632642771428_1252659595931811840_o.jpg', w: 2016, h: 1512 },
	{ path: '51720738_2272632789438080_6730697658207305728_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51720738_2272632789438080_6730697658207305728_o.jpg', w: 1512, h: 2016 },
	{ path: '51863714_2272635462771146_5339883992453742592_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51863714_2272635462771146_5339883992453742592_o.jpg', w: 2016, h: 1512 },
	{ path: '52038475_2272635709437788_840763912893759488_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '52038475_2272635709437788_840763912893759488_o.jpg', w: 1512, h: 2016 },
	{ path: '51593560_2272635869437772_7733119308284297216_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51593560_2272635869437772_7733119308284297216_o.jpg', w: 1512, h: 2016 },
	{ path: '51620166_2272636209437738_8860891631889416192_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51620166_2272636209437738_8860891631889416192_o.jpg', w: 2016, h: 1512 },
	{ path: '51741942_2272636802771012_1461033606494289920_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51741942_2272636802771012_1461033606494289920_o.jpg', w: 2016, h: 1512 },
	{ path: '51895266_2272637092770983_7416204520599846912_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51895266_2272637092770983_7416204520599846912_o.jpg', w: 2016, h: 1512 },
	{ path: '51460946_2272637622770930_5267827373373390848_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51460946_2272637622770930_5267827373373390848_o.jpg', w: 1512, h: 2016 },
	{ path: '52569271_2272638372770855_46352644268097536_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '52569271_2272638372770855_46352644268097536_o.jpg', w: 1512, h: 2016 },
	{ path: '51628630_2272638626104163_5244003761013129216_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51628630_2272638626104163_5244003761013129216_o.jpg', w: 1512, h: 2016 },
	{ path: '51502869_2272638872770805_878663683566206976_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51502869_2272638872770805_878663683566206976_o.jpg', w: 1512, h: 2016 },
	{ path: '51638564_2272639156104110_2082938557353164800_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51638564_2272639156104110_2082938557353164800_o.jpg', w: 1512, h: 2016 },
	{ path: '51734001_2272639376104088_2015905014194634752_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51734001_2272639376104088_2015905014194634752_o.jpg', w: 1512, h: 2016 },
	{ path: '52558962_2272639569437402_6806497689178472448_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '52558962_2272639569437402_6806497689178472448_o.jpg', w: 1512, h: 2016 },
	{ path: '51559189_2272639819437377_4904157099258281984_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51559189_2272639819437377_4904157099258281984_o.jpg', w: 1512, h: 2016 },
	{ path: '51910841_2272989526069073_1983295986100862976_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51910841_2272989526069073_1983295986100862976_o.jpg', w: 1512, h: 2016 },
	{ path: '51783608_2272990772735615_8419929555812220928_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51783608_2272990772735615_8419929555812220928_o.jpg', w: 1512, h: 2016 },
	//Something missing Here
	{ path: '51794134_2272991136068912_1777665149675503616_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51794134_2272991136068912_1777665149675503616_o.jpg', w: 1512, h: 2016 },
	{ path: '51615242_2272991659402193_6858399572926922752_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51615242_2272991659402193_6858399572926922752_o.jpg', w: 1512, h: 2016 },
	{ path: '51914298_2272641722770520_553668683713478656_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '51914298_2272641722770520_553668683713478656_o.jpg', w: 1512, h: 2016 },
	{ path: '51611005_2272642219437137_6443788156297805824_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '51611005_2272642219437137_6443788156297805824_o.jpg', w: 2016, h: 1512 },
	{ path: '52674289_2272640306103995_442878464714866688_o-thumb.jpg', w: 1512, h: 2016 },
	{ path: '52674289_2272640306103995_442878464714866688_o.jpg', w: 1512, h: 2016 },
	{ path: '52461586_2272641226103903_1909910787606970368_o-thumb.jpg', w: 2016, h: 1512 },
	{ path: '52461586_2272641226103903_1909910787606970368_o.jpg', w: 2016, h: 1512 },
	{ path: '51500009_2272643316103694_1448951408224108544_o-thumb.jpg', w: 2016, h: 1134 },
	{ path: '51500009_2272643316103694_1448951408224108544_o.jpg', w: 2016, h: 1134 },
	{ path: '51836638_2272989132735779_6913186059790581760_o-thumb.jpg', w: 1134, h: 2016 },
	{ path: '51836638_2272989132735779_6913186059790581760_o.jpg', w: 1134, h: 2016 },
	{ path: '51741884_2272645029436856_167964982556753920_o-thumb.jpg', w: 1134, h: 2016 },
	{ path: '51741884_2272645029436856_167964982556753920_o.jpg', w: 1134, h: 2016 },
	{ path: '51775714_2272643986103627_3449412342792585216_o-thumb.jpg', w: 1134, h: 2016 },
	{ path: '51775714_2272643986103627_3449412342792585216_o.jpg', w: 1134, h: 2016 },
	{ path: '51861134_2272645556103470_8490199992483446784_o-thumb.jpg', w: 2016, h: 1134 },
	{ path: '51861134_2272645556103470_8490199992483446784_o.jpg', w: 2016, h: 1134 },
]

// const newArray = YFGW2018Array.sort((a, b) => {
// 	if (a.path < b.path) return -1
// 	if (a.path > b.path) return 1
// 	return 0
// })

//TODO: To improve this group thumb and full and make a nice full object.

export default () => {
	const componentArray = []
	for (let i = 0; i < basement21419Array.length; i++) {
		componentArray.push(
			<figure
				itemProp="associatedMedia"
				itemScope="itemScope"
				itemType="http://schema.org/ImageObject"
				key={i}
			>
				<a
					href={basement21419[basement21419Array[i + 1].path]}
					itemProp="contentUrl"
					data-size={`${basement21419Array[i + 1].w}x${basement21419Array[i + 1].h}`}
				>
					<img
						src={basement21419[basement21419Array[i].path]}
						itemProp="thumbnail"
						alt="description"
					/>
				</a>
				<figcaption itemProp="caption description">
					{basement21419Array[i + 1].caption}
				</figcaption>
			</figure>
		)
		i++
	}
	return componentArray
}
