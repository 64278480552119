import ReactDOMServer from 'react-dom/server';
import moment from 'moment'

export const convertToDateTimeString = (epoch) =>{
	if (!epoch){ return ' -- ' }

	return moment(epoch).format('ddd, MMM D, YYYY, h:mm A')
}
export const convertToDateString = (epoch) =>{
	if (!epoch){ return ' -- ' }

	return moment(epoch).format('ddd, MMM D, YYYY')
}
export const convertToDateTimeShort = (epoch) =>{
	if (!epoch){ return ' -- ' }

	return moment(epoch).format('M/D/YYYY, h:mm A')
}
export const convertToDateShort = (epoch) =>{
	if (!epoch){ return ' -- ' }

	return moment(epoch).format('M/D/YYYY')
}
export const formatPhoneNumber = (phonenumber) => {
	let formatedNumber = phonenumber
	formatedNumber = formatedNumber.replace(/\D/g,'');
	if (formatedNumber.length === 10){
		formatedNumber = "+1" + formatedNumber;
	}
	if (formatedNumber.length === 11){
		formatedNumber = "+" + formatedNumber;
	}
	return formatedNumber;
}


export const formatEmailString = (Component) => {
	const headerString = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'
	let htmlString = ReactDOMServer.renderToStaticMarkup(Component)

	htmlString = headerString + htmlString

	return htmlString
}


export const flattenObject = (ob) => {
	let toReturn = {}

	for (const i in ob) {
		if (!ob.hasOwnProperty(i)) continue;

		if ((typeof ob[i]) === 'object') {
			let flatObject = flattenObject(ob[i])
			for (const x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;

				toReturn[i + '.' + x] = flatObject[x]
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
}

export const objectOfBoolsToArray = (object) => {
	const arrayToReturn = []
	for (const key in object){
		if (object[key]){
			arrayToReturn.push(key)
		}
	}
	return arrayToReturn
}

export const replaceAll = (target, search, replacement) => {
	return target.replace(new RegExp(search, 'g'), replacement);
}

export const moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
	const newArray = [...array]

	if (fromIndex === toIndex) return newArray

	const target = newArray[fromIndex]
	const inc = toIndex < fromIndex ? -1 : 1

	for (let i = fromIndex; i !== toIndex; i += inc) {
		newArray[i] = newArray[i + inc]
	}

	newArray[toIndex] = target

	return newArray
}
