import React, { Component } from 'react'
import $ from 'jquery'
import TEHelmet from './../../../component/Header/TEHelmet'

import images from '../../../config/images'
import '../../../stylesheets/news.css'

class News extends Component {
	componentDidMount() {
		$('#NEWS_PAGE .header .content h1').addClass('loaded')
	}

	render() {
		const meta = {
			title: 'T-Squared Builders LLC | News',
			description: '',
		}
		return (
			<div id='NEWS_PAGE'>
				<TEHelmet {...meta} />
				<header className='header'>
					<img src={images.news_header} alt='header background' className='background' />
					<div className='content'>
						<h1 className='highlighted'>News &amp; Events</h1>
					</div>
				</header>
				<div className='section' id='news'>
					<div className='post' id='splashpad'>
						<h2 className='title'>
							COUNTRY MAGAZINE
							<span className='subtitle'>BARRINGTON STYLE AND SUBSTANCE</span>
						</h2>
						<h3 className='date'>May 2, 2017</h3>
						<div className='body'>
							<div className='img-container float-left'>
								<img
									src={images.news.TrendingCover}
									alt='Style and Substance'
									title='Style and Substance'
									className='float-left'
								/>
								<div className='image-subtitle'>
									Tom Serpento. PHOTOGRAPHY BY ROBIN SUBAR
								</div>
							</div>
							<p>
								Tom Serpento, president and CEO of T-Squared Builders LLC, has been
								serving the construction and remodeling needs of the Barrington
								community for nearly 20 years. He is also the Executive Director for
								the NFL Players Association, Former Players - Chicago Chapter, and
								the Retired Professional Football Players of Chicago. Motivated by
								charitable causes for children, he is fully immersed in any action
								benefiting the underserved of Chicago. A long-time member of the St.
								Anne Catholic Community, Serpento is a recognized lector with parish
								ministries. He is also an avid “bad golfer” and enjoys long rides on
								his Trek bicycle.
							</p>
							<p>
								<b>FROM HIS MOBILE</b>
							</p>
							<p>
								Spotify, NCAA Sports, ESPN, Iowa State Football Stream, NFL Mobile,
								Accu Weather.
							</p>
							<p>
								<b>ON HIS COFFEE TABLE</b>
							</p>
							<p>
								Fresh flowers every week. Also, books, including: When Pride Still
								Mattered – A Life of Vince Lombardi by David Maraniss, The Great
								Game of Business by Jack Stack with Bo Burlinghamm, and Competitive
								Advantage— Creating &amp; Sustaining Superior Performance by Michael
								E. Porter. And magazines, including Golf, Time, This Old House,
								Better Homes &amp; Gardens/Bath &amp; Kitchen Remodeling, and
								Country.
							</p>
							<p>
								<b>IN HIS EARBUDS</b>
							</p>
							<p>
								Bruce Springsteen, Stevie Ray Vaughan, Kid Rock, Eric Clapton, U2,
								Rolling Stones.
							</p>
							<a
								href='http://jwcdaily.com/countrymag/2017/05/02/trending-3/'
								className='button'
							>
								Full Story
							</a>
						</div>
					</div>
					<div className='post' id='splashpad'>
						<h2 className='title'>BARRINGTON JUNIOR WOMEN’S CLUB – “Splashpad”</h2>
						<h3 className='date'>July 22, 2016</h3>
						<div className='body'>
							<div className='img-container float-left'>
								<img
									src={images.news.splashpad}
									alt='splashpad'
									title='Splashpad'
									className='float-left'
								/>
								<div className='image-subtitle'>
									(L to R) – Keri Culhane, BJWC Secretary, Tom Serpento, Erin
									Shechtman, BJWC President and Mary Dale, BJWC Vice-President.
								</div>
							</div>
							<p>
								"As it is that we at <b>T-Squared Builders LLC</b> select a local
								charity each year to bolster in support of a wonderful cause, we are
								extremely happy and proud to have selected the Barrington Junior
								Women’s Club and their amazing efforts with “Spashpad”! This
								incredible endeavor with which they have engaged will provide a
								“water park” like experience for those children with both mental and
								physical challenges. When I first read about “Splashpad” in the
								Quintessential Barrington magazine, I was touched beyond words, and
								much ado to the fact that I, myself, have a mentally challenged
								adult sister with whom is the angel of our family. I vividly
								remember the struggle my own parents had when we were children as
								they were always searching for places and environments that would
								allow my beloved sister to enjoy the same interaction and wonderment
								as normal children. This amazing idea originated, developed and
								advanced by Erin Shechtman, President of the BJWC, is being
								carefully crafted into a blessed reality for these children, and
								with the full support of the community. We are fully committed to
								helping her and the BJWC do all that they can as they continue their
								pursuit of charitable donations so at to see this effort through to
								fruition. To that end, and because of my position on the board of
								directors for the Chicago Chapter of the NFL Players Association,
								not only has our company fully backed this initiative, but the
								players association is also now deeply involved in support of
								“Splashpad” to further all efforts towards raising the necessary
								funds to see this through! Please click on the hyperlink to learn
								more about “Splashpad” and how you can donate!
							</p>
							<p>We deeply thank you for all that you may do!"</p>
							<p>
								Tom Serpento
								<br />
								President &amp; CEO
							</p>
						</div>
					</div>
					<div className='post' id='hoop-dreams'>
						<h2 className='title'>Countryside Elementary: Hoop Dreams</h2>
						<h3 className='date'>November 19, 2015</h3>
						<div className='body'>
							<p>Hoop Dreams-Donate NOW, Pick your Brick in 2016</p>
							<p>
								Many folks have mentioned that they want to contribute to the “Hoop
								Dreams” project, but are not quite sure what size brick to acquire,
								or what to have printed on their brick.
							</p>
							<p>
								We've got an expert brick team member ready to take your questions!
								Megan Elwell can guide you through the brick selection process, and
								would be happy to share sample ideas for your brick. Simply fill out
								the donation form{' '}
								<a href='http://www.barrington220.org/cms/lib2/IL01001296/Centricity/Domain/1006/Hoop%20Dreams%20Sponsorship%20Form.pdf'>
									(Click Here)
								</a>{' '}
								and submit with your payment either through the mail (checks made
								payable to Countryside PTO) or by using our no fee pay portal below.
							</p>
							<p>
								If you submit your donation without a “Brick Form”, we’ll reach back
								to you to help you make your final decision regarding your brick. We
								need to complete our fundraising in 2015 in order to access the best
								available bid pricing on this project. Our deadline on the brick
								order will be in early 2016, so you’ll have time to consider what
								icons or what verbiage you’d like to see on your brick….but please
								don’t delay!To view the available “Buy a Brick” icons, click{' '}
								<a href='http://www.barrington220.org/cms/lib2/IL01001296/Centricity/Domain/1006/HDicons.pdf'>
									HERE
								</a>
								. Please contact Megan at{' '}
								<a href='https://barrington220.revtrak.net/meganelwell@ymail.com'>
									meganelwell@ymail.com
								</a>{' '}
								or Anne at{' '}
								<a href='https://barrington220.revtrak.net/anne@strategyskills.com'>
									anne@strategyskills.com
								</a>{' '}
								with any questions. Each donation brings us one step closer to
								turning our Hoop Dreams into a reality. The Hoop Dreams Legacy
								Project falls under the Countryside PTO and is a 501(3)c tax
								deductible donation. Funds donated through this program will be
								earmarked for the installation of the outdoor Sport Court at
								Countryside. Thank you for your support!
							</p>
							<p>
								Please be sure to view the new “Hoop Dreams” video produced by BHS
								TV students. You can view it{' '}
								<a href='https://vimeo.com/144514574'>here.</a>
							</p>
						</div>
					</div>
					<div className='post' id='hoffman-award'>
						<h2 className='title'>2015 Best of Hoffman Estates Award</h2>
						<h3 className='date'>November 4, 2015</h3>
						<div className='body'>
							<div className='img-container float-left'>
								<a href={images.news.Best_of_2015_Award}>
									{' '}
									<img
										src={images.news.Best_of_2015_Award}
										alt='2015 t-squared award'
										title='2015 Award'
										className='float-left'
									/>{' '}
								</a>
							</div>
							<p>
								HOFFMAN ESTATES November 4, 2015—<b>T-Squared Builders LLC</b> has
								been selected for the 2015 Best of Hoffman Estates Award in the
								General Contractors category by the Hoffman Estates Award Program.
							</p>
							{/* <a href="/static/img/news/Best-of-2015-Award.jpg" className="button">Full Story</a> */}
						</div>
					</div>
					<div className='post' id='sun-times'>
						<h2 className='title'>Chicago Sun Times</h2>
						<h3 className='date'>December 21, 2008</h3>
						<div className='body'>
							<div className='img-container float-left'>
								<a href={images.news.Sun_Times_New1}>
									<img
										src={images.news.suntimes}
										alt='suntimes'
										title='Suntimes'
										className='float-left'
									/>
								</a>
							</div>
							<p>
								Simply put, <b>T-Squared Builders LLC</b> has guided my wife and I
								through a remodeling project in a manner we all dream about. They
								have left us with the feeling that we should let others know about
								this company.
							</p>
							{/* <a href={images.news.Sun_Times_New1} className="button">Full Story</a>  */}
						</div>
					</div>
					<div className='post' id='helping'>
						<h2 className='title'>Helping military family from Elmhurst</h2>
						<h3 className='date'>March 29, 2006</h3>
						<div className='body'>
							<div className='img-container float-left'>
								<img
									src={images.news.pressConference}
									alt='elmhurst'
									title='Elmhurst'
									className='float-left'
								/>
							</div>
							<p>
								Lt. Governor Pat Quinn celebrated with disabled Vietnam Veteran
								Jesse Alcozer, as he and his family moved into their newly remodeled
								home. The Alcozer home was tragically destroyed in a December 27
								fire – five weeks after his son Army PFC Christopher Alcozer was
								killed in Iraq.
								<br />
								The Alcozer home was remodeled at cost by{' '}
								<b>T-Squared Builders LLC</b>
							</p>
							<a
								href='http://www4.illinois.gov/PressReleases/ShowPressRelease.cfm?SubjectID=14&RecNum=4740&SubjectID=14&RecNum=4740'
								className='button'
							>
								Full Story
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default News
